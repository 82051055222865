@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* #header-sticky {
  background-color: black;
} */

.somevalue {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  color: white;
  padding: 1.5rem;
  -webkit-appearance: none;
  /* show none  */
  box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
    10px 10px 15px rgba(70, 70, 70, 0.15);
}

.postbox__contact-input input {
  border-radius: 3px;
  border-bottom: 1px solid rgba(43, 43, 43, 0.295);
}

.slider__shape-top-9 {
  z-index: -99 !important;
  left: 45%;
}

.feature_icon_span_number {
  font-size: 48px;
  font-weight: 600;
  color: #733fc8;
}

.form-div-hero {
  padding: 20px;
}

.icon_image_stats {}

.slider__shape-top-6 {
  display: none !important;
  width: 7%;
}

.glass-form-effect-2 {
  background-color: rgba(0, 0, 0, 0.13);
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.151);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(77, 77, 77, 0);
}

.input {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  padding-top: 1.5rem;
  color: white;
}

span.something_title_span {}

.input+.input {
  margin-top: 1.5rem;
}

.input-label {
  color: #ffffff;
  position: absolute;
  top: 1.5rem;
  transition: 0.25s ease;
}

.input-field {
  border: 0;
  z-index: 1;
  background-color: transparent;
  border-bottom: 2px solid #ffffff;
  font: inherit;
  font-size: 1.125rem;
  padding: 0.25rem 0;
}

.input-field:focus,
.input-field:valid {
  outline: 0;
  border-bottom-color: #ffffff;
}

.input-field:focus+.input-label,
.input-field:valid+.input-label {
  color: #ffffff;
  transform: translateY(-1.5rem);
}

@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

section {
  padding: 60px 0;
}

section .section-title {
  text-align: center;
  color: #383838;
  margin-bottom: 50px;
  text-transform: uppercase;
}

#footer {
  background: #333333 !important;
}

#footer h5 {
  padding-left: 10px;
  border-left: 3px solid #eeeeee;
  padding-bottom: 6px;
  margin-bottom: 20px;
  color: #ffffff;
}

#footer a {
  color: #ffffff;
  text-decoration: none !important;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

#footer ul.social li {
  padding: 3px 0;
}

#footer ul.social li a i {
  margin-right: 5px;
  font-size: 25px;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  transition: 0.5s all ease;
}

#footer ul.social li:hover a i {
  font-size: 30px;
  margin-top: -10px;
}

#footer ul.social li a,
#footer ul.quick-links li a {
  color: #ffffff;
}

#footer ul.social li a:hover {
  color: #eeeeee;
}

#footer ul.quick-links li {
  padding: 3px 0;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  transition: 0.5s all ease;
}

#footer ul.quick-links li:hover {
  padding: 3px 0;
  margin-left: 5px;
  font-weight: 700;
}

#footer ul.quick-links li a i {
  margin-right: 5px;
}

#footer ul.quick-links li:hover a i {
  font-weight: 700;
}

@media (max-width: 767px) {
  #footer h5 {
    padding-left: 0;
    border-left: transparent;
    padding-bottom: 0px;
    margin-bottom: 10px;
  }

  form.glass_form {
    width: 80%;
    margin: 0 auto;
  }
}

.outerdiv {
  width: 100%;
  min-height: 100vh;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.innerdiv {
  transform: scale(0.9);
  margin: 1rem;
  display: grid;
  grid-gap: 1.5rem;
  grid-template-rows: repeat(2, 22rem);
  grid-template-columns: repeat(4, 17rem);
}

.eachdiv {
  padding: 1rem 2rem;
  border-radius: 0.8rem;
  box-shadow: 5px 5px 20px #6d6b6b6b;
  color: white;
}

.div1 {
  background: #733fc8;
  grid-column: 1/3;
  grid-row: 1/2;
  background-image: url(https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/bg-pattern-quotation.svg);
  background-repeat: no-repeat;
  background-position-x: 25rem;
}

.div2 {
  background: #49556b;
  grid-column: 3/4;
  grid-row: 1/2;
}

.div3 {
  background: white;
  grid-column: 4/5;
  grid-row: 1/3;
  color: black;
}

.div4 {
  background: white;
  grid-column: 1/4;
  grid-row: 2/2;
  color: black;
}

.div5 {
  background: #18202d;
  grid-column: 2/4;
  grid-row: 2/3;
}

.userdetails {
  display: flex;
}

.imgbox {
  margin-right: 1rem;
}

.imgbox img {
  border-radius: 50%;
  width: 2rem;
  border: 2px solid #cec5c5;
}

.detbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.detbox p {
  margin: 0;
}

.detbox .name {
  color: hsl(0, 0%, 81%);
  font-size: 0.9rem;
  margin-bottom: 0.1rem;
  font-weight: 600;
}

.detbox .name.dark {
  color: #49505a;
}

.detbox .designation {
  color: hsl(0, 0%, 81%);
  opacity: 50%;
  font-size: 0.8rem;
}

.detbox .designation.dark {
  color: #49505a;
}

.review h4 {
  font-size: 1.4rem;
  color: #f3deff;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 0.8rem;
}

.review.dark h4 {
  color: #4b5258;
}

.review p {
  font-size: 0.95rem;
  color: #f3deff;
  font-weight: 500;
  opacity: 50%;
  line-height: 1.5;
}

.review.dark p {
  color: #0e0e0e;
}

.attribution {
  font-size: 1rem;
  line-height: 1.5;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  text-align: right;
}

.attribution a {
  text-decoration: none;
}

@media only screen and (max-width: 1000px) {
  .innerdiv {
    transform: scale(0.7);
  }
}

@media only screen and (max-width: 800px) {
  .innerdiv {
    transform: scale(0.6);
  }
}

@media only screen and (max-width: 600px) {
  .div1 {
    background-position-x: 10rem;
  }

  .innerdiv {
    display: flex;
    flex-direction: column;
    transform: scale(1);
    margin: 2rem;
    margin-bottom: 5rem;
  }

  .attribution {
    position: relative;
  }
}

.pe-loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.774);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.header__get__whatsapp {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  padding-right: 15px;
  color: #fff !important;
  background-color: var(--it-common-black);
  border-radius: 5px;
  z-index: 100;
  font-family: Arial;
  font-size: 17px;
  animation: whatsapp-animation 0.5s ease-in-out;
  box-shadow: 1px 2px 5px 2px rgba(30, 30, 30, 0.3);
  transition: all 0.3s ease-out;
}

@media only screen and (max-width: 600px) {
  .header__get__whatsapp {
    display: none;
  }
}

.header__get__whatsapp:hover {
  color: white !important;
  transform: scale(1.1);
}

.header__get__whatsapp span {
  margin-left: 5px;
}

.fa-whatsapp {
  font-size: 20px !important;
  padding-right: 5px;
  padding-left: 5px;
}

/* .main-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 5px;
}

.wrapper {
  display: inline-flex;
  background: #fff;
  height: 80px;
  width: 400px;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 5px;
  padding: 20px 15px;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.2);
}
.wrapper .option {
  background: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  padding: 0 10px;
  border: 2px solid lightgrey;
  transition: all 0.3s ease;
}
.wrapper .option .dot {
  height: 20px;
  width: 20px;
  background: #d9d9d9;
  border-radius: 50%;
  position: relative;
}
.wrapper .option .dot::before {
  position: absolute;
  content: "";
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: #000000;
  border-radius: 50%;
  opacity: 0;
  transform: scale(1.5);
  transition: all 0.3s ease;
}
input[type="radio"] {
  display: none;
}
#option-1:checked:checked ~ .option-1,
#option-2:checked:checked ~ .option-2 {
  border-color: #000000;
  background: #000000;
}
#option-1:checked:checked ~ .option-1 .dot,
#option-2:checked:checked ~ .option-2 .dot {
  background: #fff;
}
#option-1:checked:checked ~ .option-1 .dot::before,
#option-2:checked:checked ~ .option-2 .dot::before {
  opacity: 1;
  transform: scale(1);
}
.wrapper .option span {
  font-size: 20px;
  color: #808080;
}
#option-1:checked:checked ~ .option-1 span,
#option-2:checked:checked ~ .option-2 span {
  color: #fff;
}

.are-you-facing {
  font-size: 20px;
  font-family: Karla;
  color: black;
  font-weight: 600;
  padding-bottom: 10px;
} */

.are-you-facing {
  font-size: 20px;
  font-family: Karla;
  color: black;
  font-weight: 600;
  padding-bottom: 10px;
}

.l-radio {
  padding: 6px;
  border-radius: 50px;
  display: inline-flex;
  cursor: pointer;
  color: black;
  transition: background 0.2s ease;
  margin: 8px 0;
  -webkit-tap-highlight-color: transparent;
}

.l-radio:hover,
.l-radio:focus-within {
  background: rgba(159, 159, 159, 0.1);
}

.l-radio input {
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: none;
  border: 0;
  box-shadow: inset 0 0 0 1px #000000;
  box-shadow: inset 0 0 0 1.5px #000000;
  appearance: none;
  padding: 0;
  margin: 0;
  transition: box-shadow 150ms cubic-bezier(0.95, 0.15, 0.5, 1.25);
  pointer-events: none;
}

.l-radio input:focus {
  outline: none;
}

.l-radio input:checked {
  box-shadow: inset 0 0 0 6px #6743ee;
}

.l-radio span {
  vertical-align: middle;
  display: inline-block;
  line-height: 20px;
  padding: 0 8px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.select-box {
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto;
  font-family: "Open Sans", "Helvetica Neue", "Segoe UI", "Calibri", "Arial",
    sans-serif;
  font-size: 18px;
  color: #60666d;
  z-index: 99;
}

@media (min-width: 768px) {
  .select-box {
    width: 70%;
  }
}

@media (min-width: 992px) {
  .select-box {
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .select-box {
    width: 30%;
  }
}

.select-box__current {
  position: relative;
  box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  outline: none;
}

.select-box__current:focus+.select-box__list {
  opacity: 1;
  -webkit-animation-name: none;
  animation-name: none;
}

.select-box__current:focus+.select-box__list .select-box__option {
  cursor: pointer;
}

.select-box__current:focus .select-box__icon {
  transform: translateY(-50%) rotate(180deg);
}

.select-box__icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 20px;
  opacity: 0.3;
  transition: 0.2s ease;
}

.select-box__value {
  display: flex;
}

.select-box__input {
  display: none;
}

.select-box__input:checked+.select-box__input-text {
  display: block;
}

.select-box__input-text {
  display: none;
  width: 100%;
  margin: 0;
  padding: 15px;
  background-color: #fff;
}

.select-box__list {
  position: absolute;
  width: 100%;
  padding: 0;
  list-style: none;
  opacity: 0;
  -webkit-animation-name: HideList;
  animation-name: HideList;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: step-start;
  animation-timing-function: step-start;
  box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
}

.select-box__option {
  display: block;
  padding: 15px;
  background-color: #fff;
}

.select-box__option:hover,
.select-box__option:focus {
  color: #546c84;
  background-color: #fbfbfb;
}

@-webkit-keyframes HideList {
  from {
    transform: scaleY(1);
  }

  to {
    transform: scaleY(0);
  }
}

@keyframes HideList {
  from {
    transform: scaleY(1);
  }

  to {
    transform: scaleY(0);
  }
}

.some-modal {
  width: 100%;
  height: fit-content;
  min-height: 100%;
  position: fixed;
  background-color: #000000c9;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.socialmain {
  height: 45px;
  display: flex;
  background-color: var(--it-common-black);
  justify-content: space-between;
  padding: 0px 5vw;
  align-content: center;
  align-items: center;
}

.header-social-icons>a>i {
  color: #fff;
  margin-right: 15px;
  font-size: 22px;
}

.header-social-icons>a>i:hover {
  color: #d5d5d5;

}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.xl {
  color: #FFF;
  font-size: 35px !important;

}