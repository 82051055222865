.ReactModalPortal{
   
}
.ReactModal__Overlay ,.ReactModal__Overlay--after-open{
    z-index: 999 !important;
    height: 100vh;
    width:100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.75) !important;
}
.AppModal{
   
    width: 600px;
    border-radius: 20px;
    box-shadow:rgba(255, 255, 255, 0.5) 0px 2px 21px 11px
}
.AppModal-header{
    background-color: #404040;
height: 50px;
display: flex;
justify-content: end;
border-radius: 20px 20px 0px 0px ;


}
.close{
    margin: 10px;
    height: 30px;
    width: 30px;
background: #ffffff !important;
border-radius:100% ;
}
.close img{
    height: 100%;
    width: 100%;
}
.AppModal-footer{
    border-radius: 0px 0px 20px 20px ;

  
    background-color: #404040;
    display: flex;
    justify-content: center;
    padding: 10px;
}
.AppModal-footer div{
    margin: 0px;
}