@font-face {
    font-family: flaticon;
    src: url(../fonts/fa-solid-900.ttf) format("truetype"), url(../fonts/fa-brands-400.woff) format("woff"), url(../fonts/fa-duotone-900.woff2) format("woff2"), url(../fonts/fa-regular-400.eot) format("embedded-opentype"), url(../fonts/fa-light-300.svg) format("svg")
}

i[class^=flaticon-]:before,
i[class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: 400 !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.flaticon-vector:before {
    content: "\f101"
}

.flaticon-medal:before {
    content: "\f102"
}

.flaticon-app-development:before {
    content: "\f103"
}

.flaticon-programming:before {
    content: "\f104"
}

.flaticon-repairing-service:before {
    content: "\f105"
}