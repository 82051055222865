@charset "UTF-8";
@import 'https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&family=Rubik:wght@300;400;500;600;700;800;900&family=Saira:wght@300;400;500;600;700;800;900&display=swap" rel="stylesheet';

@font-face {
  font-family: "helvetica";
  src: url("https://candyfonts.com/wp-data/2018/10/26/11538/HELR45W.ttf") format("woff"),
    url("https://candyfonts.com/wp-data/2018/10/26/11538/HELR45W.ttf") format("opentype"),
    url("https://candyfonts.com/wp-data/2018/10/26/11538/HELR45W.ttf") format("truetype");
}

:root {
  --it-ff-body: "helvetica";
  --it-ff-heading: "helvetica";
  --it-ff-fontawesome: Font Awesome 5 Pro;
  --it-common-white: #ffffff;
  --it-common-white-2: #d0d0d0;
  --it-common-black: #232323;
  --it-common-black-2: #292930;
  --it-common-black-3: #3a3a42;
  --it-common-black-4: #e2e2e2;
  --it-common-black-5: #2b2b32;
  --it-common-black-6: #31303f;
  --it-common-black-7: #1d1d25;
  --it-common-black-8: #292931;
  --it-common-sky: #ebf8fb;
  --it-common-pink: #fff4f4;
  --it-common-pink-2: #ffadad;
  --it-common-pink-3: #fb87e1;
  --it-common-pink-4: #ff9c9c;
  --it-common-pink-5: #ffbebe;
  --it-common-blue: #83adff;
  --it-common-blue-2: #e4edff;
  --it-common-blue-3: #2e3bbb;
  --it-common-blue-4: #4a6ef0;
  --it-common-blue-5: #eff2fe;
  --it-common-paste: #59c9da;
  --it-common-paste-2: #daf3f7;
  --it-common-paste-3: #57c9da;
  --it-common-green: #5ef794;
  --it-common-red: #ffadad;
  --it-common-red-2: #ffeded;
  --it-common-yellow: #fff6d6;
  --it-common-yellow-2: #fff1c9;
  --it-common-yellow-3: #fff3c8;
  --it-heading-primary: #232323;
  --it-grey-1: #fffcf3;
  --it-grey-2: #fafafa;
  --it-text-body: #636363;
  --it-theme-1: #ffdc60;
  --it-border-1: #dddddd;
  --it-border-2: #595252;
}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1200px;
  }
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  --bs-gutter-x: 30px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--it-ff-body);
  font-size: 15px;
  font-weight: 400;
  color: var(--it-text-body);
  line-height: 1.3;
  overflow-x: hidden;
}

body.monserat {
  font-family: var(--it-ff-monserat);
}

a {
  text-decoration: none;
  transition: 0.3s;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--it-ff-heading);
  color: var(--it-heading-primary);
  margin-top: 0;
  font-weight: 700;
  line-height: 1.1;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

img {
  max-width: 100%;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0;
}

p {
  color: var(--it-text-body);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.z-index {
  position: relative;
  z-index: 2;
}

.z-index-3 {
  position: relative;
  z-index: 3;
}

.z-index-4 {
  position: relative;
  z-index: 4;
}

.z-index-5 {
  position: relative;
  z-index: 5;
}

.z-index-6 {
  position: relative;
  z-index: 6;
}

a,
.btn,
button,
input,
select,
textarea,
li,
img,
.transition-3,
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
}

a,
button {
  color: inherit;
  outline: none;
  border: none;
  background: 0 0;
}

button:hover {
  cursor: pointer;
}

button:focus {
  outline: 0;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

input,
textarea,
select {
  outline: none;
  color: var(--it-common-black);
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
select {
  color: #70737d;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #70737d;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #70737d;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #70737d;
}

input[type="color"] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding: 0;
  border-radius: 50%;
}

*::-moz-selection {
  background: var(--it-theme-1);
  color: var(--it-common-white);
  text-shadow: none;
}

::-moz-selection {
  background: var(--it-theme-1);
  color: var(--it-common-white);
  text-shadow: none;
}

::selection {
  background: var(--it-theme-1);
  color: var(--it-common-white);
  text-shadow: none;
}

*::-moz-placeholder {
  color: var(--it-common-black);
  font-size: var(--it-fz-body);
  opacity: 1;
}

*::placeholder {
  color: var(--it-common-black);
  font-size: var(--it-fz-body);
  opacity: 1;
}

.w-img img {
  width: 100%;
}

.m-img img {
  max-width: 100%;
}

.fix {
  overflow: hidden;
}

.clear {
  clear: both;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.overflow-y-visible {
  overflow-x: hidden;
  overflow-y: visible;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.include-bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.gx-10 {
  --bs-gutter-x: 10px;
}

.gx-30 {
  --bs-gutter-x: 30px;
}

.gx-40 {
  --bs-gutter-x: 40px;
}

.gx-50 {
  --bs-gutter-x: 12px;
}

.gx-60 {
  --bs-gutter-x: 65px;
}

.gx-20 {
  --bs-gutter-x: 20px;
}

.gx-25 {
  --bs-gutter-x: 25px;
}

.gx-60 {
  --bs-gutter-x: 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .gx-60 {
    --bs-gutter-x: 40px;
  }
}

.main-btn {
  display: inline-block;
  background-color: var(--it-theme-1);
  height: 62px;
  line-height: 64px;
  border-radius: 30px;
  padding: 0 52px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  text-transform: capitalize;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: 1s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .main-btn {
    height: 48px;
    line-height: 48px;
    padding: 0 28px;
  }
}

.main-btn span {
  position: relative;
  z-index: 99;
  transition: 0.7s;
  color: var(--it-common-black);
}

.main-btn.white-bg {
  background-color: var(--it-common-white);
  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
}

.main-btn:hover {
  transition-delay: 0.3s;
  background-color: var(--it-common-black);
}

.main-btn:hover span {
  color: var(--it-common-white);
}

.main-btn.alt-color-white:hover span {
  color: var(--it-common-black);
}

.it-btn {
  display: inline-block;
  background-color: var(--it-theme-1);
  color: var(--it-common-black);
  height: 52px;
  line-height: 52px;
  border-radius: 30px;
  padding: 0 30px;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  text-transform: capitalize;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: 0.3s;
}

.it-btn.white-bg {
  background-color: var(--it-common-white);
}

.it-btn span {
  position: relative;
  z-index: 99;
  transition: 0.7s;
  color: var(--it-common-black);
}

.it-btn span i {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: var(--it-common-white);
  line-height: 34px;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  color: var(--it-common-black);
  margin-left: 10px;
}

.it-btn:hover {
  transition-delay: 0.3s;
}

.it-btn:hover span {
  color: var(--it-common-white);
}

.yellow-btn {
  display: inline-block;
  background-color: var(--it-theme-1);
  color: var(--it-common-black);
  height: 52px;
  line-height: 52px;
  border-radius: 7px;
  width: 180px;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  text-transform: capitalize;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: 0.3s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .yellow-btn {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .yellow-btn {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .yellow-btn {
    font-size: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .yellow-btn {
    font-size: 15px;
  }
}

.yellow-btn.grey-bg {
  background-color: #eff2f9;
}

.yellow-btn span {
  position: relative;
  z-index: 99;
  transition: 0.7s;
  color: var(--it-common-black);
}

.yellow-btn:hover span {
  color: var(--it-common-white);
}

.yellow-btn-sm {
  display: inline-block;
  background-color: #f1f1f1;
  color: var(--it-common-black);
  height: 40px;
  line-height: 40px;
  border-radius: 7px;
  padding: 0 40px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  text-transform: capitalize;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: 0.3s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .yellow-btn-sm {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .yellow-btn-sm {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .yellow-btn-sm {
    font-size: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .yellow-btn-sm {
    font-size: 15px;
  }
}

.yellow-btn-sm.yellow-bg {
  background-color: var(--it-theme-1);
  transition: 0.3s;
}

.yellow-btn-sm.yellow-bg:hover {
  background: var(--it-common-black);
  color: var(--it-common-white);
}

.yellow-btn-sm:hover {
  background: var(--it-theme-1);
  color: var(--it-common-black);
}

.white-btn {
  display: inline-block;
  background-color: var(--it-common-white);
  color: var(--it-common-black);
  height: 62px;
  line-height: 64px;
  border-radius: 30px;
  padding: 0 52px;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  text-transform: capitalize;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: 0.3s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .white-btn {
    height: 50px;
    line-height: 52px;
    padding: 0 42px;
  }
}

.white-btn span {
  position: relative;
  z-index: 99;
  transition: 0.7s;
  color: var(--it-common-black);
  font-size: 17px;
  font-weight: 500;
}

.white-btn.alt-color:hover span {
  color: var(--it-common-white);
}

.main-btn-sm {
  display: inline-block;
  background-color: var(--it-theme-1);
  color: var(--it-common-black);
  height: 52px;
  line-height: 52px;
  border-radius: 30px;
  padding: 0 33px;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  text-transform: capitalize;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: 0.7s;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .main-btn-sm {
    padding: 0 25px;
  }
}

.main-btn-sm.alt-color-white:hover {
  color: var(--it-common-black);
}

.main-btn-sm span {
  position: relative;
  z-index: 99;
}

.main-btn-sm:hover {
  background-color: var(--it-common-black);
  color: var(--it-common-white);
}

.border-btn {
  display: inline-block;
  background-color: var(--it-common-white);
  color: var(--it-common-black);
  border: 1px solid var(--it-theme-1);
  height: 52px;
  line-height: 52px;
  border-radius: 30px;
  padding: 0 33px;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  text-transform: capitalize;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: 0.3s;
}

.border-btn span {
  position: relative;
  z-index: 99;
  transition: 0.7s;
  color: var(--it-common-black);
}

.border-btn:hover {
  transition-delay: 0.3s;
  border: 1px solid transparent;
}

.border-btn:hover span {
  color: var(--it-common-white);
}

.subscribe-btn-3 {
  display: inline-block;
  background-color: var(--it-theme-1);
  height: 80px;
  line-height: 80px;
  border-radius: 60px;
  padding: 0 53px;
  font-weight: 500;
  text-align: center;
  text-transform: capitalize;
  overflow: hidden;
  z-index: 1;
  transition: 0.3s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .subscribe-btn-3 {
    height: 55px;
    line-height: 55px;
    border-radius: 60px;
    padding: 0 37px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-btn-3 {
    height: 50px;
    line-height: 50px;
    border-radius: 60px;
    padding: 0 33px;
  }
}

@media (max-width: 767px) {
  .subscribe-btn-3 {
    height: 50px;
    line-height: 50px;
    border-radius: 60px;
    padding: 0 28px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .subscribe-btn-3 {
    padding: 0 50px;
  }
}

.subscribe-btn-3 span {
  position: relative;
  z-index: 99;
  transition: 0.7s;
  color: var(--it-common-black);
  font-size: 17px;
  font-weight: 500;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .subscribe-btn-3 span {
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-btn-3 span {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .subscribe-btn-3 span {
    font-size: 13px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .subscribe-btn-3 span {
    font-size: 15px;
  }
}

.subscribe-btn-3:hover {
  color: var(--it-common-white);
}

.subscribe-btn-3.alt-color-blue:hover span {
  color: var(--it-common-black);
}

.subscribe-btn-2 {
  display: inline-block;
  background-color: var(--it-theme-1);
  color: var(--it-common-black);
  height: 80px;
  line-height: 80px;
  border-radius: 60px;
  padding: 0 53px;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  text-transform: capitalize;
  overflow: hidden;
  z-index: 1;
  transition: 0.3s;
}

.subscribe-btn-2 span {
  position: relative;
  z-index: 99;
  transition: 0.7s;
  color: var(--it-common-black);
  font-size: 17px;
  font-weight: 500;
}

@media (max-width: 767px) {
  .subscribe-btn-2 span {
    font-size: 14px;
  }
}

.subscribe-btn-2.alt-color-black:hover span {
  color: var(--it-common-white);
}

.subscribe-btn {
  display: inline-block;
  background-color: var(--it-theme-1);
  color: var(--it-common-black);
  height: 35px;
  line-height: 35px;
  border-radius: 30px;
  padding: 0 33px;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  text-transform: capitalize;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: 0.3s;
}

.subscribe-btn:hover {
  background: var(--it-common-black);
  color: var(--it-common-white);
}

.subscribe-btn.radious-none {
  height: 50px;
  line-height: 50px;
  border-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0 23px;
}

.border-btn-2 {
  display: inline-block;
  color: var(--it-common-black);
  border: 1px solid var(--it-border-1);
  height: 50px;
  line-height: 50px;
  border-radius: 5px;
  padding: 0 25px;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  text-transform: capitalize;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: 0.3s;
}

.border-btn-2 i {
  margin-left: 10px;
  transform: translateY(2px);
}

.border-btn-2:hover {
  background: var(--it-common-black);
  color: var(--it-common-white);
  border: 1px solid transparent;
}

.border-btn-3 {
  display: inline-block;
  color: var(--it-common-white);
  border: 1px solid var(--it-common-white);
  height: 50px;
  line-height: 50px;
  border-radius: 2px;
  padding: 0 25px;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  text-transform: capitalize;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: 0.3s;
}

.border-btn-3 i {
  margin-right: 13px;
  font-size: 17px;
  transform: translateY(2px);
  color: var(--it-common-white);
  transition: 0.3s;
}

.border-btn-3:hover {
  background: var(--it-common-white);
  border: 1px solid transparent;
}

.border-btn-3:hover i {
  color: var(--it-common-black);
}

.border-btn-3.bg-color-1 {
  background-color: var(--it-common-blue);
  color: var(--it-common-black);
  border-radius: 5px;
  border: none;
}

.border-btn-3.bg-color-1 i {
  color: var(--it-common-black);
}

.border-btn-3.bg-color-2 {
  background-color: var(--it-theme-1);
  color: var(--it-common-black);
  border: none;
  border-radius: 5px;
}

.border-btn-3.bg-color-2 i {
  color: var(--it-common-black);
}

.tp-btn-hover {
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.tp-btn-hover b {
  position: absolute;
  width: 0;
  height: 0;
  background-color: var(--it-common-black);
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  z-index: 1;
}

.tp-btn-hover:hover b {
  width: 600px;
  height: 600px;
  display: inline-block;
}

.tp-btn-hover.alt-color b {
  background-color: var(--it-common-black);
}

.tp-btn-hover.alt-color-orange b {
  background-color: var(--it-common-black);
}

.tp-btn-hover.alt-color-blue b {
  background-color: var(--it-common-blue);
}

.tp-btn-hover.alt-color-white b {
  background-color: var(--it-common-white);
}

.apply-btn {
  display: inline-block;
  color: var(--it-common-black);
  border: 1px solid var(--it-border-1);
  height: 40px;
  line-height: 37px;
  border-radius: 5px;
  padding: 0 19px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  text-transform: capitalize;
  overflow: hidden;
  z-index: 1;
  transition: 0.3s;
}

.apply-btn:hover {
  border: 1px solid transparent;
  background: var(--it-theme-1);
  color: var(--it-common-black);
}

.submit-btn {
  background-color: var(--it-common-blue-3);
  text-align: center;
  height: 55px;
  padding: 0 20px;
  display: inline-block;
  line-height: 55px;
  color: var(--it-common-white);
  font-weight: 600;
  font-size: 16px;
}

.submit-btn:hover {
  color: var(--it-common-white);
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }

  70% {
    -webkit-box-shadow: 0 0 0 45px transparent;
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 transparent;
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }

  70% {
    -moz-box-shadow: 0 0 0 45px transparent;
    box-shadow: 0 0 0 45px transparent;
  }

  100% {
    -moz-box-shadow: 0 0 0 0 transparent;
    box-shadow: 0 0 0 0 transparent;
  }
}

@keyframes section-animation {
  0% {
    width: 0;
  }

  15% {
    width: 100%;
  }

  85% {
    opacity: 1;
  }

  90% {
    width: 100%;
    opacity: 0;
  }

  to {
    width: 0;
    opacity: 0;
  }
}

@keyframes about-sm {
  100% {
    transform: translateY(60px);
  }

  100% {
    transform: translateY(50px);
  }
}

@keyframes hero-thumb-animation {
  0% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes tpleftright {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
}

@keyframes tprotate {
  0% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}

@keyframes tptranslateY2 {
  0% {
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  100% {
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
}

@keyframes scroll {
  0% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }

  100% {
    -webkit-transform: translateY(8px);
    -moz-transform: translateY(8px);
    -ms-transform: translateY(8px);
    -o-transform: translateY(8px);
    transform: translateY(8px);
  }
}

@keyframes RL_smooth {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes slide_up_down {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

@keyframes tptranslateX2 {
  0% {
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    transform: translateX(-30px);
  }

  100% {
    -webkit-transform: translatXY(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
}

@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes scale_up_down {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
    stroke-dasharray: 3000;
  }
}

@keyframes tpupdown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

@keyframes tpswing {
  0% {
    -webkit-transform: rotate(6deg);
    -moz-transform: rotate(6deg);
    -ms-transform: rotate(6deg);
    -o-transform: rotate(6deg);
    transform: rotate(6deg);
  }

  100% {
    -webkit-transform: rotate(-6deg);
    -moz-transform: rotate(-6deg);
    -ms-transform: rotate(-6deg);
    -o-transform: rotate(-6deg);
    transform: rotate(-6deg);
  }
}

@keyframes borderanimate2 {
  0% {
    transform: translate(-50%, -50%) scale(0.8);
  }

  60% {
    opacity: 1;
  }

  100% {
    transform: translate(-50%, -50%) scale(2.5);
    opacity: 0;
  }
}

@keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    transform: translateY(2000px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  80% {
    -webkit-transform: translateY(10px);
    -ms-transform: translateY(10px);
    transform: translateY(10px);
  }

  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes moving {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(-20px);
  }

  100% {
    transform: translatey(0px);
  }
}

@keyframes about-circle {
  0% {
    -webkit-transform: translateX(100px);
    -moz-transform: translateX(100px);
    -ms-transform: translateX(100px);
    -o-transform: translateX(100px);
    transform: translateX(100px);
  }

  100% {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes services-triangle {
  0% {
    -webkit-transform: rotate(0deg) translateX(-50px);
    -moz-transform: rotate(0deg) translateX(-50px);
    -ms-transform: rotate(0deg) translateX(-50px);
    -o-transform: rotate(0deg) translateX(-50px);
    transform: rotate(0deg) translateX(-50px);
  }

  100% {
    -webkit-transform: rotate(360deg) translateY(100px);
    -moz-transform: rotate(360deg) translateY(100px);
    -ms-transform: rotate(360deg) translateY(100px);
    -o-transform: rotate(360deg) translateY(100px);
    transform: rotate(360deg) translateY(100px);
  }
}

@keyframes hero-3-dot-2 {
  0% {
    transform: translateY(-50px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes tfLeftToRight {
  49% {
    transform: translateX(60%);
  }

  50% {
    opacity: 0;
    transform: translateX(-60%);
  }

  51% {
    opacity: 1;
  }
}

@keyframes rotate2 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes moving {
  0% {
    transform: translatey(0px);
  }

  20% {
    transform: translateX(-50px);
  }

  50% {
    transform: translatey(-40px);
  }

  100% {
    transform: translatey(0px);
  }
}

@keyframes leftright {
  0% {
    transform: translatex(0);
  }

  50% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes movinglight {
  0% {
    transform: translatey(0px);
  }

  20% {
    transform: translateX(-70px);
  }

  50% {
    transform: translatey(-70px);
  }

  100% {
    transform: translatey(0px);
  }
}

@keyframes movinglight2 {
  0% {
    transform: translatey(0px);
  }

  20% {
    transform: translateX(70px);
  }

  50% {
    transform: translatey(70px);
  }

  100% {
    transform: translatey(0px);
  }
}

@keyframes movingtop {
  0% {
    transform: translatey(0px);
  }

  20% {
    transform: translatey(70px);
  }

  50% {
    transform: translatey(-70px);
  }

  100% {
    transform: translatey(0px);
  }
}

@keyframes circle-animation {
  0% {
    right: 0;
  }

  50% {
    right: 100%;
  }

  100% {
    right: 0%;
  }
}

@keyframes circle-animation-2 {
  0% {
    left: 0;
  }

  50% {
    left: 100%;
  }

  100% {
    left: 0%;
  }
}

@keyframes animationglob {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes zoom {
  0% {
    transform: scale(0.5);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.5);
  }
}

@keyframes zoom2 {
  0% {
    transform: scale(0.9);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.9);
  }
}

@keyframes wobble-vertical {
  10% {
    transform: translateY(-10px);
  }

  50% {
    transform: skew(15deg);
  }

  80% {
    transform: rotate(10deg);
  }

  100% {
    transform: translate(0);
  }
}

@-webkit-keyframes section-highlight {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@-moz-keyframes section-highlight {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@-ms-keyframes section-highlight {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@keyframes section-highlight {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@keyframes widthcalc {
  0% {
    width: 20%;
  }

  50% {
    width: 50%;
  }

  75% {
    width: 90%;
  }

  90% {
    opacity: 75%;
  }

  90% {
    opacity: 100%;
  }
}

@keyframes bounceRight {

  0%,
  20%,
  50%,
  80%,
  100% {
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -ms-transform: translateY(-35px);
    transform: translateY(-35px);
  }

  60% {
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

@keyframes icon-bounce {

  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  60% {
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -o-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

@keyframes light-one {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0.4;
  }

  50% {
    opacity: 0.6;
  }

  75% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

.dark #preloader {
  background-color: var(--it-theme-1);
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f7f7f7;
  z-index: 999999;
}

.preloader {
  width: 50px;
  height: 50px;
  display: inline-block;
  padding: 0;
  text-align: left;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
}

.preloader span {
  position: absolute;
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: var(--it-theme-1);
  -webkit-animation: preloader 1.3s linear infinite;
  animation: preloader 1.3s linear infinite;
}

.preloader span:last-child {
  animation-delay: -0.9s;
  -webkit-animation-delay: -0.9s;
}

@keyframes preloader {
  0% {
    transform: scale(0, 0);
    opacity: 0.5;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@-webkit-keyframes preloader {
  0% {
    -webkit-transform: scale(0, 0);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1, 1);
    opacity: 0;
  }
}

.scroll-top {
  width: 50px;
  height: 50px;
  line-height: 50px;
  position: fixed;
  bottom: -10%;
  right: 50px;
  font-size: 16px;
  border-radius: 6px;
  z-index: 9;
  color: var(--it-common-black);
  text-align: center;
  cursor: pointer;
  background: var(--it-theme-1);
  transition: 1s ease;
  border: none;
}

.scroll-top.open {
  bottom: 80px;
}

.grey-bg {
  background: var(--it-grey-1);
}

.grey-bg-2 {
  background: var(--it-grey-2);
}

.black-bg {
  background: var(--it-common-black-2);
}

.black-bg-4 {
  background: var(--it-common-black-6);
}

.black-bg-3 {
  background: var(--it-common-black-3);
}

.black-bg-2 {
  background: var(--it-common-black-8);
}

.white-bg {
  background: var(--it-common-white);
}

.blue-bg {
  background: var(--it-common-blue-4);
}

.body-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: rgba(24, 24, 24, 0.6);
  visibility: hidden;
  opacity: 0;
  transition: 0.45s ease-in-out;
}

.body-overlay.apply {
  opacity: 1;
  visibility: visible;
}

.body-overlay:hover {
  cursor: url(../img/cross-out.png), pointer;
}

.it-instagram img {
  width: 100%;
}

.itoffcanvas {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  right: -100%;
  width: 480px;
  bottom: 0;
  box-shadow: 0 16px -32px 0 rgba(0, 0, 0, 0.8);
  background-color: var(--it-common-black);
  z-index: 9999;
  padding: 50px;
  scrollbar-width: none;
  opacity: 0;
  visibility: hidden;
  transition: 0.45s ease-in-out;
  overflow-y: scroll;
}

.itoffcanvas.opened {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 767px) {
  .itoffcanvas {
    width: 300px;
    padding: 40px 35px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .itoffcanvas {
    width: 400px;
    padding: 40px;
  }
}

.itoffcanvas.opened {
  right: 0;
  -webkit-transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-duration: 0.6s;
}

.itoffcanvas__logo {
  margin-bottom: 40px;
}

@media (max-width: 767px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .itoffcanvas__logo {
    padding-top: 0;
  }
}

.itoffcanvas__logo img {
  width: 157px;
  height: 100%;
}

.itoffcanvas__close-btn button {
  font-size: 35px;
  color: #fff;
  position: absolute;
  right: 50px;
  top: 42px;
  transition: 1s;
  font-weight: 300;
  opacity: 0.2;
  -webkit-transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-duration: 0.3s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .itoffcanvas__close-btn button {
    font-size: 28px;
    right: 48px;
    top: 52px;
  }
}

@media (max-width: 767px) {
  .itoffcanvas__close-btn button {
    font-size: 28px;
    right: 39px;
    top: 38px;
  }
}

.itoffcanvas__close-btn button:hover {
  color: #fff;
  opacity: 1;
}

.itoffcanvas__content {
  margin-bottom: 30px;
}

.itoffcanvas__content p {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 25px;
}

.itoffcanvas__content span {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
  display: inline-block;
}

.itoffcanvas__content a {
  font-size: 30px;
  line-height: 51px;
  font-weight: 700;
  color: var(--it-common-white);
  display: inline-block;
}

@media (max-width: 767px) {
  .itoffcanvas__content a {
    font-size: 27px;
  }
}

.itoffcanvas__social {
  margin-top: 50px;
}

.itoffcanvas__social .social-icon a {
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 18px;
  background-color: var(--it-common-white);
  color: var(--it-common-black);
  margin-right: 15px;
  transition: 0.3s;
  display: inline-block;
  border-radius: 10px;
}

@media (max-width: 767px) {
  .itoffcanvas__social .social-icon a {
    margin-right: 3px;
  }
}

.itoffcanvas__social .social-icon a:hover {
  background-color: var(--it-theme-1);
  color: var(--it-common-black);
}

.itoffcanvas__text {
  border-bottom: 1px solid #3b3838;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .itoffcanvas__text {
    display: none;
  }
}

.itoffcanvas__text p {
  color: #ddd;
  font-size: 18px;
}

.itoffcanvas__info {
  border-bottom: 1px solid #3b3838;
  padding-bottom: 30px;
  margin-bottom: 20px;
}

.itoffcanvas__info .offcanva-title {
  color: var(--it-common-white);
  margin: 30px 0 40px;
  font-size: 20px;
  font-weight: 600;
}

.itoffcanvas__info-icon a {
  height: 50px;
  width: 50px;
  background-color: var(--it-theme-1);
  color: var(--it-common-black);
  display: inline-block;
  text-align: center;
  line-height: 50px;
  border-radius: 50px;
  font-weight: 400;
  font-size: 21px;
  margin-right: 20px;
}

.itoffcanvas__info-icon-updated a {
  height: 38px;
  width: 38px;
  background-color: var(--it-theme-1);
  color: var(--it-common-black);
  display: inline-block;
  text-align: center;
  line-height: 36px;
  border-radius: 50px;
  font-weight: 400;
  font-size: 21px;
  margin-right: 20px;
}

.itoffcanvas__info-address span {
  display: block;
  color: rgba(255, 255, 255, 0.6392156863);
  font-size: 16px;
  font-weight: 400;
}

.itoffcanvas__info-address a {
  display: block;
  color: var(--it-common-white);
  font-size: 18px;
  font-weight: 600;
  transition: 0.3s;
}

@media (max-width: 767px) {
  .itoffcanvas__info-address a {
    font-size: 16px;
  }
}

.itoffcanvas__info-address a:hover {
  color: var(--it-theme-1);
}

.it-offcanva-bottom-shape {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: -1;
}

.it-offcanva-bottom-shape img {
  width: 100%;
}

.breadcrumb__title {
  font-weight: 700;
  font-size: 50px;
  line-height: 1.1;
  color: var(--it-common-black);
  padding-bottom: 15px;
}

.getInTouch {
  width: auto;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .breadcrumb__title {
    font-size: 41px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb__title {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb__title {
    font-size: 45px;
  }
}

@media (max-width: 767px) {
  .breadcrumb__title {
    font-size: 32px;
    margin-bottom: 0;
  }
}

.breadcrumb__plr {
  padding: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .breadcrumb__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .breadcrumb__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb__plr {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .breadcrumb__plr {
    padding: 0;
  }
}

.breadcrumb__space {
  padding-top: 200px;
  padding-bottom: 130px;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}

@media (max-width: 767px) {
  .breadcrumb__space {
    padding-top: 200px;
    padding-bottom: 80px;
  }
}

.breadcrumb__subtitle {
  font-size: 18px;
  font-weight: 300;
  color: var(--tp-common-black);
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .breadcrumb__subtitle {
    font-size: 15px;
  }
}

.breadcrumb__button a {
  font-size: 16px;
  font-weight: 400;
  color: #636363;
  background-color: var(--it-common-white);
  border: 1px solid var(--it-theme-1);
  padding: 11px 28px;
  display: inline-block;
  border-radius: 30px;
}

.breadcrumb__button a i {
  padding: 0 8px;
  transform: translateY(2px);
}

.breadcrumb__shape-1 {
  position: absolute;
  right: 0;
  top: 0;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .breadcrumb__shape-1 {
    right: -2%;
    top: -2%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .breadcrumb__shape-1 {
    right: -2%;
    top: -2%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .breadcrumb__shape-1 {
    right: -5%;
    top: -5%;
  }
}

.breadcrumb__shape-2 {
  position: absolute;
  right: 78%;
  top: 2%;
}

.breadcrumb__shape-3 {
  position: absolute;
  top: 13%;
  left: 2%;
  animation: zoom 5s infinite;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .breadcrumb__shape-3 {
    top: 25%;
    left: 1%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .breadcrumb__shape-3 {
    top: 25%;
    left: 1%;
  }
}

.breadcrumb__shape-4 {
  position: absolute;
  bottom: 0;
  left: 0;
}

.breadcrumb__shape-5 {
  position: absolute;
  bottom: 68%;
  right: 6%;
  animation: moving 9s linear infinite;
}

.breadcrumb__shape-6 {
  position: absolute;
  bottom: 12%;
  right: 1%;
  animation: tptranslateX2 4s infinite alternate;
}

.breadcrumb__main-img {
  animation: tpupdown 0.8s infinite alternate;
}

.breadcrumb__big-text {
  position: absolute;
  left: 43%;
  top: 54%;
  transform: translate(-50%, -50%);
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .breadcrumb__big-text {
    left: 40%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .breadcrumb__big-text {
    left: 37%;
    top: 51%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .breadcrumb__big-text {
    left: 39%;
    top: 51%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb__big-text {
    left: 40%;
    top: 51%;
  }
}

.breadcrumb__big-text h2 {
  font-size: 176px;
  -webkit-text-stroke: 2px #ebebf1;
  color: transparent;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .breadcrumb__big-text h2 {
    font-size: 165px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .breadcrumb__big-text h2 {
    font-size: 120px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .breadcrumb__big-text h2 {
    font-size: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb__big-text h2 {
    font-size: 76px;
  }
}

@media (max-width: 767px) {
  .breadcrumb__button {
    margin-bottom: 30px;
  }
}

.breadcrumb__bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  object-fit: cover;
}

.it-custom-accordio .accordion-buttons {
  color: var(--it-common-black);
  font-weight: 600;
  font-size: 22px;
  width: 100%;
  text-align: left;
  position: relative;
  padding: 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .it-custom-accordio .accordion-buttons {
    font-size: 19px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .it-custom-accordio .accordion-buttons {
    font-size: 17px;
  }
}

@media (max-width: 767px) {
  .it-custom-accordio .accordion-buttons {
    font-size: 14px;
    padding: 20px 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .it-custom-accordio .accordion-buttons {
    font-size: 17px;
    padding: 20px;
  }
}

.it-custom-accordio .accordion-buttons:not(.collapsed) {
  background-color: var(--it-theme-1);
}

.it-custom-accordio .accordion-buttons:not(.collapsed)::after {
  content: "\f068";
}

.it-custom-accordio .accordion-buttons:not(.collapsed) span {
  background-color: var(--it-common-white);
}

.it-custom-accordio .accordion-buttons:not(.collapsed) span i {
  color: var(--it-common-blue-4);
}

.it-custom-accordio .accordion-buttons::after {
  position: absolute;
  content: "\f067";
  font-family: "font awesome 5 pro";
  background-image: none;
  top: 50%;
  right: 3%;
  color: var(--tp-theme-1);
  width: auto;
  height: auto;
  font-weight: 700;
  font-size: 16px;
  opacity: 1;
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .it-custom-accordio .accordion-buttons::after {
    right: 5%;
    font-size: 13px;
  }
}

.it-custom-accordio .accordion-buttons span {
  margin-right: 15px;
  height: 30px;
  width: 30px;
  background-color: var(--it-common-blue-4);
  display: inline-block;
  line-height: 28px;
  text-align: center;
  border-radius: 50%;
}

@media (max-width: 767px) {
  .it-custom-accordio .accordion-buttons span {
    margin-right: 6px;
    height: 25px;
    width: 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .it-custom-accordio .accordion-buttons span {
    margin-right: 12px;
  }
}

.it-custom-accordio .accordion-buttons span i {
  font-size: 16px;
  color: var(--it-common-white);
  font-weight: 700;
}

@media (max-width: 767px) {
  .it-custom-accordio .accordion-buttons span i {
    font-size: 14px;
  }
}

.it-custom-accordio .accordion-body {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  padding: 30px 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .it-custom-accordio .accordion-body {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .it-custom-accordio .accordion-body {
    font-size: 14px;
  }
}

.it-custom-accordio .accordion-items {
  margin-bottom: 40px;
  border: 1px solid var(--it-border-1);
  border-radius: 20px;
  overflow: hidden;
}

.it-custom-accordio-2 .accordion-items {
  margin-bottom: 20px;
}

.it-custom-accordio-2 .accordion-buttons {
  position: relative;
  width: 100%;
  text-align: left;
  font-size: 18px;
  font-weight: 700;
  padding: 40px 45px;
  background-color: var(--tp-common-white);
  color: #212121;
  border-radius: 10px 10px 0 0;
}

@media (max-width: 767px) {
  .it-custom-accordio-2 .accordion-buttons {
    font-size: 17px;
    padding: 28px 20px;
  }
}

.it-custom-accordio-2 .accordion-buttons::after {
  position: absolute;
  content: "\f063";
  font-family: "font awesome 5 pro";
  background-image: none;
  top: 45px;
  right: 45px;
  color: #b8bcc8;
  width: auto;
  height: auto;
  font-weight: 500;
  font-size: 14px;
}

@media (max-width: 767px) {
  .it-custom-accordio-2 .accordion-buttons::after {
    top: 33px;
    right: 18px;
  }
}

.it-custom-accordio-2 .accordion-buttons.collapsed::after {
  content: "\f062";
}

.it-custom-accordio-2 .collapsed {
  border-radius: 10px 10px 10px 10px;
}

.it-custom-accordio-2 .accordion-body {
  background-color: var(--tp-common-white);
  padding: 20px 45px;
  box-shadow: 0 25px 32px 0 rgba(0, 0, 0, 0.06);
  position: relative;
  z-index: 1;
  border-radius: 0 0 10px 10px;
  font-size: 16px;
}

@media (max-width: 767px) {
  .it-custom-accordio-2 .accordion-body {
    padding: 20px 25px;
  }
}

.it-custom-accordio-2 .accordion-body::before {
  position: absolute;
  content: "";
  height: 2px;
  background-color: var(--tp-grey-1);
  top: 0;
  left: 45px;
  right: 45px;
}

.faq-accordio-border .accordion-items {
  background-color: var(--tp-common-white);
  border: 1px solid #f5f5f5;
  border-radius: 10px;
}

.search__popup {
  padding-top: 70px;
  padding-bottom: 100px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 99;
  -webkit-transform: translateY(calc(-100% - 80px));
  -moz-transform: translateY(calc(-100% - 80px));
  -ms-transform: translateY(calc(-100% - 80px));
  -o-transform: translateY(calc(-100% - 80px));
  transform: translateY(calc(-100% - 80px));
  -webkit-transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
  -moz-transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
  transition-delay: 0.7s;
}

.search__popup.search-opened {
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%);
  transition-delay: 0s;
}

.search__popup.search-opened .search__input {
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 1;
  transition-delay: 0.3s;
}

.search__popup.search-opened .search__input::after {
  width: 100%;
  transition-delay: 0.5s;
}

.search__popup-2 {
  background-color: var(--it-common-black-13);
}

.search__popup-2 .search__input .search-input-field~.search-focus-border {
  background-color: var(--it-theme-8);
}

.search__popup-3 .search__input .search-input-field~.search-focus-border {
  background-color: var(--it-theme-10);
}

.search__top {
  margin-bottom: 80px;
}

.search__input {
  position: relative;
  height: 80px;
  transition-delay: 0.5s;
  opacity: 0;
}

.search__input::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 0%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
  transition-delay: 0.3s;
}

.search__input input {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 0;
  outline: 0;
  font-size: 24px;
  color: var(--it-common-white);
  border-bottom: 1px solid transparent;
  padding: 0;
  padding-right: 30px;
}

.search__input input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-size: 24px;
}

.search__input input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-size: 24px;
}

.search__input input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-size: 24px;
}

.search__input input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-size: 24px;
}

.search__input button {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 18px;
  color: var(--it-common-white);
}

.search__input .search-input-field~.search-focus-border {
  position: absolute;
  bottom: 0;
  left: auto;
  right: 0;
  width: 0;
  height: 1px;
  background-color: var(--it-common-orange);
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.search__input .search-input-field:focus~.search-focus-border {
  width: 100%;
  left: 0;
  right: auto;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.search__close-btn {
  font-size: 25px;
  color: rgba(255, 255, 255, 0.3);
}

.search__close-btn:hover {
  color: var(--it-common-white);
}

.search__result-title {
  font-size: 50px;
  letter-spacing: -0.04em;
  margin-bottom: 0;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .search__result-title {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .search__result-title {
    font-size: 35px;
  }
}

.search__result-title span {
  color: var(--it-theme-1);
  display: inline-block;
}

.search__result-content p {
  font-size: 16px;
  line-height: 1.62;
  color: var(--it-text-1);
}

.search__result-input {
  position: relative;
}

.search__result-input-box {
  position: relative;
  margin-bottom: 20px;
}

.search__result-input-box button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 22px 43px;
}

@media (max-width: 767px) {
  .search__result-input-box button {
    position: relative;
    margin-top: 15px;
  }
}

.search__result-input-box button:hover {
  background-color: var(--it-common-black);
}

.search__result-input input {
  width: 100%;
  height: 70px;
  padding-left: 60px;
  padding-right: 177px;
  background-color: var(--it-common-white);
  border: 1px solid var(--it-common-white);
  box-shadow: -3px 0 0 var(--it-theme-1), 0 1px 2px rgba(3, 4, 28, 0.14);
}

@media (max-width: 767px) {
  .search__result-input input {
    padding-right: 25px;
  }
}

.search__result-input span {
  position: absolute;
  top: 50%;
  left: 30px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 18px;
  color: #a0a0b5;
}

.search__result-input span svg {
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
  transform: translateY(-2px);
}

.search__result-tags a {
  display: inline-block;
  font-family: var(--it-ff-space);
  font-size: 13px;
  font-weight: 500;
  border: 1px solid #d5d5dd;
  padding: 5px 18px;
  line-height: 1;
  margin-right: 2px;
  margin-bottom: 7px;
}

.search__result-tags a:hover {
  background-color: var(--it-theme-1);
  border-color: var(--it-theme-1);
  color: var(--it-common-white);
}

.search__blog-item {
  padding: 50px;
  border: 1px solid #eaeaef;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .search__blog-item {
    padding: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .search__blog-item {
    padding: 25px;
  }
}

@media (max-width: 767px) {
  .search__blog-item {
    padding: 20px;
  }
}

.search__blog-title {
  font-size: 30px;
  line-height: 1.2;
  letter-spacing: -0.04em;
  margin-bottom: 13px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .search__blog-title {
    font-size: 28px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .search__blog-title {
    font-size: 21px;
  }

  .search__blog-title br {
    display: none;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 767px) {
  .search__blog-title br {
    display: none;
  }
}

@media (max-width: 767px) {
  .search__blog-title {
    font-size: 25px;
  }
}

.search__blog-title a:hover {
  color: var(--it-theme-1);
}

.search__blog-tag {
  margin-bottom: 10px;
}

.search__blog-tag a {
  display: inline-block;
  background-color: rgba(99, 100, 219, 0.06);
  font-family: var(--it-ff-space);
  font-weight: 500;
  font-size: 15px;
  line-height: 1;
  color: var(--it-common-purple);
  padding: 4px 12px;
}

.search__blog-tag a:hover {
  background-color: var(--it-common-purple);
  color: var(--it-common-white);
}

.search__blog-meta span {
  color: var(--it-text-1);
  margin-right: 15px;
  margin-bottom: 10px;
  display: inline-block;
}

.search__blog-meta span i,
.search__blog-meta span svg {
  margin-right: 2px;
}

.search__blog-meta span svg {
  -webkit-transform: translateY(-1px);
  -moz-transform: translateY(-1px);
  -ms-transform: translateY(-1px);
  -o-transform: translateY(-1px);
  transform: translateY(-1px);
}

.search__blog-meta span svg path {
  stroke: #7a7e83;
}

.search__blog-meta-author {
  margin-bottom: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .search__blog-meta-author {
    margin-right: 20px;
  }
}

.search__blog-meta-author-thumb img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 10px;
}

.search__blog-meta-author-content span {
  display: inline-block;
  font-family: var(--it-ff-inter);
  font-size: 14px;
  color: #7a7e83;
}

.search__blog-meta-author-content span a {
  color: var(--it-common-black-11);
  font-weight: 500;
}

.search__blog-meta-author-content span a:hover {
  color: var(--it-theme-1);
}

.search__blog-content p {
  font-size: 15px;
  line-height: 1.47;
  color: var(--it-text-1);
  margin-bottom: 20px;
}

.search__blog-btn .it-btn-border {
  padding: 8px 29px;
}

.search__blog-btn .it-btn-border svg,
.search__blog-btn .it-btn-border i {
  margin-left: 5px;
}

.search__blog-btn .it-btn-border svg {
  -webkit-transform: translateY(-1px);
  -moz-transform: translateY(-1px);
  -ms-transform: translateY(-1px);
  -o-transform: translateY(-1px);
  transform: translateY(-1px);
}

.search__blog-btn .it-btn-border:hover {
  background-color: var(--it-theme-1);
  border-color: var(--it-theme-1);
  color: var(--it-common-white);
}

.section-subtitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.1;
  color: var(--it-common-black);
  margin-bottom: 15px;
  font-family: rubik, sans-serif;
  background-color: rgba(255, 220, 96, 0.21);
  display: inline-block;
  padding: 10px 30px;
  border-radius: 30px;
}

.section-subtitle.section-white-bg {
  background-color: var(--it-common-white);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .section-subtitle {
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-subtitle {
    font-size: 14px;
  }
}

.section-title {
  font-weight: 700;
  font-size: 40px;
  line-height: 1.3;
  color: var(--it-common-black);
}

.section-title span {
  background-color: var(--it-theme-1);
  padding: 0 5px;
}

.section-title i {
  font-style: normal;
  position: relative;
}

.section-title i::after {
  position: absolute;
  content: "";
  bottom: 6px;
  left: 0;
  height: 6px;
  width: 100%;
  background-color: var(--it-theme-1);
  border-radius: 20px;
}

@media (max-width: 767px) {
  .section-title i::after {
    bottom: 2px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .section-title {
    font-size: 37px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .section-title {
    font-size: 38px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title {
    font-size: 35px;
  }

  .section-title br {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title {
    font-size: 40px;
  }

  .section-title br {
    display: none;
  }
}

@media (max-width: 767px) {
  .section-title {
    font-size: 32px;
  }

  .section-title br {
    display: none;
  }
}

.section-blue-subtitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 1.1;
  color: var(--it-common-blue-4);
  margin-bottom: 10px;
  border-bottom: 1px solid var(--it-common-blue-4);
  display: inline-block;
  font-family: rubik, sans-serif;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .section-blue-subtitle {
    font-size: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-blue-subtitle {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .section-blue-subtitle {
    font-size: 20px;
  }
}

.hero-title {
  font-weight: 700;
  font-size: 60px;
  line-height: 1.3;
  color: var(--it-common-black);
}

.hero-title span {
  background-color: var(--it-theme-1);
  padding: 0 5px;
  -webkit-text-stroke: 2px var(--it-common-black);
  color: transparent;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .hero-title {
    font-size: 54px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .hero-title {
    font-size: 54px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero-title {
    font-size: 48px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-title {
    font-size: 38px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-title {
    font-size: 38px;
  }
}

@media (max-width: 767px) {
  .hero-title {
    font-size: 35px;
  }
}

.hero-2-title {
  font-weight: 700;
  font-size: 54px;
  line-height: 1.3;
  color: var(--it-common-black);
  padding-bottom: 15px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero-2-title {
    font-size: 48px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-2-title {
    font-size: 38px;
  }
}

@media (max-width: 767px) {
  .hero-2-title {
    font-size: 37px;
  }
}

.hero-2-title span {
  position: relative;
}

.hero-2-title span::after {
  position: absolute;
  bottom: -3%;
  left: 0;
  content: "";
  height: 6px;
  width: 92%;
  background-color: var(--it-theme-1);
  border-radius: 20px;
  animation: section-animation 4s infinite;
}

.hero-3-title {
  font-weight: 700;
  font-size: 50px;
  line-height: 1.3;
  color: var(--it-common-black);
  padding-bottom: 15px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .hero-3-title {
    font-size: 45px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .hero-3-title {
    font-size: 41px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero-3-title {
    font-size: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-3-title {
    font-size: 41px;
  }

  .hero-3-title br {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-3-title {
    font-size: 41px;
  }

  .hero-3-title br {
    display: none;
  }
}

@media (max-width: 767px) {
  .hero-3-title {
    font-size: 35px;
  }

  .hero-3-title br {
    display: none;
  }
}

.hero-4-title {
  font-weight: 700;
  font-size: 42px;
  line-height: 1.5;
  color: var(--it-common-black);
  padding-bottom: 15px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .hero-4-title {
    font-size: 45px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .hero-4-title {
    font-size: 41px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero-4-title {
    font-size: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-4-title {
    font-size: 41px;
  }

  .hero-4-title br {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-4-title {
    font-size: 37px;
  }

  .hero-4-title br {
    display: none;
  }
}

@media (max-width: 767px) {
  .hero-4-title {
    font-size: 35px;
  }

  .hero-4-title br {
    display: none;
  }
}

.hero-4-title span {
  background-color: #95b8ff;
  color: var(--it-common-black);
  padding: 0 10px;
}

.hero-5-title {
  font-weight: 700;
  font-size: 42px;
  line-height: 1.5;
  color: var(--it-common-black);
  padding-bottom: 15px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .hero-5-title {
    font-size: 45px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .hero-5-title {
    font-size: 38px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero-5-title {
    font-size: 36px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-5-title {
    font-size: 36px;
  }

  .hero-5-title br {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-5-title {
    font-size: 37px;
  }

  .hero-5-title br {
    display: none;
  }
}

@media (max-width: 767px) {
  .hero-5-title {
    font-size: 34px;
  }

  .hero-5-title br {
    display: none;
  }
}

.hero-5-title span {
  background-color: #95b8ff;
  color: var(--it-common-black);
  padding: 0 10px;
}

.mobile-menu.mean-container {
  overflow: hidden;
}

@media (max-width: 767px) {
  .mobile-menu.mean-container {
    margin-bottom: 30px;
  }
}

.mobile-menu .sidebar-list {
  clear: both;
}

.mobile-menu .sidebar-list li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 5px;
  color: var(--tp-common-white);
}

.mobile-menu .sidebar-list li::after {
  top: 10px;
  left: 0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border: 1px solid #c00;
  background-color: transparent;
  content: "";
  display: inline-block;
  position: absolute;
}

.mobile-menu .tp-sidebar-social {
  margin-top: 20px;
}

.mobile-menu .tp-sidebar-social a {
  margin-right: 5px;
  background-color: var(--tp-theme-primary);
  color: #fff;
  display: inline-block;
  width: 35px;
  height: 35px;
  text-align: center;
  font-size: 14px;
  line-height: 34px;
}

.sidebar-title h3 {
  color: var(--tp-common-white);
}

.mean-container a.meanmenu-reveal {
  display: none;
}

.mean-container .mean-nav {
  background: 0 0;
  margin-top: 0;
}

.mean-container .mean-bar {
  padding: 0;
  min-height: auto;
  background: 0 0;
}

.mean-container .mean-nav>ul {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
  display: block !important;
}

.mean-container a.meanmenu-reveal {
  display: none !important;
}

.mean-container .mean-nav ul li a {
  width: 100%;
  padding: 15px 0;
  color: #fff;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
}

.mean-container .mean-nav ul li a:hover {
  color: var(--it-theme-1);
}

.mean-container .mean-nav ul li a.mean-expand {
  margin-top: 10px;
  padding: 0 !important;
  line-height: 14px;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  height: 30px;
  width: 30px;
  line-height: 30px;
  color: #fff;
  line-height: 30px;
  top: 0;
  font-weight: 400;
}

.mean-container .mean-nav ul li a.mean-expand:hover {
  color: var(--it-theme-1);
  border-color: #888;
}

.mean-container .mean-nav ul li>a>i {
  display: none;
}

.mean-container .mean-nav ul li>a.mean-expand i {
  display: inline-block;
}

.mean-container .mean-nav>ul>li:first-child>a {
  border-top: 0;
}

.mean-container .mean-nav ul li a.mean-expand.mean-clicked {
  color: var(--tp-theme-2);
}

.mean-container .mean-nav ul li a.mean-expand.mean-clicked i {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  color: var(--tp-common-white);
}

.header__area.header-sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
  width: 100%;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  z-index: 999;
}

.header__area.header-sticky.header__mt {
  margin-top: 0;
}

.header__area.header-sticky.header__space {
  padding-top: 0;
}

.header__area.header-sticky.header-width {
  width: auto;
  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
}

.header__area.header-sticky.header-five {
  background-color: var(--it-common-black);
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .header__area.header-sticky.header-five .header__logo {
    padding: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .header__area.header-sticky .header__logo {
    padding: 20px 0;
  }
}

.header-top__plr {
  padding: 10px 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .header-top__plr {
    padding: 10px 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .header-top__plr {
    padding: 10px 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .header-top__plr {
    padding: 10px 30px;
  }
}

@media (max-width: 767px) {
  .header-top__plr {
    padding: 10px 0;
  }
}

.header-top__bg {
  background-color: var(--it-common-blue-3);
}

.header-top__lang ul li {
  position: relative;
  list-style-type: none;
  display: inline-block;
}

.header-top__lang ul li ul {
  width: 100px;
  background-color: var(--it-common-black);
  position: absolute;
  top: 150%;
  left: 0;
  z-index: 99;
  padding: 20px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.header-top__lang ul li ul li {
  list-style-type: none;
  margin-bottom: 5px;
  display: inline-block;
}

.header-top__lang ul li ul li:last-child {
  margin-bottom: 0;
}

.header-top__lang ul li ul li a {
  color: var(--it-common-white);
}

.header-top__lang ul li ul li a:hover {
  padding-left: 5px;
  color: var(--it-theme-1);
}

.header-top__lang ul li a {
  color: var(--it-common-white);
}

.header-top__lang ul li a:hover {
  color: var(--it-theme-1);
}

.header-top__lang ul li a:hover i {
  transform: rotate(-180deg);
}

.header-top__lang ul li a i {
  margin-left: 5px;
  transition: 0.3s;
}

.header-top__lang ul li:hover ul {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.header-top__social span {
  color: var(--it-common-white);
}

.header-top__social a {
  color: var(--it-common-white);
  padding-left: 30px;
}

@media (max-width: 767px) {
  .header-top__social a {
    padding-left: 20px;
  }
}

.header-bg-color {
  background-color: var(--it-common-white);
  padding: 0 30px;
  border-radius: 0 0 30px 30px;
  border-right: 3px solid var(--it-theme-1);
  border-left: 3px solid var(--it-theme-1);
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .header-bg-color {
    padding: 10px 30px;
  }
}

@media (max-width: 767px) {
  .header-bg-color {
    padding: 10px 5px;
  }
}

.header-bg-color-2 {
  background-color: var(--it-common-white);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0 35px;
  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-bg-color-2 {
    padding: 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .header-bg-color-2 {
    padding: 10px 30px;
  }
}

@media (max-width: 767px) {
  .header-bg-color-2 {
    padding: 10px 5px;
  }
}

.header__transparent {
  position: absolute;
  z-index: 9;
  left: 0;
  right: 0;
}

.header__transparent-2 {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
}

.header__plr {
  padding: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .header__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .header__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__plr {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__plr {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .header__plr {
    padding: 0;
  }
}

.header__mlr {
  margin: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px),
only screen and (min-width: 1400px) and (max-width: 1599px) {
  .header__mlr {
    margin: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .header__mlr {
    margin: 0 30px;
  }
}

@media (max-width: 767px) {
  .header__mlr {
    margin: 0;
  }
}

.header__space {
  padding-top: 25px;
}

.header__main-menu ul {
  /* margin-right: -65px; */
}

@media only screen and (min-width: 1600px) and (max-width: 1800px),
only screen and (min-width: 1400px) and (max-width: 1599px),
only screen and (min-width: 1200px) and (max-width: 1399px) {
  .header__main-menu ul {
    margin-right: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__main-menu ul {
    margin-right: -30px;
  }
}

.header__main-menu ul li {
  display: inline-block;
  list-style: none;
  position: relative;
  padding-left: 40px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px),
only screen and (min-width: 1400px) and (max-width: 1599px) {
  .header__main-menu ul li {
    padding-left: 26px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .header__main-menu ul li {
    padding-left: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__main-menu ul li {
    padding-left: 0;
    padding: 0 14px;
  }
}

.header__main-menu ul li .submenu {
  position: absolute;
  top: 120%;
  left: 0;
  width: 240px;
  background-color: var(--it-common-black);
  text-align: left;
  padding: 40px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.header__main-menu ul li .submenu li {
  padding-left: 0;
  display: block;
  margin-bottom: 12px;
}

.header__main-menu ul li .submenu li:last-child {
  margin-bottom: 0;
}

.header__main-menu ul li .submenu li a {
  display: block;
  padding: 0;
  color: var(--it-common-white);
  transition: 0.3s;
}

.header__main-menu ul li .submenu li a::before {
  display: none;
}

.header__main-menu ul li .submenu li a:hover {
  color: var(--it-theme-1);
}

.header__main-menu ul li .submenu.has-megamenu {
  width: 700px;
  display: inline-block;
  left: -225px;
  padding-left: 70px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .header__main-menu ul li .submenu.has-megamenu {
    width: 645px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__main-menu ul li .submenu.has-megamenu {
    width: 600px;
  }
}

.header__main-menu ul li .submenu.has-megamenu li {
  width: 33.33%;
  float: left;
}

.header__main-menu ul li.has-dropdown::after {
  content: "\f103";
  font-family: "font awesome 5 pro";
  display: inline-block;
  font-size: 14px;
  margin-left: 5px;
  color: var(--it-common-black);
  font-weight: 400;
  transition: 0.3s;
}

.header__main-menu ul li:hover.has-dropdown::after {
  transform: rotate(-180deg);
}

.header__main-menu ul li:hover a::before {
  opacity: 1;
  visibility: visible;
  width: 100%;
}

.header__main-menu ul li:hover .submenu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.header__main-menu ul li a {
  color: var(--it-common-black);
  font-weight: 500;
  font-size: 17px;
  display: inline-block;
  transition: 0.3s;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__main-menu ul li a {
    font-size: 15px;
  }
}

.header__main-menu ul li a::before {
  content: "";
  height: 2px;
  width: 0;
  background-color: var(--it-common-black);
  position: absolute;
  bottom: 31px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  border-radius: 10px;
}

.header__menu-bar {
  margin-left: 40px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .header__menu-bar {
    margin-left: 20px;
  }
}
@media only screen and (min-width: 992px){
  .header__menu-bar {
    margin-left: 20px;
  }
  .header__menu-bar button {
    display: none;
  }
}

/* .header__menu-bar button {
  font-size: 25px;
  color: var(--it-theme-1);
  background-color: var(--it-common-black);
  height: 52px;
  width: 52px;
  line-height: 52px;
  border-radius: 5px;
  display: none;
} */



@media screen and (max-width: 992px) {
  .header__menu-bar button {
    font-size: 25px;
    color: var(--it-theme-1);
    background-color: var(--it-common-black);
    height: 52px;
    width: 52px;
    line-height: 52px;
    border-radius: 5px;
  }
}



@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .header__menu-bar button.bars-color {
    margin-right: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .header__menu-bar button.bars-color {
    margin-right: 0;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .header__menu-3 ul li {
    padding-left: 21px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__menu-3 ul li {
    padding: 0 11px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .header__menu-3 ul li a {
    font-size: 15px;
  }
}

.header__white-menu ul li a {
  color: var(--it-common-white);
}

.header__white-menu ul li a::before {
  bottom: 0;
  height: 2px;
}

.header__white-menu ul li a:hover {
  color: var(--it-theme-1);
}

.header__white-menu ul li.has-dropdown::after {
  color: var(--it-common-white);
}

.header__white-menu ul li:hover::after {
  color: var(--it-theme-1);
}

.header__border {
  border-bottom: 1px solid var(--it-border-2);
}

.header__logo-border {
  position: relative;
  height: 92px;
  line-height: 92px;
}

.header__logo-border::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  height: 100%;
  width: 1px;
  background-color: var(--it-border-2);
  display: inline-block;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .header__logo-border::after {
    display: none;
  }
}

.header__logo-border::before {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  height: 100%;
  width: 1px;
  background-color: var(--it-border-2);
  display: inline-block;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .header__logo-border::before {
    display: none;
  }
}

.header__action-border {
  padding-right: 40px;
  position: relative;
  height: 92px;
  line-height: 92px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .header__action-border {
    padding-right: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .header__action-border {
    padding-right: 0;
  }
}

.header__action-border::before {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  height: 100%;
  width: 1px;
  background-color: var(--it-border-2);
  display: inline-block;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .header__action-border::before {
    display: none;
  }
}

.header__mt {
  margin-top: 50px;
}

@media (max-width: 767px) {
  .header__mt {
    margin-top: 0;
  }
}

.slider__plr {
  padding: 0 200px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .slider__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .slider__plr {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .slider__plr {
    padding: 0;
  }
}

.slider__space {
  padding-top: 235px;
  padding-bottom: 190px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider__space {
    padding-top: 175px;
  }
}

@media (max-width: 767px) {
  .slider__space {
    padding-top: 180px;
  }
}

.slider__btn {
  line-height: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider__btn {
    padding-bottom: 70px;
  }
}

@media (max-width: 767px) {
  .slider__btn {
    padding-bottom: 50px;
  }
}

.slider__thumb {
  animation: tptranslateY2 4s infinite alternate;
}

.slider__content {
  padding-bottom: 20px;
}

.slider__content p {
  line-height: 1.6;
  padding-right: 170px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px),
only screen and (min-width: 1400px) and (max-width: 1599px) {
  .slider__content p {
    padding-right: 160px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .slider__content p {
    padding-right: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .slider__content p {
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .slider__content p {
    padding-right: 0;
    font-size: 15px;
  }
}

.slider__shape-top-1 {
  position: absolute;
  left: 0;
  top: 0;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .slider__shape-top-1 {
    left: -58px;
  }
}

.slider__shape-top-2 {
  position: absolute;
  left: 0;
  top: 0;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .slider__shape-top-2 {
    left: -54px;
  }
}

.slider__shape-top-3 {
  position: absolute;
  right: -25%;
  bottom: -15%;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .slider__shape-top-3 {
    right: -26%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .slider__shape-top-3 {
    right: -30%;
    bottom: -20%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .slider__shape-top-3 {
    right: -45%;
    bottom: -21%;
  }
}

.slider__shape-top-4 {
  position: absolute;
  right: -24%;
  bottom: -18%;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .slider__shape-top-4 {
    right: -24%;
    bottom: -18%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .slider__shape-top-4 {
    right: -28%;
    bottom: -23%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .slider__shape-top-4 {
    right: -43%;
    bottom: -24%;
  }
}

.slider__shape-top-5 {
  position: absolute;
  right: 4%;
  top: 5%;
  animation: tpupdown 0.8s infinite alternate;
}

.slider__shape-top-6 {
  position: absolute;
  right: 48%;
  top: 17%;
  z-index: -1;
  animation: moving 9s linear infinite;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider__shape-top-6 {
    right: 27%;
    top: 9%;
  }
}

.slider__shape-top-7 {
  position: absolute;
  right: 5%;
  top: 43%;
  z-index: -1;
  animation: rotate2 15s linear infinite;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider__shape-top-7 {
    top: 35%;
  }
}

.slider__shape-top-8 {
  position: absolute;
  left: 0;
  bottom: 0;
  animation: tpupdown 0.8s infinite alternate;
}

.slider__shape-top-9 {
  position: absolute;
  left: 37%;
  bottom: 40%;
}

.slider__shape-top-10 {
  position: absolute;
  left: 19%;
  bottom: 5%;
  animation: tptranslateX2 4s infinite alternate;
}

.slider__shape-top-11 {
  position: absolute;
  left: 50%;
  bottom: 8%;
  animation: moving 9s linear infinite;
}

@media (max-width: 767px) {
  .slider__shape-top-11 {
    left: 76%;
    bottom: 8%;
  }
}

.slider-2__plr {
  padding: 0 250px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .slider-2__plr {
    padding: 0 200px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .slider-2__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-2__plr {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .slider-2__plr {
    padding: 0;
  }
}

.slider-2__space {
  padding-top: 170px;
  padding-bottom: 50px;
}

@media (max-width: 767px) {
  .slider-2__space {
    padding-top: 180px;
  }
}

.slider-2__btn {
  line-height: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-2__btn {
    padding-bottom: 70px;
  }
}

@media (max-width: 767px) {
  .slider-2__btn {
    padding-bottom: 50px;
  }
}

.slider-2__thumb {
  animation: tptranslateY2 4s infinite alternate;
}

.slider-2__content {
  padding-bottom: 20px;
}

.slider-2__content p {
  line-height: 1.6;
  padding-right: 120px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px),
only screen and (min-width: 1400px) and (max-width: 1599px) {
  .slider-2__content p {
    padding-right: 160px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .slider-2__content p {
    padding-right: 93px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-2__content p {
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .slider-2__content p {
    padding-right: 0;
    font-size: 15px;
  }
}

.slider-2__title-sm {
  font-weight: 600;
  font-size: 25px;
  padding-bottom: 10px;
}

.slider-2__shape-1 {
  position: absolute;
  right: 12%;
  top: 24%;
  animation: rotate2 15s linear infinite;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .slider-2__shape-1 {
    right: 7%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .slider-2__shape-1 {
    right: 4%;
    top: 24%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-2__shape-1 {
    right: 5%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-2__shape-1 {
    right: 2%;
    top: 37%;
  }
}

.slider-2__shape-2 {
  position: absolute;
  right: 45%;
  top: 49%;
  animation: moving 9s linear infinite;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px),
only screen and (min-width: 1400px) and (max-width: 1599px) {
  .slider-2__shape-2 {
    top: 54%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .slider-2__shape-2 {
    right: 43%;
    top: 56%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-2__shape-2 {
    top: 70%;
    right: 43%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-2__shape-2 {
    right: 72%;
    top: 82%;
  }
}

.slider-2__shape-3 {
  position: absolute;
  left: 2%;
  top: 39%;
  animation: tptranslateX2 4s infinite alternate;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .slider-2__shape-3 {
    left: -2%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .slider-2__shape-3 {
    left: -9%;
  }
}

.slider-2__shape-4 {
  position: absolute;
  left: 0;
  top: 3%;
  animation: tpupdown 0.8s infinite alternate;
}

.slider-2__shape-5 {
  position: absolute;
  right: 3%;
  top: 6%;
  animation: moving 9s linear infinite;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .slider-2__shape-5 {
    right: 0;
    top: 6%;
  }
}

.slider-2__shape-6 {
  position: absolute;
  right: 65%;
  top: 0;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .slider-2__shape-6 {
    right: 68%;
    top: -11px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .slider-2__shape-6 {
    right: 72%;
    top: -15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .slider-2__shape-6 {
    right: 73%;
    top: -2%;
  }
}

.slider-2__shape-7 {
  position: absolute;
  bottom: 0;
  left: 0;
}

.slider-3__plr {
  padding: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .slider-3__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .slider-3__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-3__plr {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .slider-3__plr {
    padding: 0;
  }
}

.slider-3__space {
  padding-top: 300px;
  padding-bottom: 170px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .slider-3__space {
    padding-top: 220px;
    padding-bottom: 80px;
  }
}

.slider-3__content-list {
  padding-bottom: 30px;
}

.slider-3__content-list ul li {
  list-style-type: none;
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
}

.slider-3__content-list ul li i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  font-weight: 500;
  color: var(--it-common-blue-3);
  font-size: 16px;
}

.slider-3__content-list ul li span {
  font-weight: 400;
  font-size: 17px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-3__content-list ul li span {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .slider-3__content-list ul li span {
    font-size: 15px;
  }
}

.slider-3__main-img img {
  max-width: inherit;
  transform: translateX(40px);
  animation: tpupdown 0.8s infinite alternate;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .slider-3__main-img img {
    transform: translateX(15px);
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .slider-3__main-img img {
    transform: translateX(-20px);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .slider-3__main-img img {
    max-width: 100%;
    transform: translateX(-20px);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-3__main-img img {
    max-width: 100%;
    transform: translateX(0);
  }
}

@media (max-width: 767px) {
  .slider-3__main-img img {
    max-width: 100%;
    transform: translateX(0);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-3__text p {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .slider-3__text p {
    font-size: 14px;
  }

  .slider-3__text p br {
    display: none;
  }
}

.slider-3__shape-1 {
  position: absolute;
  top: -4%;
  left: 10%;
  animation: tptranslateX2 4s infinite alternate;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-3__shape-1 {
    top: -5%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-3__shape-1 {
    top: -5%;
    left: -1%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-3__shape-1 {
    top: -6%;
    left: -6%;
  }
}

.slider-3__shape-2 {
  position: absolute;
  bottom: 0;
  left: 0;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .slider-3__shape-2 {
    bottom: 0%;
    left: -4%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .slider-3__shape-2 {
    left: -4%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .slider-3__shape-2 {
    bottom: -24%;
    left: -4%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-3__shape-2 {
    bottom: -24%;
    left: -4%;
  }
}

.slider-3__shape-3 {
  position: absolute;
  top: 11%;
  left: 0;
  animation: tpupdown 1s infinite alternate;
}

.slider-3__shape-4 {
  position: absolute;
  top: 27%;
  right: 16%;
  animation: moving 9s linear infinite;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .slider-3__shape-4 {
    right: 8%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .slider-3__shape-4 {
    right: 10%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-3__shape-4 {
    top: 51%;
  }
}

.slider-3__shape-5 {
  position: absolute;
  bottom: 11%;
  right: 56%;
  animation: moving 9s linear infinite;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-3__shape-5 {
    right: 50%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-3__item {
    margin-bottom: 90px;
  }
}

@media (max-width: 767px) {
  .slider-3__content {
    margin-bottom: 70px;
  }
}

.slider-4__plr {
  padding: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .slider-4__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .slider-4__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-4__plr {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .slider-4__plr {
    padding: 0;
  }
}

.slider-4__space {
  padding-top: 200px;
  padding-bottom: 200px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .slider-4__space {
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-4__space {
    padding-bottom: 50px;
    padding-top: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .slider-4__space {
    padding-top: 150px;
    padding-bottom: 50px;
  }
}

.slider-4__shape-1 {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -2;
}

.slider-4__shape-2 {
  position: absolute;
  left: 19%;
  top: 18%;
  animation: moving 9s linear infinite;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .slider-4__shape-2 {
    left: 17%;
    top: 26%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-4__shape-2 {
    top: 30%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-4__shape-2 {
    left: 9%;
    top: 12%;
  }
}

.slider-4__shape-3 {
  position: absolute;
  left: 20%;
  top: 9%;
  animation: tptranslateX2 4s infinite alternate;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .slider-4__shape-3 {
    left: 20%;
    top: 15%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-4__shape-3 {
    top: 15%;
  }
}

.slider-4__shape-4 {
  position: absolute;
  right: 35%;
  top: 13%;
  animation: tptranslateX2 4s infinite alternate;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .slider-4__shape-4 {
    top: 16%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-4__shape-4 {
    top: 19%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-4__shape-4 {
    top: 10%;
    right: 23%;
  }
}

.slider-4__shape-5 {
  position: absolute;
  right: 2%;
  top: 7%;
  animation: zoom 7s infinite;
}

.slider-4__shape-6 {
  position: absolute;
  right: 4%;
  top: 23%;
  animation: zoom 4s infinite;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-4__shape-6 {
    top: 17%;
  }
}

.slider-4__shape-8 {
  position: absolute;
  left: 44%;
  top: 62%;
  animation: tptranslateX2 4s infinite alternate;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .slider-4__shape-8 {
    left: 38%;
  }
}

.slider-4__shape-9 {
  position: absolute;
  left: 31%;
  bottom: 11%;
  animation: zoom 7s infinite;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .slider-4__shape-9 {
    left: 39%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .slider-4__shape-9 {
    left: 38%;
    bottom: 12%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .slider-4__shape-9 {
    left: 45%;
    bottom: 8%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-4__shape-9 {
    bottom: 2%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-4__shape-9 {
    left: 24%;
    bottom: 4%;
    animation: jumpTwo 3s infinite linear;
  }
}

.slider-4__shape-10 {
  position: absolute;
  left: 25%;
  bottom: 13%;
  animation: jumpTwo 3s infinite linear;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .slider-4__shape-10 {
    left: 28%;
    bottom: 9%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .slider-4__shape-10 {
    left: 24%;
    bottom: 6%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .slider-4__shape-10 {
    left: 25%;
    bottom: 5%;
  }
}

.slider-4__shape-11 {
  position: absolute;
  right: 25%;
  bottom: 0%;
  animation: tptranslateX2 4s infinite alternate;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .slider-4__shape-11 {
    bottom: 6%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .slider-4__shape-11 {
    bottom: 12%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .slider-4__shape-11 {
    right: 25%;
    bottom: 11%;
  }
}

.slider-4__item {
  position: relative;
  z-index: 5;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-4__item {
    margin-bottom: 90px;
  }
}

@media (max-width: 767px) {
  .slider-4__item {
    padding-top: 40px;
    margin-bottom: 70px;
  }
}

.slider-4__text p {
  padding-bottom: 20px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px),
only screen and (min-width: 1400px) and (max-width: 1599px),
only screen and (min-width: 1200px) and (max-width: 1399px) {
  .slider-4__text p br {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-4__text p {
    font-size: 15px;
  }

  .slider-4__text p br {
    display: none;
  }
}

@media (max-width: 767px) {
  .slider-4__text p br {
    display: none;
  }
}

.slider-4__main-img {
  animation: tptranslateY2 4s infinite alternate;
}

.slider-4__main-img img {
  max-width: inherit;
  margin-left: -150px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .slider-4__main-img img {
    max-width: 130%;
    margin-left: -130px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .slider-4__main-img img {
    max-width: 122%;
    margin-left: -20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .slider-4__main-img img {
    max-width: 110%;
    margin-left: -30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-4__main-img img {
    max-width: 120%;
    margin-left: -50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .slider-4__main-img img {
    max-width: 100%;
    margin-left: 0;
  }
}

.slider-4__black-bg {
  background: var(--it-common-black);
}

.slider-4__img-shape-1 {
  position: absolute;
  bottom: -7%;
  right: 0%;
  z-index: -1;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .slider-4__img-shape-1 {
    right: 9%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .slider-4__img-shape-1 {
    right: -19%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-4__img-shape-1 {
    right: -37%;
  }
}

@media (max-width: 767px) {
  .slider-4__img-shape-1 {
    right: -62%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-4__img-shape-1 {
    right: -15%;
  }
}

@media only screen and (min-width: 440px) and (max-width: 575px) {
  .slider-4__img-shape-1 {
    right: -40%;
    bottom: -5%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .slider-4__img-shape-1 img {
    width: 50%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-4__img-shape-1 img {
    width: 40%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-4__img-shape-1 img {
    width: 70%;
  }
}

@media (max-width: 767px) {
  .slider-4__img-shape-1 img {
    width: 39%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-4__img-shape-1 img {
    width: 58%;
  }
}

.slider-4__img-shape-2 {
  position: absolute;
  top: -8%;
  left: -9%;
  z-index: -1;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .slider-4__img-shape-2 {
    top: -13%;
    left: 3%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .slider-4__img-shape-2 {
    top: -11%;
    left: 2%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-4__img-shape-2 {
    top: -11%;
    left: -4%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .slider-4__img-shape-2 img {
    width: 65%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-4__img-shape-2 img {
    width: 60%;
  }
}

.slider-4__social h5 {
  font-size: 20px;
  font-weight: 500;
  display: inline-block;
  margin-right: 12px;
}

.slider-4__social i {
  font-size: 18px;
  padding: 0 8px;
  color: var(--it-common-paste-3);
}

.slider-4__social i.apple {
  color: #ffadad;
}

.slider-4__big-text {
  position: absolute;
  left: -11%;
  top: 23%;
  z-index: -1;
  transform: rotate(630deg);
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .slider-4__big-text {
    left: -6%;
    top: 24%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .slider-4__big-text {
    left: -7%;
    top: 26%;
  }
}

.slider-4__big-text h2 {
  font-size: 221px;
  -webkit-text-stroke: 2px #ebebf1;
  font-weight: 600;
  color: transparent;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px),
only screen and (min-width: 1400px) and (max-width: 1599px) {
  .slider-4__big-text h2 {
    font-size: 110px;
  }
}

.slider-5__plr {
  padding: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .slider-5__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .slider-5__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-5__plr {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .slider-5__plr {
    padding: 0;
  }
}

.slider-5__space {
  padding-top: 240px;
  padding-bottom: 130px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .slider-5__space {
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-5__space {
    padding-bottom: 100px;
    padding-top: 220px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .slider-5__space {
    padding-top: 200px;
  }
}

.slider-5__main-img {
  position: relative;
  z-index: 1;
  animation: tptranslateY2 4s infinite alternate;
}

.slider-5__main-img img {
  max-width: inherit;
  transform: translateX(-50px);
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .slider-5__main-img img {
    max-width: 100%;
    transform: translateX(33px);
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .slider-5__main-img img {
    max-width: 100%;
    transform: translateX(65px);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .slider-5__main-img img {
    max-width: 100%;
    transform: translateX(20px);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-5__main-img img {
    max-width: 100%;
    transform: translateX(0);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-5__main-img img {
    max-width: 100%;
    transform: translateX(0);
  }
}

@media (max-width: 767px) {
  .slider-5__main-img img {
    max-width: 100%;
    transform: translateX(0);
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-5__main-img img {
    max-width: 100%;
    transform: translateX(0);
  }
}

.slider-5__text p {
  color: #d0d0d0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-5__text p br {
    display: none;
  }
}

@media (max-width: 767px) {
  .slider-5__text p {
    font-size: 15px;
  }

  .slider-5__text p br {
    display: none;
  }
}

.slider-5__content-list {
  padding-bottom: 30px;
}

.slider-5__content-list ul li {
  list-style-type: none;
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
  color: #d0d0d0;
  transition: 0.3s;
  text-decoration: transparent;
}

.slider-5__content-list ul li:hover {
  text-decoration: underline;
}

.slider-5__content-list ul li i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  font-weight: 500;
  color: #7da4f1;
  font-size: 16px;
}

.slider-5__content-list ul li span {
  font-weight: 400;
  font-size: 17px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-5__content-list ul li span {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .slider-5__content-list ul li span {
    font-size: 15px;
  }
}

.slider-5__button-box {
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .slider-5__button-box {
    flex-wrap: wrap;
  }
}

@media (max-width: 767px) {
  .slider-5__button {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-5__button {
    margin-bottom: 0;
  }
}

.slider-5__icon span {
  height: 50px;
  width: 50px;
  line-height: 48px;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  border: 2px solid #95b8ff;
  color: #95b8ff;
  font-size: 15px;
  margin-right: 20px;
}

.slider-5__icon span i {
  padding-left: 3px;
}

.slider-5__icon h5 {
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  color: #d0d0d0;
  text-decoration: underline;
  transition: 0.3s;
}

.slider-5__icon h5:hover {
  color: #95b8ff;
}

.slider-5__social-box span {
  font-family: poppins, sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: var(--it-common-white);
  padding-bottom: 12px;
  display: inline-block;
  padding-right: 20px;
}

@media (max-width: 767px) {
  .slider-5__social-box span {
    font-size: 16px;
  }
}

.slider-5__social-box a {
  height: 33px;
  width: 33px;
  border-radius: 50%;
  border: 1px solid #a1a1a2;
  display: inline-block;
  line-height: 33px;
  text-align: center;
  margin-right: 5px;
  transition: 0.3s;
}

.slider-5__social-box a i {
  color: #a1a1a2;
  transition: 0.3s;
}

.slider-5__social-box a:hover {
  background-color: var(--it-theme-1);
  border: 1px solid transparent;
}

.slider-5__social-box a:hover i {
  color: var(--it-common-black);
}

.slider-5__main-img-content {
  background-color: var(--it-theme-1);
  border-radius: 50px;
  position: absolute;
  bottom: 10%;
  right: -5%;
  padding: 12px 20px;
  padding-right: 40px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .slider-5__main-img-content {
    bottom: 8%;
    right: -15%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-5__main-img-content {
    padding: 4px 15px;
    padding-right: 26px;
  }
}

@media (max-width: 767px) {
  .slider-5__main-img-content {
    bottom: -1%;
    right: 16%;
    padding: 4px 15px;
    padding-right: 26px;
  }
}

.slider-5__img-icon span {
  font-weight: 500;
  color: var(--it-common-black);
  font-size: 42px;
  padding-right: 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-5__img-icon span {
    padding-right: 10px;
  }
}

@media (max-width: 767px) {
  .slider-5__img-icon span {
    font-size: 34px;
    padding-right: 5px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-5__img-icon span {
    font-size: 37px;
    padding-right: 15px;
  }
}

.slider-5__img-text h5 {
  font-weight: 700;
  font-size: 27px;
  margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-5__img-text h5 {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .slider-5__img-text h5 {
    font-size: 18px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-5__img-text h5 {
    font-size: 22px;
  }
}

.slider-5__img-text span {
  font-weight: 500;
  color: #3f3f3f;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-5__img-text span {
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  .slider-5__img-text span {
    font-size: 12px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-5__img-text span {
    font-size: 14px;
  }
}

.slider-5__shape-1 {
  position: absolute;
  top: 17%;
  left: 18%;
}

.slider-5__shape-2 {
  position: absolute;
  top: 4%;
  right: 5%;
  animation: tpupdown 0.8s infinite alternate;
}

.slider-5__shape-3 {
  position: absolute;
  bottom: 22%;
  left: 0;
  animation: tptranslateX2 4s infinite alternate;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .slider-5__shape-3 {
    bottom: 7%;
  }
}

.slider-5__shape-4 {
  position: absolute;
  bottom: 62%;
  left: 0;
  animation: tpupdown 0.8s infinite alternate;
}

.slider-5__shape-5 {
  position: absolute;
  bottom: 24%;
  left: 35%;
  animation: moving 9s linear infinite;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .slider-5__shape-5 {
    bottom: 21%;
    left: 35%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .slider-5__shape-5 {
    bottom: 18%;
    left: 40%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-5__item {
    margin-bottom: 180px;
  }
}

@media (max-width: 767px) {
  .slider-5__item {
    margin-bottom: 70px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-5__item {
    margin-bottom: 150px;
  }
}

.slider-5__sub-img-1 {
  position: absolute;
  bottom: 6%;
  left: 2%;
  z-index: -1;
}

.slider-5__sub-img-2 {
  position: absolute;
  top: 5%;
  right: 2%;
  z-index: -1;
}

.slider-5__sub-img-3 {
  position: absolute;
  top: -10%;
  right: 36%;
  z-index: -1;
}

.brand__mlr {
  margin: 0 200px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .brand__mlr {
    margin: 0 150px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .brand__mlr {
    margin: 0 30px;
  }
}

@media (max-width: 767px) {
  .brand__mlr {
    margin: 0;
  }
}

.brand__white-bg {
  background-color: var(--it-common-white);
  padding: 70px 130px;
  box-shadow: 3px 5.196px 51px 0 rgba(255, 114, 94, 0.08);
  margin-top: -100px;
  position: relative;
  z-index: 2;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .brand__white-bg {
    padding: 70px 90px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .brand__white-bg {
    padding: 70px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .brand__white-bg {
    padding: 70px 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .brand__white-bg {
    padding: 70px 30px;
  }
}

@media (max-width: 767px) {
  .brand__white-bg {
    padding: 50px 15px;
  }
}

.brand__white-bg-2 {
  background-color: var(--it-common-white);
  padding: 70px 130px;
  box-shadow: 3px 5.196px 51px 0 rgba(255, 114, 94, 0.08);
  position: relative;
  z-index: 2;
  margin-bottom: -120px;
  border-radius: 10px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .brand__white-bg-2 {
    padding: 70px 90px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .brand__white-bg-2 {
    padding: 70px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .brand__white-bg-2 {
    padding: 70px 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .brand__white-bg-2 {
    padding: 70px 30px;
  }
}

@media (max-width: 767px) {
  .brand__white-bg-2 {
    padding: 50px 15px;
  }
}

.brand__white-bg-3 {
  background-color: var(--it-common-white);
  padding: 70px 130px;
  box-shadow: 3px 5.196px 51px 0 rgba(255, 114, 94, 0.08);
  position: relative;
  z-index: 2;
  border-radius: 10px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .brand__white-bg-3 {
    padding: 70px 90px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .brand__white-bg-3 {
    padding: 70px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .brand__white-bg-3 {
    padding: 70px 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .brand__white-bg-3 {
    padding: 70px 30px;
  }
}

@media (max-width: 767px) {
  .brand__white-bg-3 {
    padding: 50px 15px;
  }
}

.brand__img {
  text-align: center;
}

.brand-2__space {
  padding: 80px 0;
  border-bottom: 1px solid var(--it-border-1);
}

@media (max-width: 767px) {
  .brand-2__space {
    padding: 60px 0;
  }
}

.brand-3__space {
  padding: 80px 0;
}

.brand-3__plr {
  padding: 0 200px;
  padding-bottom: 120px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .brand-3__plr {
    padding: 0 100px;
    padding-bottom: 120px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .brand-3__plr {
    padding: 0 100px;
    padding-bottom: 120px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .brand-3__plr {
    padding: 0 30px;
    padding-bottom: 120px;
  }
}

@media (max-width: 767px) {
  .brand-3__plr {
    padding: 0;
    padding-bottom: 120px;
  }
}

.brand-3__img-color {
  background-color: #1d1d25;
  padding: 30px 45px;
  border-radius: 15px;
}

.brand-3__img-color img {
  height: 40px;
}

.feature__mlr {
  margin: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .feature__mlr {
    margin: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .feature__mlr {
    margin: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .feature__mlr {
    margin: 0 30px;
  }
}

@media (max-width: 767px) {
  .feature__mlr {
    margin: 0;
  }
}

.feature__item {
  padding: 30px 30px;
  box-shadow: 0 0 92px 0 rgba(255, 114, 94, 0.05);
  position: relative;
}

.feature__item.item-space {
  padding: 50px 30px;
  position: relative;
  transition: 0.3s;
  border: 2px dashed transparent;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .feature__item.item-space {
    padding: 50px 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .feature__item.item-space {
    padding: 50px 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature__item.item-space {
    padding: 50px 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .feature__item.item-space {
    margin-bottom: 90px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .feature__item.item-space {
    margin-bottom: 90px;
  }
}

.feature__item.item-space:hover {
  border: 2px dashed var(--it-theme-1);
}

.feature__item.item-space:hover::after {
  background-color: var(--it-theme-1);
}

.feature__item.item-space:hover .feature__icon span {
  border: 2px dashed var(--it-theme-1);
}

.feature__item.item-space:hover .feature__title.title-border::after {
  width: 100%;
  opacity: 1;
  visibility: visible;
}

.feature__item.item-space::after {
  content: "";
  height: 100px;
  width: 120px;
  background-color: var(--it-common-yellow-2);
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  clip-path: polygon(52% 100%, 0 0, 100% 0);
  transition: 0.3s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature__item {
    padding: 60px 28px;
  }
}

@media (max-width: 767px) {
  .feature__item {
    padding: 60px 18px;
  }
}

.feature__item::after {
  content: "";
  height: 160px;
  width: 165px;
  background-color: var(--it-common-sky);
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  clip-path: polygon(53% 100%, 0 0, 100% 0);
}

.feature__color-2::after {
  background-color: var(--it-theme-1);
}

.feature__color-3::after {
  background-color: var(--it-common-pink);
}

.feature__color-4::after {
  background-color: #e8f0fd;
}

.feature__icon {
  margin-bottom: 30px;
}

.feature__icon.icon-space {
  margin-top: -121px;
  margin-bottom: 0;
}

.feature__icon.icon-space span {
  border: 2px dashed transparent;
  transition: 0.3s;
}

.feature__icon span {
  height: 125px;
  width: 125px;
  background-color: var(--it-common-white);
  border-radius: 50%;
  line-height: 125px;
  display: inline-block;
  box-shadow: 3px 5.196px 51px 0 rgba(255, 114, 94, 0.08);
}

.feature__content p {
  padding-bottom: 10px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .feature__content p {
    font-size: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .feature__content p {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .feature__content p {
    font-size: 14px;
  }
}

.feature__title {
  font-weight: 700;
  font-size: 22px;
  padding-bottom: 10px;
}

@media (max-width: 767px) {
  .feature__title {
    font-size: 19px;
  }
}

.feature__title.title-border {
  position: relative;
  display: inline-block;
}

.feature__title.title-border::after {
  position: absolute;
  content: "";
  bottom: 8px;
  left: 0;
  background-color: var(--it-common-black);
  height: 1px;
  width: 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
}

.feature__link a {
  font-weight: 500;
  font-size: 18px;
  color: var(--it-common-black);
  transition: 0.3s;
}

@media (max-width: 767px) {
  .feature__link a {
    font-size: 15px;
  }
}

.feature__link a i {
  font-size: 15px;
  margin-left: 10px;
  font-weight: 500;
}

.feature__link a:hover {
  color: var(--it-common-paste);
}

.feature__link a:hover i {
  animation: tfLeftToRight 0.4s forwards;
}

.feature__text p {
  padding-right: 106px;
}

.feature__item-shape {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -2px;
}

.feature__top-text p {
  padding-right: 106px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .feature__top-text p {
    padding-right: 85px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .feature__top-text p {
    padding-right: 7px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .feature__top-text p {
    padding-right: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .feature__top-text p {
    padding-right: 0;
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .feature__top-text p {
    padding-right: 0;
    font-size: 14px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .feature__top-text p {
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .feature__section-box {
    padding-bottom: 20px;
  }
}

.feature-2__mlr {
  margin: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .feature-2__mlr {
    margin: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .feature-2__mlr {
    margin: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-2__mlr {
    margin: 0 30px;
  }
}

@media (max-width: 767px) {
  .feature-2__mlr {
    margin: 0;
  }
}

.feature-2__item {
  box-shadow: 0 0 57px 0 rgba(0, 0, 0, 0.05);
  padding: 40px 15px;
  border-radius: 5px;
  position: relative;
  transition: 0.5s;
  background-color: var(--it-common-white);
}

.feature-2__item:hover {
  transform: translateY(-25px);
}

.feature-2__item:hover .feature-2__item-overly {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.feature-2__item:hover .feature-2__title-sm {
  color: var(--it-common-white);
}

.feature-2__item-overly {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #2b2b32;
  border-radius: 5px;
  transform: scale(0.6);
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
}

.feature-2__icon span {
  background-color: #88e3f1;
  height: 105px;
  width: 105px;
  line-height: 105px;
  border-radius: 50%;
  box-shadow: 3px 5.196px 43px 0 rgba(32, 32, 32, 0.07);
  margin-bottom: 25px;
  display: inline-block;
  text-align: center;
}

.feature-2__icon-color-2 span {
  background-color: var(--it-common-pink-5);
}

.feature-2__icon-color-3 span {
  background-color: var(--it-theme-1);
}

.feature-2__icon-color-4 span {
  background-color: #a9c7ff;
}

.feature-2__icon-color-5 span {
  background-color: #6bf89c;
}

.feature-2__title-sm {
  font-size: 22px;
  font-weight: 700;
  transition: 0.3s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature-2__title-sm {
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-2__title-sm {
    font-size: 19px;
  }
}

@media (max-width: 767px) {
  .feature-2__title-sm {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .feature-2__title-sm {
    font-size: 20px;
  }
}

.about__shape-1 {
  position: absolute;
  right: 8%;
  top: -17%;
  animation: rotate2 15s linear infinite;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__shape-1 {
    right: 5%;
    top: -13%;
  }
}

@media (max-width: 767px) {
  .about__shape-1 {
    right: 2%;
    top: -11%;
    z-index: -1;
  }
}

.about__shape-2 {
  position: absolute;
  right: 5%;
  bottom: 15%;
  z-index: -2;
  animation: moving 9s linear infinite;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about__shape-2 {
    bottom: 2%;
  }
}

.about__content p {
  padding-bottom: 10px;
}

@media (max-width: 767px) {
  .about__content p {
    font-size: 15px;
  }
}

.about__border-right {
  border-right: 1px solid var(--it-border-1);
}

@media (max-width: 767px) {
  .about__border-right {
    border-right: 0;
  }
}

.about__list-item {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--it-border-1);
}

.about__list-item ul {
  display: inline-block;
}

@media (max-width: 767px) {
  .about__list-item ul {
    margin-bottom: 20px;
  }
}

.about__list-item ul li {
  list-style-type: none;
  position: relative;
  padding-left: 25px;
  padding-bottom: 20px;
  width: 50%;
  float: left;
}

@media (max-width: 767px) {
  .about__list-item ul li {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about__list-item ul li {
    width: 50%;
  }
}

.about__list-item ul li:hover span {
  text-decoration: underline;
}

.about__list-item ul li a {
  transition: 0.3s;
}

.about__list-item ul li:last-child {
  padding-bottom: 0;
}

.about__list-item ul li i {
  position: absolute;
  left: 0;
  top: 3px;
  height: 15px;
  width: 15px;
  border: 1.5px solid var(--it-theme-1);
  line-height: 12px;
  text-align: center;
  display: inline-block;
  font-size: 12px;
  color: var(--it-theme-1);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__img {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .about__img {
    text-align: center;
  }
}

.about__img img {
  max-width: inherit;
  margin-left: -80px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about__img img {
    max-width: 100%;
    margin-left: -20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__img img {
    max-width: 100%;
    margin-left: -10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__img img {
    margin-bottom: 70px;
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .about__img img {
    max-width: 100%;
    margin-left: 0;
    margin-bottom: 50px;
  }
}

.about__contact-box {
  padding-top: 30px;
}

.about__contact-icon span {
  margin-right: 15px;
  width: 55px;
  height: 55px;
  background-color: var(--it-theme-1);
  border-radius: 50%;
  line-height: 64px;
  text-align: center;
  display: inline-block;
}

.about__contact-icon span i {
  font-size: 25px;
  color: var(--it-common-white);
}

.about__contact-number a {
  font-weight: 500;
  color: var(--it-common-black);
  font-size: 18px;
  display: block;
}

.about__contact-number a:hover {
  color: var(--it-common-paste);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__contact-number a {
    font-size: 16px;
  }
}

.about__video {
  padding-top: 35px;
}

.about__video-icon a {
  margin-right: 35px;
  width: 45px;
  height: 45px;
  background-color: var(--it-theme-1);
  border-radius: 50%;
  line-height: 46px;
  text-align: center;
  display: inline-block;
  position: relative;
  z-index: 1;
}

.about__video-icon a::after {
  content: "";
  left: 0;
  top: 0;
  position: absolute;
  background-color: rgba(255, 221, 96, 0.339);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform: scale(1.3);
  z-index: -1;
}

.about__video-icon a i {
  font-size: 15px;
  color: var(--it-common-white);
  margin-left: 4px;
}

.about__video-text a {
  font-weight: 500;
  font-size: 18px;
  color: var(--it-common-black);
}

.about__video-text a:hover {
  color: var(--it-common-paste);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__video-text a {
    font-size: 16px;
  }
}

.about-2__shape-1 {
  position: absolute;
  top: 11%;
  right: 12%;
  animation: rotate2 15s linear infinite;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px),
only screen and (min-width: 1400px) and (max-width: 1599px) {
  .about-2__shape-1 {
    right: 3%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about-2__shape-1 {
    top: 4%;
    right: 1%;
  }
}

.about-2__shape-2 {
  position: absolute;
  top: 67%;
  left: 12%;
  animation: jumpTwo 4s infinite linear;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .about-2__shape-2 {
    left: 5%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .about-2__shape-2 {
    left: 2%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about-2__shape-2 {
    top: 66%;
    left: 1%;
  }
}

.about-2__shape-3 {
  position: absolute;
  bottom: 19%;
  right: 5%;
  animation: moving 9s linear infinite;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .about-2__shape-3 {
    right: 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about-2__shape-3 {
    right: 1%;
    bottom: 2%;
  }
}

.about-2__section-title {
  padding-bottom: 5px;
}

.about-2__main-img img {
  max-width: inherit;
  transform: translateX(-102px);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about-2__main-img img {
    max-width: 100%;
    transform: translateX(-15px);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-2__main-img img {
    max-width: 100%;
    transform: translateX(-18px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .about-2__main-img img {
    max-width: 100%;
    transform: translateX(0);
    margin-bottom: 70px;
  }
}

.about-2__main-img-content {
  background-color: var(--it-theme-1);
  border-radius: 50px;
  position: absolute;
  top: 7%;
  right: 2%;
  padding: 12px 20px;
  padding-right: 40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about-2__main-img-content {
    top: 4%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-2__main-img-content {
    top: 4%;
    right: 2%;
    padding-right: 20px;
    padding: 2px 12px;
  }
}

@media (max-width: 767px) {
  .about-2__main-img-content {
    top: 68%;
    right: 18%;
    padding: 2px 10px;
    padding-right: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-2__main-img-content {
    top: 7%;
    right: -2%;
    padding: 10px 15px;
    padding-right: 30px;
  }
}

.about-2__icon span {
  font-weight: 500;
  color: var(--it-common-black);
  font-size: 42px;
  padding-right: 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-2__icon span {
    padding-right: 10px;
  }
}

@media (max-width: 767px) {
  .about-2__icon span {
    font-size: 34px;
    padding-right: 5px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-2__icon span {
    font-size: 37px;
    padding-right: 15px;
  }
}

.about-2__img-text h5 {
  font-weight: 700;
  font-size: 27px;
  margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-2__img-text h5 {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .about-2__img-text h5 {
    font-size: 18px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-2__img-text h5 {
    font-size: 22px;
  }
}

.about-2__img-text span {
  font-weight: 500;
  color: #3f3f3f;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-2__img-text span {
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  .about-2__img-text span {
    font-size: 12px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-2__img-text span {
    font-size: 14px;
  }
}

.about-2__text {
  padding-bottom: 10px;
}

.about-2__text p {
  padding-right: 115px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .about-2__text p {
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .about-2__text p {
    padding-right: 0;
    font-size: 15px;
  }
}

.about-2__list-title {
  font-weight: 500;
  font-size: 20px;
  font-family: rubik, sans-serif;
  background-color: var(--it-theme-1);
  padding: 11px 23px;
  display: inline-block;
  margin-bottom: 0;
}

.about-2__list-item {
  margin-right: 100px;
  padding-bottom: 44px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .about-2__list-item {
    margin-right: 0;
  }
}

.about-2__list-item ul {
  padding: 30px;
  background-color: #f3f3f3;
  display: inline-block;
}

.about-2__list-item ul li {
  width: 50%;
  float: left;
  list-style: none;
  padding-bottom: 15px;
}

@media (max-width: 767px) {
  .about-2__list-item ul li {
    width: 100%;
  }
}

.about-2__list-item ul li:nth-child(2n + 1) {
  padding-bottom: 0;
}

@media (max-width: 767px) {
  .about-2__list-item ul li:nth-child(2n + 1) {
    padding-bottom: 15px;
  }
}

.about-2__list-item ul li:last-child {
  padding-bottom: 0;
}

.about-2__list-item ul li span {
  font-weight: 400;
  font-size: 17px;
}

.about-2__list-item ul li span::before {
  content: "";
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: var(--it-theme-1);
  border: 1px solid var(--it-common-black);
  margin-right: 7px;
}

.about-3__plr {
  padding: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px),
only screen and (min-width: 1400px) and (max-width: 1599px) {
  .about-3__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .about-3__plr {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .about-3__plr {
    padding: 0;
  }
}

.about-3__space {
  padding-bottom: 50px;
}

@media (max-width: 767px) {
  .about-3__space {
    padding-bottom: 0;
  }
}

.about-3__content {
  padding-right: 206px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .about-3__content {
    padding-right: 79px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .about-3__content {
    padding-right: 57px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about-3__content {
    padding-right: 29px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-3__content {
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .about-3__content {
    padding-right: 0;
  }
}

.about-3__content-list {
  padding-bottom: 5px;
  border-bottom: 1px solid var(--it-border-1);
}

.about-3__content-list ul {
  display: inline-block;
  padding-bottom: 5px;
}

.about-3__content-list ul li {
  list-style-type: none;
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
  width: 50%;
  float: left;
}

@media (max-width: 767px) {
  .about-3__content-list ul li {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-3__content-list ul li {
    width: 50%;
  }
}

.about-3__content-list ul li i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  font-weight: 500;
  color: var(--it-common-blue-3);
  font-size: 16px;
}

.about-3__content-list ul li span {
  font-weight: 400;
  font-size: 17px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
(max-width: 767px) {
  .about-3__content-list ul li span {
    font-size: 15px;
  }
}

.about-3__video {
  padding-right: 30px;
}

.about-3__video a {
  height: 52px;
  width: 52px;
  border-radius: 50%;
  background-color: var(--it-theme-1);
  line-height: 52px;
  display: inline-block;
  text-align: center;
}

.about-3__video a i {
  font-size: 16px;
  color: var(--it-common-white);
  padding-left: 4px;
}

.about-3__video-text span {
  font-size: 18px;
  font-weight: 500;
  color: var(--it-common-black);
  text-decoration: underline;
  transition: 0.3s;
}

.about-3__video-text span:hover {
  color: var(--it-common-blue-3);
}

.about-3__button-box {
  padding-top: 30px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .about-3__button-box {
    padding-top: 0;
  }
}

@media (max-width: 767px) {
  .about-3__button-box {
    flex-wrap: wrap;
  }
}

.about-3__item img {
  max-width: inherit;
  transform: translateX(20px);
  animation: tpupdown 0.8s infinite alternate;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .about-3__item img {
    max-width: 100%;
    transform: translateX(-30px);
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .about-3__item img {
    max-width: 100%;
    transform: translateX(-30px);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about-3__item img {
    max-width: 100%;
    transform: translateX(-40px);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-3__item img {
    max-width: 100%;
    transform: translateX(-30px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-3__item img {
    max-width: 100%;
    transform: translateX(-2px);
  }
}

@media (max-width: 767px) {
  .about-3__item img {
    max-width: 100%;
    transform: translateX(-2px);
  }
}

.about-3__text p {
  padding-right: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
(max-width: 767px) {
  .about-3__text p {
    padding-right: 0;
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .about-3__button {
    margin-bottom: 30px;
  }
}

.about-3__content-list-2 {
  border: none;
}

.about-3__content-list-2 ul li {
  width: 100%;
  transform: 0.3s;
  text-decoration: transparent;
}

.about-3__content-list-2 ul li:hover {
  text-decoration: underline;
}

.about-3__plr-2 {
  padding-top: 120px;
}

.about-3__item-img img {
  max-width: 100%;
}

.about-3__button-wrapper {
  position: absolute;
  left: 4%;
  bottom: 6%;
  background-color: var(--it-common-white);
  padding: 20px 41px;
  border-radius: 20px;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .about-3__button-wrapper {
    padding: 15px;
    left: 4%;
    bottom: 7%;
    background-color: var(--it-common-white);
    padding: 6px 12px;
  }
}

@media (max-width: 767px) {
  .about-3__button-wrapper .about-3__video {
    padding-right: 10px;
  }
}

@media (max-width: 767px) {
  .about-3__button-wrapper .about-3__video a {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    background-color: var(--it-theme-1);
    line-height: 35px;
  }
}

@media (max-width: 767px) {
  .about-3__button-wrapper .about-3__video a i {
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  .about-3__button-wrapper .about-3__video-text span {
    font-size: 16px;
  }
}

.about-3__wrapper-item {
  border-radius: 20px;
  border: 1px solid var(--it-common-white-2);
  padding: 65px 80px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .about-3__wrapper-item {
    padding: 61px 60px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .about-3__wrapper-item {
    padding: 25px 30px;
  }

  .about-3__wrapper-item .section-title br {
    display: none;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about-3__wrapper-item {
    padding: 33px 21px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-3__wrapper-item {
    padding: 15px 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-3__wrapper-item {
    padding: 33px 40px;
  }
}

@media (max-width: 767px) {
  .about-3__wrapper-item {
    padding: 30px 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-3__wrapper-item {
    padding: 35px 20px;
  }
}

.about-3__wrapper-item .about-3__content {
  padding-right: 50px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .about-3__wrapper-item .about-3__content {
    padding: 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about-3__wrapper-item .about-3__content {
    padding: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-3__wrapper-item .about-3__content {
    padding: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-3__wrapper-item .about-3__content {
    padding: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .about-3__wrapper-item .about-3__content {
    padding: 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about-3__wrapper-item .section-title {
    font-size: 34px;
  }

  .about-3__wrapper-item .section-title br {
    display: none;
  }
}

.about-3__wrapper-item .about-3__button {
  padding-top: 30px;
  margin-bottom: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-3__wrapper-item .about-3__button {
    padding-top: 0;
  }
}

@media (max-width: 767px) {
  .about-3__wrapper-item .about-3__button {
    padding-top: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-3__wrapper-item .main-btn-sm {
    height: 35px;
    line-height: 35px;
    padding: 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-3__wrapper-item__text p {
    padding-right: 0;
  }
}

.about-4__plr {
  padding: 0 200px;
  padding-top: 120px;
  padding-bottom: 120px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .about-4__plr {
    padding: 0 100px;
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .about-4__plr {
    padding: 0 100px;
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about-4__plr {
    padding: 0 30px;
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-4__plr {
    padding: 0 30px;
    padding-top: 0;
    padding-bottom: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-4__plr {
    padding: 0 30px;
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

@media (max-width: 767px) {
  .about-4__plr {
    padding: 0;
    padding-top: 0;
    padding-bottom: 120px;
  }
}

.about-4__bg {
  background-color: #31303f;
  border-radius: 10px;
  padding: 90px 100px;
  position: relative;
  z-index: 2;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .about-4__bg {
    padding: 90px 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-4__bg {
    padding: 60px 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-4__bg {
    padding: 90px 20px;
  }
}

@media (max-width: 767px) {
  .about-4__bg {
    padding: 90px 0;
  }
}

.about-4__main-img {
  animation: tptranslateY2 4s infinite alternate;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-4__main-img {
    margin-bottom: 100px;
  }
}

@media (max-width: 767px) {
  .about-4__main-img {
    margin-bottom: 70px;
  }
}

.about-4__main-img img {
  margin-top: -211px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-4__main-img img {
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  .about-4__main-img img {
    margin-top: 0;
    width: 100%;
  }
}

.about-4__text {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--it-border-2);
}

.about-4__text p {
  color: var(--it-common-white-2);
}

@media (max-width: 767px) {
  .about-4__text p {
    font-size: 15px;
  }
}

.about-4__content-list {
  padding-bottom: 5px;
  margin-top: 35px;
  margin-bottom: 30px;
}

.about-4__content-list ul {
  padding-bottom: 5px;
}

.about-4__content-list ul li {
  list-style-type: none;
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
}

.about-4__content-list ul li i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  font-weight: 500;
  color: #83adff;
  font-size: 16px;
}

.about-4__content-list ul li span {
  color: var(--it-common-white-2);
  font-weight: 400;
  font-size: 17px;
  text-decoration: transparent;
  transition: 0.3s;
}

.about-4__content-list ul li span:hover {
  text-decoration: underline;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
(max-width: 767px) {
  .about-4__content-list ul li span {
    font-size: 15px;
  }
}

.about-4__video {
  padding-right: 40px;
}

.about-4__video span {
  height: 47px;
  width: 47px;
  border-radius: 50%;
  background-color: var(--it-theme-1);
  line-height: 49px;
  display: inline-block;
  text-align: center;
  position: relative;
  z-index: 2;
}

.about-4__video span::after {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: rgba(243, 215, 111, 0.588);
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  z-index: -1;
  transform: scale(1.3);
}

.about-4__video span i {
  font-size: 16px;
  color: var(--it-common-black);
  padding-left: 4px;
}

.about-4__video-text span {
  font-size: 18px;
  font-weight: 500;
  color: var(--it-common-white);
  text-decoration: underline;
  transition: 0.3s;
}

.about-4__video-text span:hover {
  color: var(--it-theme-1);
}

.about-4__shape-1 {
  position: absolute;
  top: 18%;
  left: 12%;
  z-index: 3;
  animation: tptranslateX2 4s infinite alternate;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .about-4__shape-1 {
    top: 17%;
    left: 8%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .about-4__shape-1 {
    top: 17%;
    left: 9%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about-4__shape-1 {
    top: 16%;
    left: 5%;
  }
}

.about-4__shape-2 {
  position: absolute;
  top: 27%;
  left: 40%;
  z-index: 3;
  animation: zoom 7s infinite;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .about-4__shape-2 {
    top: 29%;
    left: 37%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .about-4__shape-2 {
    top: 32%;
    left: 39%;
  }
}

.about-4__shape-3 {
  position: absolute;
  bottom: 19%;
  left: 41%;
  z-index: 3;
  animation: tpupdown 0.8s infinite alternate;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .about-4__shape-3 {
    bottom: 15%;
  }
}

.about-4__shape-4 {
  position: absolute;
  bottom: 8%;
  left: 7%;
  z-index: 1;
  animation: rotate2 15s linear infinite;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .about-4__shape-4 {
    left: 2%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .about-4__shape-4 {
    left: 2%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about-4__shape-4 {
    bottom: 4%;
    left: 0%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-4__shape-4 {
    bottom: 3%;
    left: 1%;
  }
}

.about-4__shape-5 {
  animation: moving 9s linear infinite;
  position: absolute;
  top: 1%;
  right: 2%;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .about-4__shape-5 {
    top: -5%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .about-4__shape-5 {
    top: -6%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about-4__shape-5 {
    top: -5%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-4__shape-5 {
    top: -7%;
    right: 1%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about-4__section-title .section-title br {
    display: none;
  }
}

.about-5__plr {
  padding: 0 200px;
  padding-top: 120px;
  padding-bottom: 120px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .about-5__plr {
    padding: 0 100px;
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .about-5__plr {
    padding: 0 30px;
    padding-top: 120px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about-5__plr {
    padding: 0 30px;
    padding-top: 0;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about-51__plr {
    padding: 0 30px;
    padding-top: 120px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-5__plr {
    padding: 0 30px;
    padding-top: 0;
   
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-5__plr {
    padding: 0 30px;
    padding-top: 120px;
  }
}

@media (max-width: 767px) {
  .about-5__plr {
    padding: 0;
    padding-top: 0;
  }
}

.about-5__text {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--it-border-1);
}

@media (max-width: 767px) {
  .about-5__text p {
    font-size: 13px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-5__text p {
    font-size: 15px;
  }
}

.about-5__content-list {
  padding-bottom: 40px;
  padding-top: 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-5__content-list {
    padding-bottom: 0;
  }
}

@media (max-width: 767px) {
  .about-5__content-list {
    padding-bottom: 10px;
  }
}

.about-5__content-list ul {
  display: inline-block;
  padding-bottom: 5px;
}

.about-5__content-list ul li {
  list-style-type: none;
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
  width: 50%;
  float: left;
}

@media (max-width: 767px) {
  .about-5__content-list ul li {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-5__content-list ul li {
    width: 50%;
  }
}

.about-5__content-list ul li i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  font-weight: 500;
  color: var(--it-common-blue-3);
  font-size: 16px;
}

.about-5__content-list ul li span {
  font-weight: 400;
  font-size: 17px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
(max-width: 767px) {
  .about-5__content-list ul li span {
    font-size: 15px;
  }
}

.about-5__content-title {
  font-size: 24px;
  padding-bottom: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-5__content-title {
    padding-bottom: 0;
  }
}

@media (max-width: 767px) {
  .about-5__content-title {
    font-size: 18px;
    padding-bottom: 0;
  }
}

.about-5__border {
  border: 1px solid var(--it-border-1);
  border-radius: 10px;
  padding: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-5__border {
    padding: 43px 40px;
    margin: 0 42px;
  }
}

@media (max-width: 767px) {
  .about-5__border {
    padding: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-5__border {
    padding: 30px 15px;
  }
}

.about-5__content-box {
  margin-right: 302px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .about-5__content-box {
    margin-right: 203px;
    margin-left: 0;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .about-5__content-box {
    margin-right: 56px;
    margin-left: 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about-5__content-box {
    margin-right: 55px;
    margin-left: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-5__content-box {
    margin-right: 0;
    margin-left: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-5__content-box {
    margin-right: 0;
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .about-5__content-box {
    margin-right: 0;
    margin-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-5__main-img {
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .about-5__main-img {
    margin-bottom: 30px;
  }
}

.about-details__plr {
  padding: 0 200px;
  border-bottom: 1px solid var(--it-border-1);
}

@media only screen and (min-width: 1600px) and (max-width: 1800px),
only screen and (min-width: 1400px) and (max-width: 1599px) {
  .about-details__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .about-details__plr {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .about-details__plr {
    padding: 0;
  }
}

.about-details__content-list {
  padding: 10px 0;
}

.about-details__content-list ul {
  padding-bottom: 5px;
}

.about-details__content-list ul li {
  list-style-type: none;
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
  text-decoration: transparent;
  transition: 0.3s;
}

@media (max-width: 767px) {
  .about-details__content-list ul li {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-details__content-list ul li {
    width: 50%;
  }
}

.about-details__content-list ul li:hover {
  text-decoration: underline;
}

.about-details__content-list ul li i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  font-weight: 500;
  color: var(--it-common-blue-3);
  font-size: 16px;
}

.about-details__content-list ul li span {
  font-weight: 400;
  font-size: 17px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-details__content-list ul li span {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .about-details__content-list ul li span {
    font-size: 14px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-details__content-list ul li span {
    font-size: 16px;
  }
}

.about-details__title {
  font-size: 24px;
  padding-bottom: 20px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px),
only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about-details__title {
    padding-bottom: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-details__title {
    padding-bottom: 0;
  }
}

@media (max-width: 767px) {
  .about-details__title {
    font-size: 18px;
    padding-bottom: 0;
  }
}

.about-details__content {
  padding-left: 25px;
  padding-right: 190px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .about-details__content {
    padding-right: 90px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .about-details__content {
    padding-right: 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about-details__content {
    padding-right: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-details__content {
    padding-left: 10px;
    padding-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-details__content {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .about-details__content {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .about-details__text p {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .about-details__text p {
    font-size: 13px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-details__text p {
    font-size: 15px;
  }
}

.about-details__text-2 {
  font-size: 17px;
  padding-bottom: 75px;
}

@media (max-width: 767px) {
  .about-details__text-2 {
    padding-bottom: 40px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px),
only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about-details__text-2 p br {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .about-details__text-2 p {
    font-size: 15px;
  }

  .about-details__text-2 p br {
    display: none;
  }
}

@media (max-width: 767px) {
  .about-details__text-2 p {
    font-size: 13px;
  }

  .about-details__text-2 p br {
    display: none;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-details__text-2 p {
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-details__main-img {
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .about-details__main-img {
    margin-bottom: 30px;
  }
}

.counter__shape-1 {
  position: absolute;
  bottom: 0;
  left: 0;
}

.counter__shape-2 {
  position: absolute;
  bottom: 0;
  right: 0;
}

.counter__black-bg {
  background-color: var(--it-common-black);
  padding: 100px 125px;
  border-radius: 15px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .counter__black-bg {
    padding: 100px 50px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .counter__black-bg {
    padding: 100px 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .counter__black-bg {
    padding: 100px 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .counter__black-bg {
    padding: 100px 30px;
  }
}

@media (max-width: 767px) {
  .counter__black-bg {
    padding: 100px 10px;
  }
}

.counter__mlr {
  margin: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .counter__mlr {
    margin: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .counter__mlr {
    margin: 0 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .counter__mlr {
    margin: 0 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .counter__mlr {
    margin: 0 30px;
  }
}

@media (max-width: 767px) {
  .counter__mlr {
    margin: 0 15px;
  }
}

.counter__item {
  background-color: var(--it-common-blue);
  padding: 55px 0;
  border-radius: 5px;
  position: relative;
  z-index: 2;
}

.counter__color-2 {
  background-color: var(--it-theme-1);
}

.counter__color-3 {
  background-color: var(--it-common-paste);
}

.counter__color-4 {
  background-color: var(--it-common-pink-2);
}

.counter__title {
  font-weight: 700;
  font-size: 22px;
  color: var(--it-common-black);
  padding-bottom: 10px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .counter__title {
    font-size: 20px;
  }
}

.counter__icon {
  padding-bottom: 10px;
}

.counter__icon span {
  font-size: 60px;
  color: var(--it-common-black);
  display: inline-block;
}

.counter__text span {
  font-weight: 700;
  font-size: 50px;
  color: var(--it-common-black);
  line-height: 1;
}

.counter__text span i {
  font-style: normal;
}

.counter-2__plr {
  padding: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .counter-2__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .counter-2__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .counter-2__plr {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .counter-2__plr {
    padding: 0;
  }
}

.counter-2__black-bg {
  background-color: var(--it-common-black-7);
  border-radius: 15px;
  padding: 70px 25px;
  position: relative;
}

@media (max-width: 767px) {
  .counter-2__black-bg {
    padding-bottom: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .counter-2__black-bg {
    padding-bottom: 30px;
  }
}

.counter-2__shape {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.counter-2__item {
  position: relative;
  z-index: 2;
  border-right: 1px solid var(--it-border-2);
}

.counter-2__item.item-border {
  border-right: none;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px),
only screen and (min-width: 1400px) and (max-width: 1599px),
only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
  .counter-2__item {
    border-right: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .counter-2__item {
    border-right: none;
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .counter-2__item {
    border-right: none;
  }
}

.counter-2__text span {
  font-size: 50px;
  font-weight: 700;
  color: var(--it-common-white);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .counter-2__text span {
    font-size: 45px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .counter-2__text span {
    font-size: 37px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter-2__text span {
    font-size: 37px;
  }
}

@media (max-width: 767px) {
  .counter-2__text span {
    font-size: 35px;
  }
}

.counter-2__text span i {
  font-style: normal;
}

.counter-2__title {
  font-size: 22px;
  color: var(--it-common-white);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .counter-2__title {
    font-size: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .counter-2__title {
    font-size: 17px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter-2__title {
    font-size: 17px;
  }
}

@media (max-width: 767px) {
  .counter-2__title {
    font-size: 17px;
  }
}

.counter-2__icon img {
  width: 65px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .counter-2__icon img {
    width: 45px;
  }
}

@media (max-width: 767px) {
  .counter-2__icon img {
    width: 60px;
  }
}

.service__mlr {
  padding: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .service__mlr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .service__mlr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .service__mlr {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .service__mlr {
    padding: 0;
  }
}

.service__space {
  margin-top: -100px;
  padding-top: 200px;
}

.service__space-2 {
  padding-top: 120px;
  padding-bottom: 70px;
}

.service__shape-1 {
  position: absolute;
  right: 0;
  top: 20%;
}

.service__shape-2 {
  position: absolute;
  left: 0;
  top: 15%;
}

.service__shape-3 {
  position: absolute;
  right: 0;
  top: 10%;
  animation: tpupdown 0.8s infinite alternate;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__shape-3 {
    top: 1%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__shape-3 {
    top: 2%;
  }
}

.service__shape-4 {
  position: absolute;
  left: 0;
  bottom: 10%;
  animation: tpupdown 0.8s infinite alternate;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__shape-4 {
    bottom: 0;
  }
}

.service__shape-5 {
  position: absolute;
  left: 0;
  top: -9%;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .service__shape-5 {
    top: -8%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .service__shape-5 {
    top: -7%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__shape-5 {
    top: -6%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__shape-5 {
    top: -3.5%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__shape-5 {
    top: -3%;
  }
}

@media (max-width: 767px) {
  .service__shape-5 {
    top: -19px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .service__shape-5 {
    top: -37px;
  }
}

@media only screen and (min-width: 440px) and (max-width: 575px) {
  .service__shape-5 {
    top: -27px;
  }
}

.service__shape-6 {
  position: absolute;
  left: 0;
  bottom: -6%;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .service__shape-6 {
    bottom: -5%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .service__shape-6 {
    bottom: -5%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__shape-6 {
    bottom: -4%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__shape-6 {
    bottom: -2.5%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__shape-6 {
    bottom: -2%;
  }
}

@media (max-width: 767px) {
  .service__shape-6 {
    bottom: -16px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .service__shape-6 {
    bottom: -24px;
  }
}

@media only screen and (min-width: 440px) and (max-width: 575px) {
  .service__shape-6 {
    bottom: -20px;
  }
}

.service__item {
  background-color: var(--it-common-white);
  border: 2px dashed transparent;
  transition: 0.3s;
  border-top-right-radius: 20%;
  padding: 70px 55px;
  height: 100%;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__item {
    padding: 60px 46px;
    text-align: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__item {
    padding: 50px 35px;
  }
}

@media (max-width: 767px) {
  .service__item {
    padding: 40px 35px;
  }
}

.service__item.bg-color {
  background-color: var(--it-common-black-2);
  border: 2px solid var(--it-border-2);
}

.service__item.bg-color .service__title {
  color: var(--it-common-white);
}

.service__item.bg-color .service__title.title-color-1:hover {
  color: var(--it-common-paste);
}

.service__item.bg-color .service__title.title-color-2:hover {
  color: var(--it-theme-1);
}

.service__item.bg-color .service__title.title-color-3:hover {
  color: var(--it-common-pink-2);
}

.service__item.bg-color .service__title.title-color-4:hover {
  color: var(--it-common-blue);
}

.service__item.bg-color .service__title.title-color-5:hover {
  color: var(--it-common-green);
}

.service__item.bg-color .service__title.title-color-6:hover {
  color: var(--it-common-pink-3);
}

.service__item.bg-color .service__content p {
  color: var(--it-common-white);
}

.service__item:hover {
  border: 2px dashed var(--it-common-paste);
}

.service__item:hover .service__icon-wrapper {
  transform: translateY(-10px);
}

.service__border-2:hover {
  border-color: var(--it-theme-1);
}

.service__border-3:hover {
  border-color: var(--it-common-pink-2);
}

.service__border-4:hover {
  border-color: var(--it-common-blue);
}

.service__border-5:hover {
  border-color: var(--it-common-green);
}

.service__border-6:hover {
  border-color: var(--it-common-pink-3);
}

.service__shape {
  position: absolute;
  bottom: -30%;
  right: 8%;
}

.service__icon-wrapper {
  display: inline-block;
  transition: 0.3s;
}

.service__inner-icon {
  position: absolute;
  top: 41%;
  left: 55%;
  transform: translate(-50%, -50%);
}

.service__inner-icon.inner-hight {
  top: 41%;
  left: 52%;
}

.service__inner-icon span {
  font-size: 40px;
  color: var(--it-common-white);
  font-weight: 700;
}

.service__title {
  font-weight: 700;
  font-size: 22px;
  padding-bottom: 10px;
}

@media (max-width: 767px) {
  .service__title {
    font-size: 19px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .service__title {
    font-size: 21px;
  }
}

.service__title.title-color-1:hover {
  color: var(--it-common-paste);
}

.service__title.title-color-2:hover {
  color: var(--it-theme-1);
}

.service__title.title-color-3:hover {
  color: var(--it-common-pink-2);
}

.service__title.title-color-4:hover {
  color: var(--it-common-blue);
}

.service__title.title-color-5:hover {
  color: var(--it-common-green);
}

.service__title.title-color-6:hover {
  color: var(--it-common-pink-3);
}

.service__title.color-1 {
  color: var(--it-common-white);
}

.service__title.color-1:hover {
  color: var(--it-common-paste);
}

.service__title.color-2 {
  color: var(--it-common-white);
}

.service__title.color-2:hover {
  color: var(--it-theme-1);
}

.service__title.color-3 {
  color: var(--it-common-white);
}

.service__title.color-3:hover {
  color: var(--it-common-pink-2);
}

.service__title.color-4 {
  color: var(--it-common-white);
}

.service__title.color-4:hover {
  color: var(--it-common-blue);
}

.service__title.color-5 {
  color: var(--it-common-white);
}

.service__title.color-5:hover {
  color: var(--it-common-green);
}

.service__title.color-6 {
  color: var(--it-common-white);
}

.service__title.color-6:hover {
  color: var(--it-common-pink-3);
}

.service__title.color-7 {
  color: var(--it-common-white);
}

.service__title.color-7:hover {
  color: #3aa583;
}

.service__title.color-8 {
  color: var(--it-common-white);
}

.service__title.color-8:hover {
  color: #9e661e;
}

.service__title.color-9 {
  color: var(--it-common-white);
}

.service__title.color-9:hover {
  color: #7272b1;
}

.service__title.color-10 {
  color: var(--it-common-white);
}

.service__title.color-10:hover {
  color: #62591b;
}

.service__title.color-11 {
  color: var(--it-common-white);
}

.service__title.color-11:hover {
  color: #d04184;
}

.service__title.color-12 {
  color: var(--it-common-white);
}

.service__title.color-12:hover {
  color: #c6a477;
}

.service__content p {
  padding-bottom: 5px;
  transition: 0.3s;
}

@media (max-width: 767px) {
  .service__content p {
    font-size: 14px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .service__content p {
    font-size: 15px;
  }
}

.service__link a {
  font-weight: 500;
  font-size: 17px;
  color: var(--it-common-paste);
}

.service__link a i {
  margin-left: 10px;
  transform: translateY(2px);
}

.service__link a:hover i {
  animation: tfLeftToRight 0.4s forwards;
}

.service__link-color-2 a {
  color: var(--it-theme-1);
}

.service__link-color-3 a {
  color: var(--it-common-pink-2);
}

.service__link-color-4 a {
  color: var(--it-common-blue);
}

.service__link-color-5 a {
  color: var(--it-common-green);
}

.service__link-color-6 a {
  color: var(--it-common-pink-3);
}

.service__link-color-7 a {
  color: #3aa583;
}

.service__link-color-8 a {
  color: #9e661e;
}

.service__link-color-9 a {
  color: #7272b1;
}

.service__link-color-10 a {
  color: #62591b;
}

.service__link-color-11 a {
  color: #d04184;
}

.service__link-color-12 a {
  color: #c6a477;
}

.service__title-color-1 {
  color: var(--it-common-white);
}

.service__title-color-1:hover {
  color: var(--it-common-paste);
}

.service__title-color-2 {
  color: var(--it-common-white);
}

.service__title-color-2:hover {
  color: var(--it-theme-1);
}

.service__title-color-3 {
  color: var(--it-common-white);
}

.service__title-color-3:hover {
  color: var(--it-common-pink-2);
}

.service__title-color-4 {
  color: var(--it-common-white);
}

.service__title-color-4:hover {
  color: var(--it-common-blue);
}

.service__title-color-5 {
  color: var(--it-common-white);
}

.service__title-color-5:hover {
  color: var(--it-common-green);
}

.service__title-color-6 {
  color: var(--it-common-white);
}

.service__title-color-6:hover {
  color: var(--it-common-pink-3);
}

.service__item-bg {
  background-color: #31303f;
  border-radius: 40px;
  padding: 70px 65px;
  transition: 0.3s;
}

.service__item-bg.item-hover-black {
  border-radius: 30px;
  padding: 70px 65px;
  border: 1px solid var(--it-border-1);
  transition: 0.3s;
  background-color: var(--it-common-white);
  transition: 0.3s;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__item-bg {
    border-radius: 40px;
    padding: 50px 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__item-bg {
    padding: 70px 32px;
  }
}

@media (max-width: 767px) {
  .service__item-bg {
    padding: 50px 35px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .service__item-bg {
    padding: 50px;
  }
}

.service__item-bg:hover {
  border: 2px dashed transparent;
  background-color: #1d1d25;
}

.service__item-bg:hover.item-hover-black {
  background-color: var(--it-common-black);
  border: 1px solid transparent;
}

.service__item-bg:hover.item-hover-black .service__content p {
  color: var(--it-common-white);
}

.service__item-bg:hover.item-hover-black .service__title {
  color: var(--it-common-white);
}

.service__plr {
  padding: 0 200px;
  padding-bottom: 50px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .service__plr {
    padding: 0 100px;
    padding-bottom: 50px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .service__plr {
    padding: 0 100px;
    padding-bottom: 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .service__plr {
    padding: 0 30px;
    padding-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .service__plr {
    padding: 0;
    padding-bottom: 50px;
  }
}

.service__shape-1 {
  position: absolute;
  top: -24%;
  right: 14%;
}

.service-2__plr {
  padding: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .service-2__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .service-2__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .service-2__plr {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .service-2__plr {
    padding: 0;
  }
}

.service-2__space {
  padding-top: 110px;
  padding-bottom: 110px;
}

.service-2__shape-1 {
  position: absolute;
  left: 1.5%;
  top: 26%;
  animation: moving 9s linear infinite;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .service-2__shape-1 {
    left: 0%;
  }
}

.service-2__shape-2 {
  position: absolute;
  left: 2%;
  bottom: 14%;
  animation: jumpTwo 4s infinite linear;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .service-2__shape-2 {
    left: 0%;
    bottom: 5%;
  }
}

.service-2__shape-3 {
  position: absolute;
  right: 0%;
  top: 7%;
  animation: tpupdown 0.8s infinite alternate;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service-2__shape-3 {
    top: 1%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-2__shape-3 {
    top: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-2__shape-3 {
    right: 0;
    top: 0;
  }
}

.service-2__shape-4 {
  position: absolute;
  right: 7%;
  top: 31%;
  animation: jumpTwo 4s infinite linear;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .service-2__shape-4 {
    right: 3%;
  }
}

.service-2__shape-5 {
  position: absolute;
  right: 3%;
  bottom: 8%;
  animation: jumpTwo 4s infinite linear;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px),
only screen and (min-width: 1400px) and (max-width: 1599px) {
  .service-2__shape-5 {
    bottom: 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service-2__shape-5 {
    right: 2%;
    bottom: -1%;
  }
}

.service-2__text {
  padding-right: 106px;
}

.service-2__item-wrapper {
  background-color: var(--it-common-white);
  padding: 50px;
  border-radius: 10px;
  position: relative;
  margin-bottom: 30px;
  transition: 0.3s;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .service-2__item-wrapper {
    padding: 50px 45px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service-2__item-wrapper {
    padding: 50px 36px;
  }
}

@media (max-width: 767px) {
  .service-2__item-wrapper {
    padding: 25px 30px;
  }
}

.service-2__item-wrapper:hover {
  background-color: var(--it-theme-1);
}

.service-2__item-wrapper:hover::after {
  background-color: var(--it-common-blue-4);
}

.service-2__item-wrapper:hover .service-2__icon span i {
  color: var(--it-common-black);
}

.service-2__item-wrapper:hover .service-2__link a i {
  color: var(--it-common-blue-4);
}

.service-2__item-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--it-common-blue-5);
  height: 100px;
  width: 100px;
  border-top-left-radius: 10px;
  transition: 0.3s;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service-2__item-wrapper::after {
    height: 94px;
    width: 86px;
  }
}

@media (max-width: 767px) {
  .service-2__item-wrapper::after {
    height: 80px;
    width: 80px;
  }
}

.service-2__icon {
  margin-right: 30px;
}

@media (max-width: 767px) {
  .service-2__icon {
    margin-bottom: 20px;
  }
}

.service-2__icon span {
  height: 80px;
  width: 80px;
  background-color: var(--it-common-white);
  border-radius: 50%;
  line-height: 80px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
  display: inline-block;
  text-align: center;
}

.service-2__icon span.icon-height {
  line-height: 95px;
}

.service-2__icon span.icon-height-2 {
  line-height: 90px;
  padding-left: 6px;
}

.service-2__icon span.icon-height-3 {
  line-height: 92px;
}

.service-2__icon span.icon-height-4 {
  line-height: 90px;
}

.service-2__icon span i {
  font-size: 32px;
  color: var(--it-theme-1);
  font-weight: 500;
  transition: 0.3s;
}

.service-2__title-sm {
  font-size: 22px;
  font-weight: 700;
  color: var(--it-common-black);
  padding-bottom: 5px;
  transition: 0.3s;
}

.service-2__title-sm:hover {
  color: var(--it-common-blue-4);
}

@media (max-width: 767px) {
  .service-2__title-sm {
    font-size: 18px;
  }
}

.service-2__link a {
  font-weight: 500;
  font-size: 17px;
  color: var(--it-common-black);
  transition: 0.3s;
}

.service-2__link a:hover {
  color: var(--it-common-blue-4);
}

.service-2__link a:hover i {
  animation: tfLeftToRight 0.4s forwards;
}

.service-2__link a i {
  padding-left: 10px;
  color: var(--it-theme-1);
  transition: 0.3s;
}

.service-2__top-text p {
  padding-right: 106px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .service-2__top-text p {
    padding-right: 85px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .service-2__top-text p {
    padding-right: 7px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service-2__top-text p {
    padding-right: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .service-2__top-text p {
    padding-right: 0;
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .service-2__top-text p {
    padding-right: 0;
    font-size: 14px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .service-2__top-text p {
    padding-right: 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service-2__content p {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .service-2__content p {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .service-2__item {
    flex-wrap: wrap;
  }
}

.service-details__plr {
  padding: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .service-details__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .service-details__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .service-details__plr {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .service-details__plr {
    padding: 0;
  }
}

.service-details__service-item {
  background-color: #f7f9ff;
  padding: 47px 40px;
  margin-left: 20px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .service-details__service-item {
    padding: 15px 35px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service-details__service-item {
    padding: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-details__service-item {
    padding: 20px;
    margin-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-details__service-item {
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .service-details__service-item {
    padding: 30px 15px;
    margin-left: 0;
  }
}

.service-details__service-item h4 {
  font-weight: 700;
  font-size: 22px;
  padding-bottom: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-details__service-item h4 {
    padding-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .service-details__service-item h4 {
    font-size: 19px;
    padding-bottom: 15px;
  }
}

.service-details__service-item ul li {
  font-weight: 600;
  font-size: 18px;
  font-family: poppins, sans-serif;
  color: var(--it-common-black);
  background-color: var(--it-common-white);
  list-style-type: none;
  margin-bottom: 20px;
  padding: 8px 12px;
  border-radius: 30px;
  transition: 0.3s;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .service-details__service-item ul li {
    font-size: 17px;
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service-details__service-item ul li {
    font-size: 17px;
    padding: 3px 12px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-details__service-item ul li {
    margin-bottom: 10px;
    padding: 5px 12px;
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .service-details__service-item ul li {
    margin-bottom: 15px;
    padding: 8px 12px;
    font-size: 15px;
  }
}

.service-details__service-item ul li:last-child {
  margin-bottom: 0;
}

.service-details__service-item ul li:hover {
  background-color: var(--it-theme-1);
}

.service-details__service-item ul li::before {
  content: "\f00c";
  font-family: "font awesome 5 pro";
  background-color: var(--it-common-blue-4);
  display: inline-block;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  color: var(--it-common-white);
  line-height: 30px;
  font-size: 15px;
  text-align: center;
  margin-right: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-details__service-item ul li::before {
    height: 20px;
    width: 20px;
    line-height: 20px;
    font-size: 12px;
  }
}

.service-details__list {
  padding-bottom: 75px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-details__list {
    padding-bottom: 35px;
  }
}

@media (max-width: 767px) {
  .service-details__list {
    padding-bottom: 25px;
  }
}

.service-details__list ul {
  display: inline-block;
  padding-bottom: 5px;
}

.service-details__list ul li {
  list-style-type: none;
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
  width: 25%;
  float: left;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-details__list ul li {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .service-details__list ul li {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .service-details__list ul li {
    width: 50%;
  }
}

.service-details__list ul li i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  font-weight: 500;
  color: var(--it-common-blue-3);
  font-size: 16px;
}

.service-details__list ul li span {
  font-weight: 600;
  font-size: 18px;
  font-family: poppins, sans-serif;
  color: var(--it-common-black);
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
(max-width: 767px) {
  .service-details__list ul li span {
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-details__list ul li span {
    font-size: 15px;
  }
}

.service-details__item p {
  padding-right: 190px;
  padding-bottom: 20px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .service-details__item p {
    padding-right: 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .service-details__item p {
    padding-right: 0;
    font-size: 14px;
  }

  .service-details__item p br {
    display: none;
  }
}

@media (max-width: 767px) {
  .service-details__item p {
    padding-right: 0;
    font-size: 14px;
  }

  .service-details__item p br {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-details__main-img {
    padding-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .service-details__main-img {
    padding-bottom: 35px;
  }
}

.service-details__main-img-2 {
  padding-bottom: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-details__main-img-2 {
    padding-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .service-details__main-img-2 {
    padding-bottom: 35px;
  }
}

.service-details__section-box {
  padding-bottom: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-details__section-box {
    font-size: 15px;
    padding-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .service-details__section-box {
    font-size: 15px;
    padding-bottom: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-details__right-text p {
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .service-details__right-text p {
    padding-bottom: 20px;
    font-size: 14px;
  }
}

.project__active {
  margin: 0 15px;
}

@media (max-width: 767px) {
  .project__active {
    margin: 0;
  }
}

.project__item img {
  width: 100%;
}

.project-slider-dots {
  text-align: center;
  margin-top: 60px;
}

.project-slider-dots .swiper-pagination-bullet {
  width: 18px;
  height: 18px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid var(--it-theme-1);
  background-color: transparent;
  opacity: 1;
  margin: 0 5px;
  transition: 0.3s;
}

.project-slider-dots .swiper-pagination-bullet-active {
  background-color: var(--it-theme-1);
}

.project-2__plr {
  padding: 0 200px;
  padding-top: 150px;
  padding-bottom: 120px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .project-2__plr {
    padding: 0 100px;
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px),
only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
  .project-2__plr {
    padding: 0 30px;
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .project-2__plr {
    padding: 0;
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

.project-2__shape-1 {
  position: absolute;
  left: 0;
  top: 22%;
  animation: tpupdown 0.8s infinite alternate;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .project-2__shape-1 {
    top: 4%;
  }
}

.project-2__shape-2 {
  position: absolute;
  left: 0;
  bottom: 16%;
  animation: rotate2 15s linear infinite;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .project-2__shape-2 {
    left: -1%;
    bottom: 0%;
  }
}

.project-2__shape-3 {
  position: absolute;
  right: 0;
  top: 16%;
  animation: jumpTwo 4s infinite linear;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .project-2__shape-3 {
    right: 0%;
    top: 7%;
  }
}

.project-2__shape-4 {
  position: absolute;
  right: 0;
  bottom: 3%;
  animation: tpupdown 0.8s infinite alternate;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .project-2__shape-4 {
    right: -6%;
    bottom: -2%;
  }
}

.project-2__button-box {
  padding-bottom: 60px;
}

.project-2__button-box button {
  font-weight: 600;
  font-size: 18px;
  color: var(--it-common-black);
  margin: 0 5px;
  background-color: transparent;
  padding: 10px 20px;
  border-radius: 30px;
  padding: 6px 24px;
  font-family: poppins, sans-serif;
  transition: 0.3s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .project-2__button-box button {
    font-size: 17px;
    padding: 6px 15px;
  }
}

@media (max-width: 767px) {
  .project-2__button-box button {
    font-size: 13px;
    padding: 6px 17px;
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .project-2__button-box button {
    font-size: 17px;
  }
}

.project-2__button-box button.active {
  background-color: var(--it-theme-1);
}

.project-2__button-box button:hover {
  background-color: var(--it-theme-1);
}

.project-2__thumb {
  position: relative;
}

.project-2__thumb img {
  border-radius: 10px;
  width: 100%;
}

.project-2__thumb::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(41, 41, 48, 0.488);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  transform: scale(0.5);
  border-radius: 10px;
}

.project-2__item {
  margin-bottom: 30px;
}

.project-2__item:hover .project-2__thumb::after {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.project-2__item:hover .project-2__img-icon {
  opacity: 1;
  visibility: visible;
}

.project-2__item:hover .project-2__img-text {
  opacity: 1;
  visibility: visible;
}

.project-2__img-text {
  position: absolute;
  left: 0;
  bottom: 0;
  margin: 30px;
  z-index: 4;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .project-2__img-text {
    margin: 15px;
  }
}

.project-2__img-text h4 {
  color: var(--it-common-white);
  font-weight: 700;
  font-size: 22px;
  transition: 0.3s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .project-2__img-text h4 {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .project-2__img-text h4 {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .project-2__img-text h4 {
    font-size: 19px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .project-2__img-text h4 {
    font-size: 21px;
  }
}

.project-2__img-text h4:hover {
  color: var(--it-theme-1);
}

.project-2__img-text i {
  font-weight: 400;
  font-style: normal;
  color: var(--it-common-white);
  font-size: 16px;
}

.project-2__img-text i span {
  color: var(--it-theme-1);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .project-2__img-text i {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .project-2__img-text i {
    font-size: 12px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .project-2__img-text i {
    font-size: 15px;
  }
}

.project-2__img-icon {
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;
  z-index: 4;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.project-2__img-icon a {
  height: 55px;
  width: 55px;
  background-color: var(--it-theme-1);
  display: inline-block;
  line-height: 58px;
  text-align: center;
  border-radius: 5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .project-2__img-icon a {
    height: 40px;
    width: 40px;
    line-height: 46px;
  }
}

.project-2__img-icon a i {
  font-weight: 500;
  font-size: 20px;
  color: var(--it-common-black);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .project-2__img-icon a i {
    font-size: 18px;
  }
}

.project-2__button-box-2 button {
  color: var(--it-common-white);
  transition: 0.3s;
}

.project-2__button-box-2 button.active {
  color: var(--it-common-black);
}

.project-2__button-box-2 button:hover {
  color: var(--it-common-black);
}

.project-2__plr-2 {
  padding: 0 200px;
  padding-top: 150px;
  padding-bottom: 120px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .project-2__plr-2 {
    padding: 0 100px;
    padding-top: 150px;
    padding-bottom: 120px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .project-2__plr-2 {
    padding: 0 100px;
    padding-top: 150px;
    padding-bottom: 120px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .project-2__plr-2 {
    padding: 0 30px;
    padding-top: 150px;
    padding-bottom: 120px;
  }
}

@media (max-width: 767px) {
  .project-2__plr-2 {
    padding: 0;
    padding-top: 150px;
    padding-bottom: 120px;
  }
}

.project-2__plr-3 {
  padding: 0 200px;
  padding-top: 100px;
  padding-bottom: 100px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .project-2__plr-3 {
    padding: 0 100px;
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .project-2__plr-3 {
    padding: 0 100px;
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .project-2__plr-3 {
    padding: 0 30px;
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media (max-width: 767px) {
  .project-2__plr-3 {
    padding: 0;
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.project-3__plr {
  padding: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .project-3__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .project-3__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .project-3__plr {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .project-3__plr {
    padding: 0;
  }
}

.project-3__space {
  padding-top: 120px;
  padding-bottom: 100px;
}

.project-3__text {
  padding-right: 60px;
  padding-bottom: 10px;
}

.project-3__project-tab {
  margin-bottom: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .project-3__project-tab {
    margin-bottom: 30px;
  }
}

.project-3__project-tab ul li button {
  font-size: 18px;
  font-weight: 600;
  font-family: poppins, sans-serif;
  color: var(--it-common-black);
  background-color: transparent;
  padding: 5px 24px;
  border-radius: 30px;
  margin-right: 10px;
  transition: 0.3s;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .project-3__project-tab ul li button {
    padding: 5px 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .project-3__project-tab ul li button {
    padding: 5px 14px;
    margin-right: 5px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .project-3__project-tab ul li button {
    padding: 3px 10px;
    margin-right: 5px;
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .project-3__project-tab ul li button {
    margin-bottom: 15px;
    font-size: 15px;
    padding: 7px 18px;
    margin-right: 6px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .project-3__project-tab ul li button {
    font-size: 16px;
  }
}

.project-3__project-tab ul li button:hover {
  background-color: var(--it-theme-1);
  color: var(--it-common-black);
}

.project-3__project-tab ul li button.active {
  background-color: var(--it-theme-1);
  color: var(--it-common-black);
}

.project-3__item:hover .project-3__icon a i {
  color: var(--it-common-blue-3);
}

.project-3__item:hover .project-3__icon a::before {
  background-color: var(--it-common-blue);
}

.project-3__main-img img {
  width: 100%;
  border-radius: 10px;
}

.project-3__title-sm {
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 0;
  padding-bottom: 6px;
  transition: 0.3s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .project-3__title-sm {
    font-size: 17px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .project-3__title-sm {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .project-3__title-sm {
    font-size: 14px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .project-3__title-sm {
    font-size: 18px;
  }
}

.project-3__title-sm:hover {
  color: var(--it-common-blue-4);
}

.project-3__content-text span {
  font-size: 14px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .project-3__content-text span {
    font-size: 13px;
  }
}

.project-3__content {
  padding: 30px 25px;
}

.project-3__icon {
  padding-right: 45px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .project-3__icon {
    padding-right: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .project-3__icon {
    padding-right: 15px;
  }
}

.project-3__icon a {
  position: relative;
}

.project-3__icon a i {
  font-size: 24px;
  color: var(--it-common-black);
  font-weight: 500;
  position: relative;
  z-index: 2;
  transform: translateY(2px);
  margin-left: 19px;
  transition: 0.3s;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .project-3__icon a i {
    font-size: 19px;
    margin-left: 17px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .project-3__icon a i {
    font-size: 20px;
    margin-left: 17px;
    transform: translateY(1px);
  }
}

.project-3__icon a::before {
  content: "";
  height: 30px;
  width: 30px;
  background-color: var(--it-common-black-4);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  transition: 0.3s;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .project-3__icon a::before {
    height: 25px;
    width: 25px;
  }
}

.project-3__section-box {
  margin-bottom: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .project-3__section-box {
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .project-3__section-box {
    margin-bottom: 50px;
  }
}

.project-3__space-2 {
  padding: 100px 0;
}

.project-4__space {
  padding-bottom: 115px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .project-4__space {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

.project-4__project-tab {
  margin-bottom: 60px;
}

.project-4__project-tab ul li button {
  font-size: 18px;
  font-weight: 600;
  font-family: poppins, sans-serif;
  color: var(--it-common-black);
  background-color: transparent;
  padding: 5px 24px;
  border-radius: 30px;
  margin-right: 10px;
  transition: 0.3s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .project-4__project-tab ul li button {
    font-size: 16px;
    padding: 5px 20px;
  }
}

@media (max-width: 767px) {
  .project-4__project-tab ul li button {
    font-size: 16px;
    padding: 5px 18px;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .project-4__project-tab ul li button {
    padding: 5px 20px;
  }
}

.project-4__project-tab ul li button:hover {
  background-color: var(--it-theme-1);
  color: var(--it-common-black);
}

.project-4__project-tab ul li button.active {
  background-color: var(--it-theme-1);
  color: var(--it-common-black);
}

.project-4__main-img img {
  width: 100%;
}

.project-4__content-box {
  padding-top: 45px;
  padding-left: 60px;
  margin-left: 170px;
  background-color: var(--it-common-white);
  position: absolute;
  right: 0;
  bottom: -7%;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .project-4__content-box {
    padding-right: 20px;
    padding-left: 50px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .project-4__content-box {
    padding-left: 30px;
    margin-left: 156px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .project-4__content-box {
    padding-left: 25px;
    padding-right: 20px;
    margin-left: 92px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .project-4__content-box {
    padding-top: 45px;
    padding-left: 40px;
    margin-left: 170px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .project-4__content-box {
    padding-top: 40px;
    padding-left: 27px;
    margin-left: 90px;
  }
}

@media (max-width: 767px) {
  .project-4__content-box {
    padding-top: 20px;
    padding-left: 15px;
    margin-left: 67px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .project-4__content-box {
    padding-top: 54px;
    padding-left: 57px;
    margin-left: 180px;
  }
}

.project-4__title-sm {
  font-size: 22px;
  font-weight: 700;
  padding-bottom: 10px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .project-4__title-sm {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .project-4__title-sm {
    font-size: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .project-4__title-sm {
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .project-4__title-sm {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .project-4__title-sm {
    padding-bottom: 0;
    font-size: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .project-4__title-sm {
    font-size: 19px;
  }
}

.project-4__title-sm:hover.title-color-1 {
  color: #fcace9;
}

.project-4__title-sm:hover.title-color-2 {
  color: var(--it-common-blue-4);
}

.project-4__title-sm:hover.title-color-3 {
  color: #59c9da;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
  .project-4__text p {
    padding-right: 10px;
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .project-4__text p {
    font-size: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 767px) {
  .project-4__text p {
    font-size: 12px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .project-4__text p {
    font-size: 16px;
    padding-right: 30px;
  }
}

.project-4__category {
  margin-bottom: 20px;
}

.project-4__category span {
  font-size: 15px;
  color: var(--it-common-black);
  background-color: #fcace9;
  padding: 6px 28px;
  border-radius: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .project-4__category span {
    padding: 5px 20px;
  }
}

@media (max-width: 767px) {
  .project-4__category span {
    font-size: 14px;
    padding: 4px 17px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .project-4__category span {
    font-size: 15px;
    padding: 5px 25px;
  }
}

.project-4__category.category-color-2 span {
  background-color: var(--it-common-blue-4);
}

.project-4__category.category-color-3 span {
  background-color: #59c9da;
}

.project-4__link {
  padding-bottom: 40px;
}

@media (max-width: 767px) {
  .project-4__link {
    padding-bottom: 20px;
  }
}

.project-4__link.link-color-2 span {
  color: var(--it-common-blue-4);
}

.project-4__link.link-color-3 span {
  color: #59c9da;
}

.project-4__link span {
  font-size: 16px;
  font-weight: 500;
  color: #fcace9;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .project-4__link span {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .project-4__link span {
    font-size: 14px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .project-4__link span {
    font-size: 16px;
  }
}

.project-4__link span:hover i {
  animation: tfLeftToRight 0.4s forwards;
}

.project-4__link span i {
  padding-left: 10px;
}

.project__section-arrow button {
  height: 50px;
  width: 50px;
  background-color: var(--it-theme-1);
  border-radius: 50%;
  line-height: 53px;
  text-align: center;
  color: var(--it-common-black);
  margin: 0 10px;
  transition: 0.4s;
}

.project__section-arrow button:hover {
  transform: scale(1.2);
}

.project-details__plr {
  padding: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .project-details__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .project-details__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .project-details__plr {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .project-details__plr {
    padding: 0;
  }
}

.project-details__right {
  padding-bottom: 5px;
  margin-left: 240px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .project-details__right {
    margin-left: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .project-details__right {
    margin-left: 0;
  }
}

.project-details__right ul {
  padding-bottom: 5px;
}

.project-details__right ul li {
  list-style-type: none;
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
  transition: 0.3s;
  text-decoration: transparent;
}

@media (max-width: 767px) {
  .project-details__right ul li {
    font-size: 14px;
  }
}

.project-details__right ul li:last-child {
  margin-bottom: 0;
}

.project-details__right ul li:hover {
  text-decoration: underline;
}

.project-details__right ul li i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  font-weight: 500;
  color: var(--it-common-blue-3);
  font-size: 16px;
}

.project-details__right ul li span {
  font-weight: 600;
  font-size: 17px;
  margin-right: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .project-details__right ul li span {
    font-size: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .project-details__right ul li span {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .project-details__right ul li span {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .project-details__left {
    padding-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .project-details__left {
    padding-bottom: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .project-details__left {
    padding-bottom: 30px;
  }
}

.project-details__left h4 {
  font-size: 24px;
}

@media (max-width: 767px) {
  .project-details__left h4 {
    font-size: 18px;
  }
}

.project-details__left p {
  margin-bottom: 0;
  font-size: 17px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .project-details__left p br {
    display: none;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .project-details__left p br {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .project-details__left p {
    padding-right: 10px;
    font-size: 15px;
  }

  .project-details__left p br {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .project-details__left p {
    font-size: 14px;
  }

  .project-details__left p br {
    display: none;
  }
}

@media (max-width: 767px) {
  .project-details__left p {
    padding-right: 0;
    font-size: 13px;
  }

  .project-details__left p br {
    display: none;
  }
}

.project-details__border {
  margin-top: 60px;
  padding: 65px 120px;
  border: 1px solid var(--it-border-1);
  border-radius: 20px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .project-details__border {
    padding: 70px 80px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .project-details__border {
    padding: 70px 37px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .project-details__border {
    padding: 70px 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .project-details__border {
    padding: 50px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .project-details__border {
    padding: 30px 20px;
  }
}

@media (max-width: 767px) {
  .project-details__border {
    padding: 20px;
  }
}

.project-details__text {
  border-bottom: 1px solid var(--it-border-1);
  margin-bottom: 60px;
}

.project-details__text p {
  font-size: 17px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .project-details__text p br {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .project-details__text p br {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .project-details__text p {
    font-size: 16px;
  }

  .project-details__text p br {
    display: none;
  }
}

@media (max-width: 767px) {
  .project-details__text p {
    font-size: 14px;
  }

  .project-details__text p br {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .project-details__solution {
    padding-bottom: 20px;
  }
}

.project-details__solution h4 {
  font-weight: 700;
  font-size: 24px;
  padding-bottom: 10px;
}

@media (max-width: 767px) {
  .project-details__solution h4 {
    font-size: 18px;
  }
}

.project-details__solution p {
  padding-bottom: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .project-details__solution p {
    padding-right: 20px;
  }
}

@media (max-width: 767px) {
  .project-details__solution p {
    font-size: 14px;
  }
}

.project-details__solution ul {
  padding-bottom: 5px;
}

.project-details__solution ul li {
  list-style-type: none;
  position: relative;
  padding-left: 25px;
  margin-bottom: 15px;
  transition: 0.3s;
  text-decoration: transparent;
}

.project-details__solution ul li:hover {
  text-decoration: underline;
}

.project-details__solution ul li i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  font-weight: 500;
  color: var(--it-common-blue-3);
  font-size: 16px;
}

.project-details__solution ul li span {
  font-weight: 400;
  font-size: 17px;
  margin-right: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .project-details__solution ul li span {
    font-size: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
(max-width: 767px) {
  .project-details__solution ul li span {
    font-size: 15px;
  }
}

.project-details__img img {
  width: 100%;
}

.project-2__wrapper {
  display: flex;
  flex-wrap: wrap;
}

.project-2__wrapper .project-2__item {
  padding: 0 15px;
}

.project-3__inner-wrapper .project-3__item {
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  .project-3__inner-wrapper .project-3__item {
    margin-bottom: 30px;
  }
}

.project-3__inner-wrapper .project-3__item:hover .project-3__main-img::after {
  opacity: 1;
  visibility: visible;
  height: 19%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .project-3__inner-wrapper .project-3__item:hover .project-3__main-img::after {
    height: 30%;
  }
}

@media (max-width: 767px) {
  .project-3__inner-wrapper .project-3__item:hover .project-3__main-img::after {
    height: 37%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .project-3__inner-wrapper .project-3__item:hover .project-3__main-img::after {
    height: 24%;
  }
}

.project-3__inner-wrapper .project-3__item:hover .project-3__img-icon {
  opacity: 1;
  visibility: visible;
}

.project-3__inner-wrapper .project-3__item:hover .project-3__img-text {
  opacity: 1;
  visibility: visible;
}

.project-3__inner-wrapper .project-3__main-img {
  position: relative;
}

.project-3__inner-wrapper .project-3__main-img::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  height: 0;
  width: 100%;
  background-color: rgba(41, 41, 48, 0.488);
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
}

.project-3__inner-wrapper .project-3__img-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 28px;
  z-index: 4;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .project-3__inner-wrapper .project-3__img-icon {
    margin: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .project-3__inner-wrapper .project-3__img-icon {
    margin: 21px;
  }
}

@media (max-width: 767px) {
  .project-3__inner-wrapper .project-3__img-icon {
    margin: 15px;
  }
}

.project-3__inner-wrapper .project-3__img-icon a {
  height: 90px;
  width: 90px;
  background-color: var(--it-theme-1);
  display: inline-block;
  line-height: 110px;
  text-align: center;
  border-radius: 5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .project-3__inner-wrapper .project-3__img-icon a {
    height: 70px;
    width: 70px;
    line-height: 82px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .project-3__inner-wrapper .project-3__img-icon a {
    height: 70px;
    width: 70px;
    line-height: 82px;
  }
}

@media (max-width: 767px) {
  .project-3__inner-wrapper .project-3__img-icon a {
    height: 40px;
    width: 40px;
    line-height: 46px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .project-3__inner-wrapper .project-3__img-icon a {
    height: 50px;
    width: 50px;
    line-height: 56px;
  }
}

.project-3__inner-wrapper .project-3__img-icon a i {
  font-weight: 500;
  font-size: 40px;
  color: var(--it-common-black);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .project-3__inner-wrapper .project-3__img-icon a i {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .project-3__inner-wrapper .project-3__img-icon a i {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .project-3__inner-wrapper .project-3__img-icon a i {
    font-size: 20px;
  }
}

.project-3__inner-wrapper .project-3__img-text {
  position: absolute;
  left: 0;
  bottom: 0;
  margin: 30px;
  z-index: 4;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
}

@media (max-width: 767px) {
  .project-3__inner-wrapper .project-3__img-text {
    margin: 15px;
  }
}

.project-3__inner-wrapper .project-3__img-text h4 {
  color: var(--it-common-white);
  font-weight: 700;
  font-size: 22px;
  transition: 0.3s;
}

@media (max-width: 767px) {
  .project-3__inner-wrapper .project-3__img-text h4 {
    font-size: 16px;
  }
}

.project-3__inner-wrapper .project-3__img-text h4:hover {
  color: var(--it-theme-1);
}

.project-3__inner-wrapper .project-3__img-text i {
  font-weight: 400;
  font-style: normal;
  color: var(--it-common-white);
  font-size: 16px;
}

.project-3__inner-wrapper .project-3__img-text i span {
  color: var(--it-theme-1);
}

@media (max-width: 767px) {
  .project-3__inner-wrapper .project-3__img-text i {
    font-size: 12px;
  }
}

.social__shape-1 {
  position: absolute;
  top: 30%;
  left: 9%;
  z-index: -1;
  animation: moving 9s linear infinite;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .social__shape-1 {
    top: 11%;
  }
}

.social__mlr {
  margin: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .social__mlr {
    margin: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .social__mlr {
    margin: 0 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .social__mlr {
    margin: 0 30px;
  }
}

@media (max-width: 767px) {
  .social__mlr {
    margin: 0;
  }
}

.social__plr {
  padding: 0 200px;
  padding-top: 100px;
  padding-bottom: 70px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .social__plr {
    padding: 0 100px;
    padding-top: 100px;
    padding-bottom: 70px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .social__plr {
    padding: 0 30px;
    padding-top: 100px;
    padding-bottom: 70px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .social__plr {
    padding: 0 30px;
    padding-top: 100px;
    padding-bottom: 70px;
  }
}

@media (max-width: 767px) {
  .social__plr {
    padding: 0;
    padding-top: 100px;
    padding-bottom: 70px;
  }
}

.social__item {
  padding: 30px 25px;
  box-shadow: 2.5px 4.33px 70px 0 rgba(0, 0, 0, 0.03);
  border: 2px dashed transparent;
  transition: 0.3s;
  background-color: var(--it-common-white);
}

@media (max-width: 767px) {
  .social__item {
    padding: 20px 15px;
  }
}

.social__item:hover {
  border: 2px dashed var(--it-common-blue-3);
  transform: translateY(-10px);
}

.social__item:hover.border-none {
  border: 2px dashed transparent;
}

.social__item:hover .social__link span i {
  color: var(--it-common-black);
}

.social__border-color-2:hover {
  border-color: var(--it-theme-1);
}

.social__border-color-2 .social__text .social__title:hover {
  color: var(--it-theme-1);
}

.social__border-color-3:hover {
  border-color: var(--it-common-paste);
}

.social__border-color-3 .social__text .social__title:hover {
  color: var(--it-common-paste);
}

.social__border-color-4:hover {
  border-color: var(--it-common-red);
}

.social__border-color-4 .social__text .social__title:hover {
  color: var(--it-common-red);
}

.social__icon {
  padding-right: 20px;
}

.social__icon img {
  min-width: 62px;
}

.social__title {
  font-weight: 700;
  font-size: 22px;
  transition: 0.3s;
}

.social__title:hover {
  color: var(--it-common-blue-3);
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .social__title {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .social__title {
    font-size: 20px;
  }
}

.social__text {
  font-weight: 400;
  font-size: 12px;
}

.social__link span {
  width: 50px;
  height: 50px;
  background-color: var(--it-common-blue-2);
  display: inline-block;
  border-radius: 50%;
  line-height: 55px;
  text-align: center;
}

.social__link span i {
  transition: 0.3s;
  font-size: 17px;
  color: var(--it-common-blue-3);
}

.social__color-2 span {
  background-color: var(--it-theme-1);
}

.social__color-3 span {
  background-color: var(--it-common-paste-2);
}

.social__color-3 span i {
  color: var(--it-common-paste-3);
}

.social__color-4 span {
  background-color: var(--it-common-red-2);
}

.social__color-4 span i {
  color: var(--it-common-red);
}

.social__plr-2 {
  padding: 0 200px;
  padding-bottom: 70px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .social__plr-2 {
    padding: 0 100px;
    padding-bottom: 70px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .social__plr-2 {
    padding: 0 30px;
    padding-bottom: 70px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .social__plr-2 {
    padding: 0 30px;
    padding-bottom: 70px;
  }
}

@media (max-width: 767px) {
  .social__plr-2 {
    padding: 0;
    padding-bottom: 70px;
  }
}

.company__left-img img {
  max-width: inherit;
  margin-left: -225px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .company__left-img img {
    max-width: 100%;
    margin-left: -50px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .company__left-img img {
    max-width: 100%;
    margin-left: -50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .company__left-img img {
    max-width: 100%;
    margin-left: -10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .company__left-img img {
    max-width: 100%;
    margin-left: 0;
    margin-bottom: 70px;
  }
}

@media (max-width: 767px) {
  .company__left-img img {
    max-width: 100%;
    margin-left: 0;
    margin-bottom: 50px;
  }
}

.company__service-tab ul {
  padding-bottom: 30px;
}

.company__service-tab ul li button::before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  height: 12px;
  width: 12px;
  background-color: var(--it-theme-1);
  border-radius: 50%;
}

.company__service-tab ul li button.btn-color-3::before {
  background-color: var(--it-common-pink-2);
}

.company__service-tab ul li button.btn-color-2::before {
  background-color: var(--it-common-blue);
}

.company__service-tab ul li button.active span {
  border-bottom: 1px solid var(--it-common-black);
}

.company__service-tab ul li button span {
  font-weight: 500;
  font-size: 17px;
  color: var(--it-common-black);
  transition: 0.3s;
}

@media only screen and (min-width: 450px) and (max-width: 575px) {
  .company__service-tab ul li button span {
    font-size: 15px;
  }
}

.company__tab-icon span {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-color: var(--it-common-yellow);
  line-height: 70px;
  margin-right: 30px;
  text-align: center;
  display: inline-block;
}

.company__tab-text h4 {
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
}

.company__tab-content-wrapper {
  padding-bottom: 40px;
}

.cta__plr {
  margin: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .cta__plr {
    margin: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cta__plr {
    margin: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .cta__plr {
    margin: 0 30px;
  }
}

@media (max-width: 767px) {
  .cta__plr {
    margin: 0;
  }
}

.cta__bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  border-radius: 100px;
  margin-bottom: -165px;
  padding: 70px;
}

@media (max-width: 767px) {
  .cta__bg {
    padding: 30px;
    border-radius: 0;
  }
}

.cta__title {
  font-size: 35px;
  font-weight: 700;
  color: var(--it-common-black);
  padding-bottom: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta__title {
    font-size: 27px;
  }
}

@media (max-width: 767px) {
  .cta__title {
    font-size: 20px;
  }
}

.cta__section-box p {
  padding-bottom: 15px;
}

@media (max-width: 767px) {
  .cta__section-box p {
    font-size: 14px;
  }

  .cta__section-box p br {
    display: none;
  }
}

.cta__shape-icon {
  position: absolute;
  top: -76px;
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta__btn .white-btn {
    height: 47px;
    line-height: 49px;
    padding: 0 30px;
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .cta__btn .white-btn {
    height: 47px;
    line-height: 49px;
    padding: 0 30px;
    font-size: 16px;
  }
}

.cta__section-wrapper {
  padding-top: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .cta__section-wrapper {
    padding-top: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cta__section-wrapper {
    padding-top: 0;
  }
}

.cta__bg-2 {
  border-radius: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta__bg-2 {
    padding: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta__section-box {
    margin-top: 30px;
  }
}

.testimonial__shape-1 {
  position: absolute;
  left: 3%;
  top: 11%;
  animation: rotate2 15s linear infinite;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial__shape-1 {
    top: 4%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial__shape-1 {
    top: 3%;
  }
}

@media (max-width: 767px) {
  .testimonial__shape-1 {
    left: -12%;
    top: 0%;
  }
}

.testimonial__shape-2 {
  position: absolute;
  right: 3%;
  bottom: 2%;
  animation: moving 9s linear infinite;
}

.testimonial__shape-3 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}

.testimonial__item-shape {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.testimonial__plr {
  padding: 120px 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .testimonial__plr {
    padding: 120px 150px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .testimonial__plr {
    padding: 120px 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .testimonial__plr {
    padding: 120px 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial__plr {
    padding: 120px 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial__plr {
    padding: 120px 30px;
  }
}

@media (max-width: 767px) {
  .testimonial__plr {
    padding: 120px 0;
  }
}

.testimonial__item {
  background-color: #3a3a42;
  padding: 30px;
}

@media (max-width: 767px) {
  .testimonial__item {
    flex-wrap: wrap;
  }
}

.testimonial__main-img {
  flex: 0 0 auto;
}

@media (max-width: 767px) {
  .testimonial__main-img {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .testimonial__main-img img {
    width: 100%;
  }
}

.testimonial__content {
  padding-left: 20px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px),
only screen and (min-width: 1200px) and (max-width: 1399px),
(max-width: 767px) {
  .testimonial__content {
    padding-left: 0;
  }
}

.testimonial__author-name {
  font-weight: 700;
  font-size: 22px;
  color: var(--it-common-white);
  transition: 0.3s;
}

.testimonial__author-name:hover {
  color: var(--it-theme-1);
}

.testimonial__author-info {
  padding-bottom: 10px;
}

.testimonial__author-info span {
  color: var(--it-common-white);
}

@media (max-width: 767px) {
  .testimonial__content-text {
    padding-bottom: 30px;
  }
}

.testimonial__content-text p {
  color: var(--it-common-white);
  padding-bottom: 5px;
  font-style: italic;
  padding-right: 35px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .testimonial__content-text p {
    padding-right: 20px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .testimonial__content-text p {
    padding-right: 18px;
  }
}

@media (max-width: 767px) {
  .testimonial__content-text p {
    padding-right: 0;
  }
}

.testimonial__content-text i {
  color: var(--it-theme-1);
  font-size: 25px;
  padding-right: 5px;
}

.testimonial__content-text i.star-color {
  color: var(--it-common-white);
}

.testimonial-slider-dots {
  text-align: center;
  margin-top: 60px;
  position: relative;
  z-index: 2;
}

.testimonial-slider-dots .swiper-pagination-bullet {
  width: 18px;
  height: 18px;
  display: inline-block;
  border-radius: 50%;
  border: 2px solid var(--it-theme-1);
  background-color: transparent;
  opacity: 1;
  margin: 0 5px;
  transition: 0.3s;
}

.testimonial-slider-dots .swiper-pagination-bullet-active {
  background-color: var(--it-theme-1);
}

.testimonial-2__shape-1 {
  position: absolute;
  top: 73%;
  left: 24%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-2__shape-1 {
    left: 20%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-2__shape-1 {
    left: 10%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .testimonial-2__shape-1 {
    left: 0%;
  }
}

.testimonial-2__shape-2 {
  position: absolute;
  top: 85%;
  right: 25%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-2__shape-2 {
    right: 21%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-2__shape-2 {
    right: 11%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .testimonial-2__shape-2 {
    right: 0%;
  }
}

.testimonial-2__shape-3 {
  position: absolute;
  top: 36%;
  left: 16%;
  animation: tpupdown 0.8s infinite alternate;
}

.testimonial-2__shape-4 {
  position: absolute;
  top: 43%;
  right: 1%;
  animation: tptranslateX2 4s infinite alternate;
}

.testimonial-2__shape-5 {
  position: absolute;
  top: 66%;
  right: 11%;
  animation: moving 7s linear infinite;
}

.testimonial-2__shape-6 {
  position: absolute;
  top: 17%;
  left: 0;
  animation: jumpTwo 4s infinite linear;
}

.testimonial-2__shape-7 {
  position: absolute;
  bottom: 8%;
  right: 0;
  animation: tpupdown 0.8s infinite alternate;
}

.testimonial-2__shape-8 {
  position: absolute;
  left: 2%;
  bottom: 20%;
  animation: moving 9s linear infinite;
}

.testimonial-2__space {
  padding-top: 120px;
  padding-bottom: 120px;
}

.testimonial-2__main-thumb {
  padding-bottom: 15px;
}

.testimonial-2__author-info {
  padding-bottom: 15px;
}

.testimonial-2__author-name {
  font-weight: 700;
  font-size: 22px;
  transition: 0.3s;
}

.testimonial-2__author-name:hover {
  color: var(--it-common-blue);
}

.testimonial-2__star {
  padding-bottom: 10px;
}

.testimonial-2__star i {
  color: var(--it-theme-1);
  font-size: 20px;
  padding: 0 2px;
}

.testimonial-2__star i.star-color {
  color: #ddd;
}

.testimonial-2__text p {
  padding: 0 390px;
  font-style: italic;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .testimonial-2__text p {
    padding: 0 360px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-2__text p {
    padding: 0 270px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-2__text p {
    padding: 0 150px;
  }
}

@media (max-width: 767px) {
  .testimonial-2__text p {
    padding: 0;
  }

  .testimonial-2__text p br {
    display: none;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .testimonial-2__text p {
    padding: 0 70px;
  }
}

.testimonial-3__space {
  padding: 120px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-3__main-img {
    margin-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .testimonial-3__main-img {
    margin-bottom: 50px;
  }
}

.testimonial-3__icon {
  position: absolute;
  left: 8%;
  bottom: -2%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-3__icon {
    left: 14%;
  }
}

@media (max-width: 767px) {
  .testimonial-3__icon {
    left: 0%;
    bottom: -5%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .testimonial-3__icon {
    left: 3%;
    bottom: -3%;
  }
}

.testimonial-3__icon span {
  font-size: 40px;
  color: var(--it-common-white);
  height: 105px;
  width: 105px;
  border-radius: 50%;
  line-height: 101px;
  background-color: var(--it-theme-1);
  text-align: center;
  display: inline-block;
  border: 7px solid var(--it-common-white);
}

@media (max-width: 767px) {
  .testimonial-3__icon span {
    font-size: 20px;
    height: 70px;
    width: 70px;
    line-height: 70px;
    border: 4px solid var(--it-common-white);
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .testimonial-3__icon span {
    font-size: 40px;
    height: 105px;
    width: 105px;
    line-height: 101px;
    border: 5px solid var(--it-common-white);
  }
}

.testimonial-3__content-icon {
  padding-bottom: 5px;
}

.testimonial-3__content-icon span {
  font-size: 60px;
  color: var(--it-theme-1);
}

@media (max-width: 767px) {
  .testimonial-3__content-icon span {
    font-size: 50px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .testimonial-3__content-icon span {
    font-size: 56px;
  }
}

.testimonial-3__text {
  padding-bottom: 20px;
}

.testimonial-3__text p {
  font-size: 21px;
  font-style: italic;
  padding-right: 95px;
  line-height: 31px;
  margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-3__text p {
    font-size: 18px;
    padding-right: 53px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-3__text p {
    padding: 0 55px;
    font-size: 19px;
  }
}

@media (max-width: 767px) {
  .testimonial-3__text p {
    padding: 0 6px;
    font-size: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .testimonial-3__text p {
    padding: 0 15px;
    font-size: 17px;
  }
}

.testimonial-3__author-name {
  font-weight: 700;
  font-size: 22px;
  transition: 0.3s;
}

.testimonial-3__author-name:hover {
  color: var(--it-common-paste);
}

@media (max-width: 767px) {
  .testimonial-3__author-name {
    font-size: 19px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .testimonial-3__author-name {
    font-size: 21px;
  }
}

.testimonial-3__author-name::before {
  content: "";
  height: 4px;
  width: 32px;
  background-color: var(--it-theme-1);
  display: inline-block;
  transform: translateY(-6px);
  margin-right: 10px;
}

.testimonial-3__star i {
  color: var(--it-theme-1);
  font-size: 20px;
  padding-right: 6px;
}

.testimonial-3__star i.star-color {
  color: #ddd;
}

.testimonial-3__shape-1 {
  position: absolute;
  right: 4%;
  bottom: 0;
  z-index: -1;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px),
only screen and (min-width: 1400px) and (max-width: 1599px),
only screen and (min-width: 1200px) and (max-width: 1399px) {
  .testimonial-3__shape-1 {
    right: 0;
  }
}

.testimonial-3__shape-2 {
  position: absolute;
  left: 12%;
  top: 26%;
  z-index: -1;
  animation: zoom 7s infinite;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .testimonial-3__shape-2 {
    left: 8%;
    top: 21%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .testimonial-3__shape-2 {
    left: -2%;
    top: 21%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .testimonial-3__shape-2 {
    left: -2%;
    top: 3%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-3__shape-2 {
    left: -4%;
    top: 2%;
  }
}

.testimonial-3__shape-3 {
  position: absolute;
  left: 14%;
  top: 58%;
  z-index: -1;
  animation: moving 9s linear infinite;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .testimonial-3__shape-3 {
    left: 7%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .testimonial-3__shape-3 {
    left: 0%;
    top: 62%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .testimonial-3__shape-3 {
    left: -1%;
  }
}

.testimonial-3__shape-4 {
  position: absolute;
  left: 3%;
  bottom: 8%;
  z-index: -1;
  animation: moving 9s linear infinite;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .testimonial-3__shape-4 {
    left: 6%;
    bottom: 5%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-3__shape-4 {
    left: 5%;
    bottom: 6%;
  }
}

.testimonial-4-slider-dots {
  margin-top: 100px;
  position: relative;
  z-index: 2;
}

.testimonial-4-slider-dots .swiper-pagination-bullet {
  width: 18px;
  height: 18px;
  display: inline-block;
  border-radius: 50%;
  border: 2px solid var(--it-theme-1);
  background-color: transparent;
  opacity: 1;
  margin: 0 5px;
  transition: 0.3s;
}

.testimonial-4-slider-dots .swiper-pagination-bullet-active {
  background-color: var(--it-theme-1);
}

.testimonial-3-slider-dots {
  margin-top: 60px;
  position: relative;
  z-index: 2;
}

.testimonial-3-slider-dots .swiper-pagination-bullet {
  width: 18px;
  height: 18px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid var(--it-theme-1);
  background-color: transparent;
  opacity: 1;
  margin: 0 5px;
  transition: 0.3s;
}

.testimonial-3-slider-dots .swiper-pagination-bullet-active {
  background-color: var(--it-theme-1);
}

.testimonial-4__plr {
  padding: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .testimonial-4__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .testimonial-4__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-4__plr {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .testimonial-4__plr {
    padding: 0;
  }
}

.testimonial-4__space {
  padding-top: 100px;
  padding-bottom: 100px;
}

.testimonial-4__section-box {
  padding-bottom: 40px;
}

.testimonial-4__item {
  background-color: var(--it-common-black-7);
  padding: 30px 55px;
  padding-bottom: 60px;
  position: relative;
  border-bottom-right-radius: 140px;
  border-bottom-left-radius: 140px;
  z-index: 2;
  border: 1px dashed transparent;
  transition: 0.3s;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .testimonial-4__item {
    padding: 30px 44px;
    padding-bottom: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-4__item {
    padding: 30px 22px;
    padding-bottom: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-4__item {
    border-bottom-right-radius: 200px;
    border-bottom-left-radius: 200px;
  }
}

@media (max-width: 767px) {
  .testimonial-4__item {
    padding: 30px 35px;
    padding-bottom: 30px;
    padding-bottom: 60px;
  }
}

.testimonial-4__item:hover {
  border: 1px dashed var(--it-theme-1);
}

.testimonial-4__icon {
  padding-bottom: 20px;
}

.testimonial-4__icon i {
  color: var(--it-theme-1);
  padding: 0 4px;
  font-size: 23px;
}

.testimonial-4__icon i.star-color {
  color: var(--it-common-white);
}

.testimonial-4__text {
  padding-bottom: 10px;
}

.testimonial-4__text p {
  font-style: italic;
  color: var(--it-common-white);
  line-height: 1.8;
}

.testimonial-4__author-info {
  padding-bottom: 15px;
}

.testimonial-4__author-info span {
  color: var(--it-common-white);
}

.testimonial-4__author-name {
  font-size: 22px;
  color: var(--it-common-white);
  padding-bottom: 5px;
  transition: 0.3s;
}

.testimonial-4__author-name:hover {
  color: var(--it-theme-1);
}

.testimonial-4__author-img {
  position: absolute;
  bottom: -13%;
  left: 50%;
  transform: translateX(-50%);
}

.testimonial-4__item-shape {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #292728;
  font-size: 120px;
  z-index: -1;
}

.testimonial-4__shape-1 {
  position: absolute;
  top: 6%;
  left: 3%;
  animation: rotate2 15s linear infinite;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-4__shape-1 {
    top: 3%;
  }
}

.testimonial-4__shape-2 {
  position: absolute;
  bottom: 13%;
  right: 6%;
  animation: tpupdown 0.8s infinite alternate;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .testimonial-4__shape-2 {
    bottom: 8%;
    right: 3%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .testimonial-4__shape-2 {
    bottom: 7%;
    right: 3%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .testimonial-4__shape-2 {
    bottom: 7%;
    right: 2%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-4__shape-2 {
    bottom: 5%;
    right: 2%;
  }
}

.work__plr {
  padding: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .work__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .work__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .work__plr {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .work__plr {
    padding: 0;
  }
}

.work__space {
  padding-top: 100px;
  padding-bottom: 90px;
}

.work__item-box {
  padding: 10px;
  background-color: #fafafa;
  border-radius: 3px;
}

.work__section-box {
  padding-bottom: 40px;
}

.work__item {
  padding: 50px 30px;
  border: 2px dashed var(--it-common-paste-3);
  border-radius: 3px;
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .work__item {
    padding: 50px 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .work__item {
    padding: 50px 10px;
  }
}

@media (max-width: 767px) {
  .work__item {
    padding: 30px 15px;
  }
}

.work__item.item-bg-2 {
  border: 2px dashed var(--it-common-blue-4);
}

.work__item.item-bg-3 {
  border: 2px dashed var(--it-common-pink-2);
}

.work__item.item-bg-4 {
  border: 2px dashed var(--it-common-green);
}

.work__item.item-bg-5 {
  border: 2px dashed var(--it-common-pink-3);
}

.work__item.item-bg-6 {
  border: 2px dashed #3aa583;
}

.work__item.item-bg-7 {
  border: 2px dashed #d04184;
}

.work__item.item-bg-8 {
  border: 2px dashed #7272b1;
}

.work__item.item-bg-9 {
  border: 2px dashed #9e661e;
}

.work__title-sm {
  font-size: 22px;
  font-weight: 700;
  padding-bottom: 5px;
  transition: 0.3s;
}

@media (max-width: 767px) {
  .work__title-sm {
    font-size: 19px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .work__title-sm {
    font-size: 21px;
  }
}

.work__title-color-1:hover {
  color: var(--it-common-paste-3);
}

.work__title-color-2:hover {
  color: var(--it-common-blue-4);
}

.work__title-color-3:hover {
  color: var(--it-common-pink-2);
}

.work__title-color-4:hover {
  color: var(--it-common-green);
}

.work__title-color-5:hover {
  color: var(--it-common-pink-3);
}

.work__title-color-6:hover {
  color: #3aa583;
}

.work__title-color-7:hover {
  color: #d04184;
}

.work__title-color-8:hover {
  color: #7272b1;
}

.work__title-color-9:hover {
  color: #9e661e;
}

.work__content p {
  padding: 0 35px;
  margin-bottom: 30px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .work__content p {
    padding: 0 5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .work__content p {
    padding: 0;
  }
}

@media (max-width: 767px) {
  .work__content p {
    padding: 0;
    font-size: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .work__content p {
    padding: 0;
  }
}

.work__icon img {
  width: 111px;
}

.work__shape {
  position: absolute;
  left: 42%;
  bottom: 7%;
  z-index: -1;
}

.work__link.link-bg-2 span {
  background-color: var(--it-common-blue-4);
}

.work__link.link-bg-3 span {
  background-color: var(--it-common-pink-2);
}

.work__link.link-bg-4 span {
  background-color: var(--it-common-green);
}

.work__link.link-bg-5 span {
  background-color: var(--it-common-pink-3);
}

.work__link.link-bg-6 span {
  background-color: #3aa583;
}

.work__link.link-bg-7 span {
  background-color: #d04184;
}

.work__link.link-bg-8 span {
  background-color: #7272b1;
}

.work__link.link-bg-9 span {
  background-color: #9e661e;
}

.work__link span {
  display: inline-block;
  background-color: var(--it-common-paste-3);
  height: 65px;
  width: 65px;
  line-height: 78px;
  text-align: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

@media (max-width: 767px) {
  .work__link span {
    height: 45px;
    width: 45px;
    line-height: 54px;
  }
}

.work__link span i {
  color: var(--it-common-white);
  font-size: 33px;
}

@media (max-width: 767px) {
  .work__link span i {
    font-size: 22px;
  }
}

.work__space-2 {
  padding-bottom: 60px;
  padding-top: 110px;
}

.work__inner-wrapper .work__title-sm {
  font-size: 22px;
  font-weight: 700;
  padding-bottom: 5px;
  transition: 0.3s;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .work__inner-wrapper .work__title-sm {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .work__inner-wrapper .work__title-sm {
    font-size: 19px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .work__inner-wrapper .work__title-sm {
    font-size: 19px;
  }
}

@media (max-width: 767px) {
  .work__inner-wrapper .work__title-sm {
    font-size: 19px;
  }

  .work__inner-wrapper .work__title-sm br {
    display: none;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .work__inner-wrapper .work__title-sm {
    font-size: 21px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .work__inner-wrapper .work__item {
    padding: 50px 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .work__inner-wrapper .work__item {
    padding: 50px 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .work__inner-wrapper .work__content p {
    font-size: 14px;
  }
}

.work-slider-dots {
  text-align: center;
  margin-top: 60px;
}

.work-slider-dots .swiper-pagination-bullet {
  width: 18px;
  height: 18px;
  display: inline-block;
  border-radius: 50%;
  border: 2px solid var(--it-theme-1);
  background-color: transparent;
  opacity: 1;
  margin: 0 5px;
  transition: 0.3s;
}

.work-slider-dots .swiper-pagination-bullet-active {
  background-color: var(--it-theme-1);
}

.work-2__plr {
  padding: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .work-2__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .work-2__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .work-2__plr {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .work-2__plr {
    padding: 0;
  }
}

.work-2__space {
  padding-top: 110px;
  padding-bottom: 70px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .work-2__space {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .work-2__space {
    padding-top: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .work-2__space {
    background-color: var(--it-common-black);
  }
}

.work-2__shape-1 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.work-2__shape-2 {
  position: absolute;
  top: 3%;
  left: 30%;
  animation: zoom 7s infinite;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .work-2__shape-2 {
    top: 2%;
    left: 2%;
  }
}

.work-2__shape-3 {
  position: absolute;
  top: 11%;
  right: 22%;
  animation: moving 9s linear infinite;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .work-2__shape-3 {
    top: 6%;
    right: 5%;
  }
}

.work-2__shape-4 {
  position: absolute;
  left: 0;
  top: 28%;
  animation: tpupdown 0.8s infinite alternate;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .work-2__shape-4 {
    top: 22%;
  }
}

.work-2__bg {
  position: relative;
}

.work-2__bg::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: var(--it-grey-2);
  z-index: -2;
}

.work-2__item-bg {
  background-color: var(--it-common-black-5);
  padding: 60px 20px;
  padding-bottom: 0;
  border-radius: 5px;
  border: 1px dashed var(--it-border-1);
}

.work-2__item-box {
  padding: 20px;
}

.work-2__item-box:hover .work-2__main-img::before {
  opacity: 1;
  visibility: visible;
}

.work-2__item-box:hover .work-2__title-sm::after {
  opacity: 1;
  visibility: visible;
  width: 100%;
}

.work-2__main-img {
  position: relative;
  display: inline-block;
}

.work-2__main-img::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--it-theme-1);
  height: 210px;
  width: 210px;
  border-radius: 50%;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
}

.work-2__main-img::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--it-common-white);
  height: 200px;
  width: 200px;
  border-radius: 50%;
  z-index: -1;
}

.work-2__number {
  position: absolute;
  top: 42%;
  left: -14%;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background-color: var(--it-theme-1);
  line-height: 45px;
  text-align: center;
}

.work-2__number span {
  font-size: 16px;
  color: var(--it-common-black);
}

.work-2__title-sm {
  font-size: 22px;
  font-weight: 700;
  color: var(--it-common-white);
  position: relative;
  padding-bottom: 5px;
  display: inline-block;
  transition: 0.3s;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .work-2__title-sm {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .work-2__title-sm {
    font-size: 19px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .work-2__title-sm {
    font-size: 21px;
  }
}

@media (max-width: 767px) {
  .work-2__title-sm {
    font-size: 20px;
  }
}

.work-2__title-sm:hover {
  color: var(--it-theme-1);
}

.work-2__title-sm::after {
  position: absolute;
  content: "";
  bottom: 1px;
  left: 0;
  background-color: var(--it-common-white);
  height: 1px;
  width: 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
}

.work-2__content p {
  padding: 0 30px;
  color: var(--it-common-white);
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .work-2__content p {
    padding: 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .work-2__content p {
    padding: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .work-2__content p {
    padding: 0 51px;
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .work-2__content p {
    padding: 0;
    font-size: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .work-2__content p {
    padding: 0;
  }
}

.skill__plr {
  padding: 130px 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .skill__plr {
    padding: 130px 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .skill__plr {
    padding: 130px 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .skill__plr {
    padding: 130px 30px;
  }
}

@media (max-width: 767px) {
  .skill__plr {
    padding: 130px 0;
  }
}

.skill-bg {
  background-repeat: no-repeat;
  background-size: cover;
}

.skill__section-box p {
  color: var(--it-common-white);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .skill__section-box p br {
    display: none;
  }
}

.skill__progress-item .progress-bar {
  background-color: #ffdc60;
  height: 6px;
  margin-left: 3px;
  margin-top: 2px;
  border-radius: 10px;
  position: relative;
}

.skill__progress-item .progress-bar span {
  position: absolute;
  top: 0;
  left: 0;
  background-color: red;
  transform: translateY(-36%);
  padding: 20px;
  display: inline-block;
  color: var(--it-common-white);
}

.skill__progress-item .progress {
  height: 12px;
  background-color: transparent;
  border: 1px solid #ffdc60;
}

.tpdrive-bar-item {
  overflow: hidden;
  padding: 10px 0;
  margin-right: 150px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .tpdrive-bar-item {
    margin-right: 80px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .tpdrive-bar-item {
    margin-right: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpdrive-bar-item {
    margin-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .tpdrive-bar-item {
    margin-right: 0;
  }
}

.tpdrive-bar-title {
  font-size: 16px;
  color: var(--it-common-white);
  font-weight: 600;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpdrive-bar-title {
    padding-bottom: 8px;
  }
}

@media (max-width: 767px) {
  .tpdrive-bar-title {
    padding-bottom: 15px;
  }
}

.tpdrive-bar-progress .progress {
  overflow: visible;
  height: 10px;
  background-color: transparent;
  border: 1px solid #ffdc60;
}

.tpdrive-bar-item .progress-bar {
  background-color: #ffdc60;
  border-radius: 30px;
  position: relative;
  overflow: visible;
  height: 4px;
  margin-top: 2px;
  margin-left: 3px;
}

.tpdrive-bar-item .progress-bar span {
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(-50%);
  font-weight: 500;
  font-size: 14px;
  color: var(--it-common-black);
  width: 30px;
  height: 30px;
  background-color: #ffdc60;
  border: 2.5px solid #ffdc60;
  box-shadow: 0 2px 2px rgba(1, 15, 28, 0.12);
  border-radius: 0 20px 20px 20px;
}

.solution__plr {
  padding: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .solution__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .solution__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .solution__plr {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .solution__plr {
    padding: 0;
  }
}

.solution__space {
  padding-top: 100px;
  padding-bottom: 60px;
}

.solution__wrapper {
  padding: 100px 110px;
  border: 1px solid #ddd;
  border-radius: 10px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .solution__wrapper {
    padding: 100px 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .solution__wrapper {
    padding: 100px 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .solution__wrapper {
    padding: 100px 40px;
  }
}

@media (max-width: 767px) {
  .solution__wrapper {
    padding: 60px 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .solution__wrapper {
    padding: 60px 50px;
  }
}

.solution-item {
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 57px 0 rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 2;
}

.solution-top {
  background-color: #7ee1f0;
  padding: 20px;
}

.solution-top.color-2 {
  background-color: var(--it-common-blue-4);
}

.solution-top.color-3 {
  background-color: #ffb088;
}

.solution-top span {
  font-size: 22px;
  font-weight: 700;
  color: var(--it-common-black);
}

@media (max-width: 767px) {
  .solution-top span {
    font-size: 19px;
  }
}

.solution-feature-list ul {
  padding: 30px 20px;
}

.solution-feature-list ul li {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #efefef;
  padding-left: 15px;
  padding-right: 15px;
  transition: 0.3s;
}

.solution-feature-list ul li i {
  padding-right: 5px;
  transition: 0.3s;
}

.solution-feature-list ul li:last-child {
  margin-bottom: 0;
}

.solution-feature-list ul li:hover i {
  color: var(--it-theme-1);
}

.solution-link {
  padding: 0 30px 30px;
}

.solution-link.color-1:hover a {
  color: #7ee1f0;
}

.solution-link.color-2:hover a {
  color: var(--it-common-blue-3);
}

.solution-link.color-3:hover a {
  color: #ffb088;
}

.solution-link a {
  font-size: 17px;
  color: var(--it-common-black);
  font-weight: 600;
  transition: 0.3s;
}

.solution-link a i {
  font-size: 14px;
}

.solution__shape-1 {
  position: absolute;
  top: 18%;
  right: 17%;
  animation: moving 9s linear infinite;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .solution__shape-1 {
    top: 11%;
    right: 10%;
  }
}

.solution__shape-2 {
  position: absolute;
  bottom: 11%;
  left: 14%;
  animation: tpupdown 0.8s infinite alternate;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .solution__shape-2 {
    bottom: 7%;
    left: 14%;
  }
}

.solution__shape-3 {
  position: absolute;
  top: 14%;
  left: 11%;
  animation: zoom 7s infinite;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .solution__shape-3 {
    right: 8%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .solution__shape-3 {
    top: 7%;
    left: 1%;
  }
}

.solution__shape-4 {
  position: absolute;
  bottom: 9%;
  right: 12%;
  animation: zoom 7s infinite;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .solution__shape-4 {
    right: 8%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .solution__shape-4 {
    bottom: 1%;
    right: -2%;
  }
}

.work-3__plr {
  padding: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .work-3__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .work-3__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .work-3__plr {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .work-3__plr {
    padding: 0;
  }
}

.work-3__space {
  padding-top: 120px;
  padding-bottom: 90px;
}

.work-3__text {
  margin-right: 250px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--it-border-2);
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .work-3__text {
    margin-right: 160px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .work-3__text {
    margin-right: 120px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .work-3__text {
    margin-right: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .work-3__text {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .work-3__text {
    margin-right: 0;
  }
}

.work-3__text p {
  color: var(--it-common-white-2);
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
(max-width: 767px) {
  .work-3__text p {
    font-size: 15px;
  }
}

.work-3__button {
  padding-top: 35px;
}

.work-3__button p {
  padding-right: 270px;
  color: var(--it-common-white-2);
  padding-bottom: 30px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .work-3__button p {
    padding-right: 160px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .work-3__button p {
    padding-right: 120px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .work-3__button p {
    padding-right: 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .work-3__button p {
    padding-right: 0;
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .work-3__button p {
    padding-right: 0;
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .work-3__button p {
    padding-right: 0;
    font-size: 15px;
  }
}

.work-3__wrapper {
  background-color: #343344;
  padding: 10px;
  border-radius: 10px;
  transition: 0.3s;
  max-width: 500px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px) {
  .work-3__wrapper {
    max-width: 100%;
  }
}

.work-3__wrapper:hover {
  background-color: var(--it-common-black-7);
}

.work-3__wrapper:nth-child(2n + 1) {
  margin-left: 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .work-3__wrapper:nth-child(2n + 1) {
    margin-left: 170px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .work-3__wrapper:nth-child(2n + 1) {
    margin-left: 70px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .work-3__wrapper:nth-child(2n + 1) {
    margin-left: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .work-3__wrapper:nth-child(2n + 1) {
    margin-left: 0;
  }
}

.work-3__item {
  border-radius: 10px;
  border: 1px dashed var(--it-border-2);
  padding: 20px;
}

.work-3__icon {
  margin-right: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .work-3__icon {
    margin-right: 20px;
  }
}

@media (max-width: 767px) {
  .work-3__icon {
    margin-right: 20px;
  }
}

.work-3__icon span {
  font-size: 35px;
  color: #333;
  height: 70px;
  width: 70px;
  border-radius: 10px;
  background-color: var(--it-common-blue);
  line-height: 70px;
  display: inline-block;
  text-align: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .work-3__icon span {
    font-size: 30px;
    color: #333;
    height: 60px;
    width: 60px;
    line-height: 60px;
  }
}

@media (max-width: 767px) {
  .work-3__icon span {
    font-size: 30px;
    color: #333;
    height: 60px;
    width: 60px;
    line-height: 60px;
  }
}

.work-3__icon.icon-color-2 span {
  background-color: var(--it-theme-1);
}

.work-3__icon.icon-color-3 span {
  background-color: var(--it-common-pink-2);
}

.work-3__section-title {
  font-size: 22px;
  color: var(--it-common-white);
  transition: 0.3s;
}

.work-3__section-title.title-color-1:hover {
  color: var(--it-common-blue);
}

.work-3__section-title.title-color-2:hover {
  color: var(--it-theme-1);
}

.work-3__section-title.title-color-3:hover {
  color: var(--it-common-red);
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
(max-width: 767px) {
  .work-3__section-title {
    font-size: 19px;
  }
}

.work-3__right-text p {
  color: var(--it-common-white-2);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .work-3__right-text p {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .work-3__right-text p {
    font-size: 15px;
  }
}

.work-3__wrapper-space {
  margin-left: 200px;
  margin-right: 200px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .work-3__left-side {
    margin-bottom: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .work-3__wrapper-box {
    margin-left: 50px;
  }
}

.work-3__inner-wrapper .work-3__wrapper {
  background-color: var(--it-common-white);
  border: 1px dashed var(--it-theme-1);
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .work-3__inner-wrapper .work-3__wrapper {
    max-width: none;
  }
}

.work-3__inner-wrapper .work-3__wrapper:nth-child(2n + 1) {
  margin-left: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .work-3__inner-wrapper .work-3__wrapper .work-3__section-title {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .work-3__inner-wrapper .work-3__wrapper .work-3__section-title {
    font-size: 20px;
  }
}

.work-3__inner-wrapper .work-3__wrapper .work-3__item {
  border: none;
  background-color: var(--it-theme-1);
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .work-3__inner-wrapper .work-3__wrapper .work-3__icon {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .work-3__inner-wrapper .work-3__wrapper .work-3__icon {
    margin-bottom: 25px;
  }
}

.work-3__inner-wrapper .work-3__wrapper .work-3__icon span {
  background-color: var(--it-common-white);
}

.work-3__inner-wrapper .work-3__wrapper .work-3__icon span i {
  color: var(--it-theme-1);
}

.work-3__inner-wrapper .work-3__wrapper .work-3__right-text p {
  color: var(--it-common-black);
}

.work-3__inner-wrapper .work-3__wrapper .work-3__section-title {
  color: var(--it-common-black);
}

.work-3__inner-wrapper .work-3__wrapper.inner-wrapper-2 {
  border: 1px dashed var(--it-common-blue);
}

.work-3__inner-wrapper .work-3__wrapper.inner-wrapper-2 .work-3__item {
  background-color: var(--it-common-blue);
}

.work-3__inner-wrapper .work-3__wrapper.inner-wrapper-2 .work-3__icon span i {
  color: var(--it-common-blue);
}

.work-3__inner-wrapper .work-3__wrapper.inner-wrapper-3 {
  border: 1px dashed var(--it-common-red);
}

.work-3__inner-wrapper .work-3__wrapper.inner-wrapper-3 .work-3__item {
  background-color: var(--it-common-red);
}

.work-3__inner-wrapper .work-3__wrapper.inner-wrapper-3 .work-3__icon span i {
  color: var(--it-common-red);
}

.work-3__inner-wrapper .work-3__wrapper.inner-wrapper-4 {
  border: 1px dashed var(--it-common-pink-3);
}

.work-3__inner-wrapper .work-3__wrapper.inner-wrapper-4 .work-3__item {
  background-color: var(--it-common-pink-3);
}

.work-3__inner-wrapper .work-3__wrapper.inner-wrapper-4 .work-3__icon span i {
  color: var(--it-common-pink-3);
}

.work-3__inner-wrapper .work-3__wrapper.inner-wrapper-5 {
  border: 1px dashed #6bf89c;
}

.work-3__inner-wrapper .work-3__wrapper.inner-wrapper-5 .work-3__item {
  background-color: #6bf89c;
}

.work-3__inner-wrapper .work-3__wrapper.inner-wrapper-5 .work-3__icon span i {
  color: #6bf89c;
}

.work-3__inner-wrapper .work-3__wrapper .work-3__content {
  flex-wrap: wrap;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .work-3__inner-wrapper .work-3__content {
    flex-wrap: wrap;
  }

  .work-3__inner-wrapper .work-3__content .work-3__icon span {
    margin-bottom: 25px;
  }
}

.blog__space {
  padding-top: 120px;
  padding-bottom: 130px;
}

@media (max-width: 767px) {
  .blog__space {
    padding-bottom: 0;
  }
}

.blog__mlr {
  margin: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .blog__mlr {
    margin: 0 200px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .blog__mlr {
    margin: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .blog__mlr {
    margin: 0 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog__mlr {
    margin: 0 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__mlr {
    margin: 0 30px;
  }
}

@media (max-width: 767px) {
  .blog__mlr {
    margin: 0;
  }
}

.blog__content {
  padding-top: 30px;
}

.blog__catagory {
  margin-right: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__catagory {
    margin-right: 26px;
  }
}

@media (max-width: 767px) {
  .blog__catagory {
    margin-right: 20px;
  }
}

.blog__catagory a {
  font-weight: 500;
  font-size: 17px;
  color: var(--it-common-blue);
  padding: 6px 30px;
  border: 1px solid var(--it-common-blue);
  border-radius: 35px;
  display: inline-block;
}

@media (max-width: 767px) {
  .blog__catagory a {
    font-size: 15px;
    padding: 6px 25px;
  }
}

.blog__catagory-color-2 a {
  border: 1px solid var(--it-common-paste);
  color: var(--it-common-paste);
}

.blog__catagory-color-3 a {
  border: 1px solid var(--it-common-pink-4);
  color: var(--it-common-pink-4);
}

.blog__main-img:hover img {
  transform: scale(1.1) rotate(5deg);
}

.blog__main-img img {
  width: 100%;
  transition: 0.6s;
}

.blog__title {
  font-weight: 700;
  font-size: 22px;
  padding-bottom: 10px;
  transition: 0.3s;
  color: var(--it-common-black);
  line-height: 1.2;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .blog__title {
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .blog__title br {
    display: none;
  }
}

.blog__title:hover {
  color: var(--it-common-blue);
}

.blog__title-color-2:hover {
  color: var(--it-common-paste);
}

.blog__title-color-3:hover {
  color: var(--it-common-pink-4);
}

.blog__text {
  border-bottom: 1px solid var(--it-border-1);
  margin-bottom: 15px;
}

.blog__text p {
  padding-right: 30px;
}

@media (max-width: 767px) {
  .blog__text p {
    padding-right: 0;
  }
}

.blog__link a {
  font-weight: 500;
  font-size: 18px;
  color: var(--it-common-black);
}

.blog__link a span {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: var(--it-common-blue);
  line-height: 14px;
  display: inline-block;
  text-align: center;
  margin-left: 5px;
}

.blog__link a span i {
  font-size: 14px;
  color: var(--it-common-white);
  padding-left: 2px;
}

.blog__link a:hover span {
  animation: tfLeftToRight 0.4s forwards;
}

.blog__link-color-2 a span {
  background-color: var(--it-common-paste);
}

.blog__link-color-3 a span {
  background-color: var(--it-common-pink-4);
}

.blog-2__plr {
  padding: 0 220px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .blog-2__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px),
only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-2__plr {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-2__plr {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .blog-2__plr {
    padding: 0;
  }
}

.blog-2__item {
  margin-bottom: 70px;
}

.blog-2__item:hover .blog-2__thumb img {
  transform: scale(1.2);
}

.blog-2__thumb img {
  transition: 0.6s;
  width: 100%;
  transform: scale(1);
}

.blog-2__meta-img {
  flex: 0 0 auto;
  padding-right: 20px;
}

@media (max-width: 767px) {
  .blog-2__meta-img {
    padding-right: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-2__meta-img {
    padding-right: 20px;
  }
}

.blog-2__meta-box {
  padding: 0 70px;
  margin-top: -30px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .blog-2__meta-box {
    padding: 0 60px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .blog-2__meta-box {
    padding: 0 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .blog-2__meta-box {
    padding: 0 24px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-2__meta-box {
    padding: 0 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-2__meta-box {
    padding: 0 18px;
  }
}

@media (max-width: 767px) {
  .blog-2__meta-box {
    padding: 0 17px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-2__meta-box {
    padding: 0 70px;
  }
}

.blog-2__meta {
  box-shadow: 0 0 57px 0 rgba(0, 0, 0, 0.05);
  background-color: var(--it-common-white);
  padding: 11px 20px;
  border-radius: 5px;
  position: relative;
  z-index: 2;
}

.blog-2__date span {
  font-size: 17px;
  font-weight: 400;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .blog-2__date span {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-2__date span {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .blog-2__date span {
    font-size: 14px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-2__date span {
    font-size: 17px;
  }
}

.blog-2__content-text {
  border-bottom: 1px solid var(--it-border-1);
  padding-top: 30px;
  margin: 0 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .blog-2__content-text {
    margin: 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-2__content-text {
    margin: 0 9px;
  }
}

@media (max-width: 767px) {
  .blog-2__content-text {
    margin: 0 15px;
  }
}

@media only screen and (min-width: 440px) and (max-width: 575px) {
  .blog-2__content-text {
    margin: 0 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-2__content-text {
    margin: 0 80px;
  }
}

.blog-2__content-text p {
  padding-bottom: 10px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .blog-2__content-text p {
    padding: 0;
    padding-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .blog-2__content-text p {
    font-size: 14px;
  }
}

@media only screen and (min-width: 440px) and (max-width: 575px) {
  .blog-2__content-text p {
    font-size: 16px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-2__content-text p {
    font-size: 16px;
  }
}

.blog-2__content-title-sm {
  font-size: 22px;
  font-weight: 700;
  color: var(--it-common-black);
  transition: 0.3s;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-2__content-title-sm br {
    display: none;
  }
}

@media (max-width: 767px) {
  .blog-2__content-title-sm {
    font-size: 19px;
  }

  .blog-2__content-title-sm br {
    display: none;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-2__content-title-sm br {
    display: block;
  }
}

.blog-2__content-title-sm:hover {
  color: var(--it-common-blue-4);
}

.blog-2__content-button {
  padding-top: 30px;
}

.blog-3__mlr {
  margin: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .blog-3__mlr {
    margin: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .blog-3__mlr {
    margin: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-3__mlr {
    margin: 0 30px;
  }
}

@media (max-width: 767px) {
  .blog-3__mlr {
    margin: 0;
  }
}

.blog-3__item:hover .blog-3__meta span::after {
  width: 100%;
  opacity: 1;
  visibility: visible;
}

.blog-3__item:hover .blog-3__link span {
  color: var(--it-common-blue);
}

.blog-3__item:hover .blog-3__link span i {
  color: var(--it-common-blue);
}

.blog-3__item:hover .blog-3__main-thumb img {
  transform: scale(1.2);
}

.blog-3__main-thumb {
  position: relative;
  border-radius: 20px;
}

.blog-3__main-thumb img {
  width: 100%;
  transition: 0.6s;
}

.blog-3__thumb-overly {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  background-color: var(--it-theme-1);
  padding: 6px 32px;
  border-radius: 30px;
  margin: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-3__thumb-overly {
    padding: 4px 15px;
  }
}

@media (max-width: 767px) {
  .blog-3__thumb-overly {
    padding: 4px 18px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-3__thumb-overly {
    padding: 6px 30px;
  }
}

.blog-3__thumb-overly span {
  font-weight: 500;
  font-size: 17px;
  color: var(--it-common-black);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-3__thumb-overly span {
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  .blog-3__thumb-overly span {
    font-size: 14px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-3__thumb-overly span {
    font-size: 14px;
  }
}

.blog-3__content {
  padding: 25px 0;
}

.blog-3__meta span {
  font-weight: 400;
  font-size: 17px;
  color: #333;
  padding-bottom: 10px;
  display: inline-block;
  transition: 0.3s;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-3__meta span {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .blog-3__meta span {
    font-size: 14px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-3__meta span {
    font-size: 16px;
  }
}

.blog-3__meta span::after {
  content: "";
  position: absolute;
  bottom: 9px;
  left: 0;
  height: 1px;
  width: 0;
  background-color: var(--it-common-black);
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
}

.blog-3__title-sm {
  font-weight: 700;
  font-size: 22px;
  transition: 0.3s;
  line-height: 1.3;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-3__title-sm {
    font-size: 19px;
  }

  .blog-3__title-sm br {
    display: none;
  }
}

@media (max-width: 767px) {
  .blog-3__title-sm {
    font-size: 18px;
  }

  .blog-3__title-sm br {
    display: none;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-3__title-sm {
    font-size: 20px;
  }
}

.blog-3__title-sm:hover {
  color: var(--it-common-blue);
}

.blog-3__text {
  border-bottom: 1px solid var(--it-border-1);
}

.blog-3__text p {
  padding-right: 120px;
  padding-bottom: 10px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .blog-3__text p {
    padding-right: 90px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .blog-3__text p {
    padding-right: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .blog-3__text p {
    padding-right: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-3__text p {
    padding-right: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-3__text p {
    padding-right: 0;
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .blog-3__text p {
    padding-right: 0;
    font-size: 14px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-3__text p {
    padding-right: 0;
    font-size: 15px;
  }
}

.blog-3__link {
  padding-top: 15px;
}

.blog-3__link a {
  font-weight: 500;
  font-size: 18px;
  color: var(--it-common-black);
  transition: 0.3s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-3__link a {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .blog-3__link a {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .blog-3__link a {
    font-size: 16px;
  }
}

.blog-3__link a:hover {
  color: var(--it-common-blue);
}

.blog-3__link a:hover i {
  animation: tfLeftToRight 0.4s forwards;
}

.blog-3__link a i {
  padding-left: 8px;
  transform: translateY(2px);
  font-size: 17px;
  font-weight: 500;
  transition: 0.3s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-3__link a i {
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-3__link a i {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .blog-3__link a i {
    font-size: 14px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-3__link a i {
    font-size: 16px;
  }
}

.blog-3__link a i:hover {
  color: var(--it-common-black);
}

.blog-3__top-text p {
  padding-right: 106px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .blog-3__top-text p {
    padding-right: 85px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .blog-3__top-text p {
    padding-right: 7px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .blog-3__top-text p {
    padding-right: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .blog-3__top-text p {
    padding-right: 0;
    font-size: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-3__top-text p {
    padding-right: 0;
  }
}

.blog-4__mlr {
  margin: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .blog-4__mlr {
    margin: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .blog-4__mlr {
    margin: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-4__mlr {
    margin: 0 30px;
  }
}

@media (max-width: 767px) {
  .blog-4__mlr {
    margin: 0;
  }
}

.blog-4__item-bg {
  border-radius: 15px;
  background-repeat: no-repeat;
  background-size: cover;
}

.blog-4__item {
  padding: 70px 30px;
  border-radius: 5px;
  padding-bottom: 50px;
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-4__item {
    padding: 70px 26px;
    padding-bottom: 50px;
  }
}

.blog-4__item::after {
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f9f8f8;
  position: absolute;
  z-index: -1;
  transition: 0.4s;
  border-radius: 10px;
}

.blog-4__item:hover::after {
  background-color: rgba(34, 34, 34, 0.8);
}

.blog-4__item:hover .border-btn-2 {
  background-color: var(--it-common-white);
  color: var(--it-common-black);
}

.blog-4__item:hover .blog-4__title {
  color: var(--it-common-white);
}

.blog-4__item:hover .blog-4__content p {
  color: var(--it-common-white);
}

.blog-4__item:hover .blog-4__meta span {
  color: var(--it-common-white);
}

.blog-4__tag {
  position: absolute;
  top: 0;
  left: 30px;
}

.blog-4__tag.tag-color-2 span {
  background-color: var(--it-theme-1);
}

.blog-4__tag.tag-color-3 span {
  background-color: #ffb995;
}

.blog-4__tag span {
  background-color: #7ee1f0;
  padding: 12px 20px;
  display: inline-block;
  border-radius: 0 0 5px 5px;
  color: var(--it-common-black);
  font-size: 14px;
  font-weight: 600;
  transition: 0.3s;
}

.blog-4__meta {
  padding-bottom: 15px;
}

.blog-4__meta span {
  color: #818282;
  font-size: 16px;
  font-weight: 400;
  transition: 0.3s;
}

@media (max-width: 767px) {
  .blog-4__meta span {
    font-size: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-4__meta span {
    font-size: 16px;
  }
}

.blog-4__title {
  font-size: 22px;
  color: var(--it-common-black);
  line-height: 1.2;
  padding-bottom: 10px;
  transition: 0.3s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-4__title {
    font-size: 19px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-4__title {
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-4__title {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .blog-4__title {
    font-size: 19px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-4__title {
    font-size: 22px;
  }
}

.blog-4__title:hover.title-color-1 {
  color: #7ee1f0;
}

.blog-4__title:hover.title-color-2 {
  color: var(--it-theme-1);
}

.blog-4__title:hover.title-color-3 {
  color: #ffb995;
}

.blog-4__content {
  padding-bottom: 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-4__content {
    padding-bottom: 20px;
  }
}

.blog-4__content p {
  font-size: 16px;
  transition: 0.3s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-4__content p {
    font-size: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-4__content p {
    font-size: 14px;
  }
}

.blog-5__plr {
  padding: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .blog-5__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .blog-5__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-5__plr {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .blog-5__plr {
    padding: 0;
  }
}

.blog-5__space {
  padding-top: 100px;
  padding-bottom: 70px;
}

.blog-5__item {
  padding: 10px;
  border-radius: 10px;
  background-color: #31303f;
  transition: 0.3s;
  border: 1px dashed transparent;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .blog-5__item {
    margin: 0 150px;
  }
}

@media (max-width: 767px) {
  .blog-5__item {
    padding: 18px;
    flex-wrap: wrap;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-5__item {
    padding: 30px;
    flex-wrap: wrap;
  }
}

.blog-5__item:hover {
  background-color: var(--it-common-black-7);
  border: 1px dashed #89abf1;
}

.blog-5__item:hover .blog-5__link a {
  color: #89abf1;
}

.blog-5__item:hover .blog-5__title-sm {
  color: #89abf1;
}

.blog-5__main-img {
  padding-right: 30px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .blog-5__main-img {
    padding-right: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-5__main-img {
    padding-right: 60px;
  }
}

@media (max-width: 767px) {
  .blog-5__main-img {
    padding-right: 0;
    margin-bottom: 30px;
    width: 100%;
  }
}

.blog-5__main-img img {
  border-radius: 10px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .blog-5__main-img img {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .blog-5__main-img img {
    width: 100%;
  }
}

.blog-5__meta span {
  color: #89abf1;
  display: inline-block;
  margin-bottom: 13px;
}

.blog-5__title-sm {
  font-size: 22px;
  color: var(--it-common-white);
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .blog-5__title-sm {
    font-size: 19px;
  }

  .blog-5__title-sm br {
    display: none;
  }
}

@media (max-width: 767px) {
  .blog-5__title-sm {
    font-size: 19px;
  }

  .blog-5__title-sm br {
    display: none;
  }
}

.blog-5__content p {
  color: var(--it-common-white-2);
  padding-bottom: 10px;
  padding-right: 40px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .blog-5__content p {
    font-size: 15px;
    padding-right: 0;
  }
}

.blog-5__link a {
  font-size: 18px;
  color: var(--it-common-white);
  font-weight: 500;
  transition: 0.3s;
}

.blog-5__link a:hover i {
  animation: tfLeftToRight 0.4s forwards;
}

.blog-5__link a i {
  padding-left: 15px;
}

.blog-5__item-box-2 .blog-5__meta span {
  color: var(--it-theme-1);
}

.blog-5__item-box-2:hover {
  background-color: var(--it-common-black-7);
  border: 1px dashed var(--it-theme-1);
}

.blog-5__item-box-2:hover .blog-5__link a {
  color: var(--it-theme-1);
}

.blog-5__item-box-2:hover .blog-5__title-sm.title-color-2 {
  color: var(--it-theme-1);
}

.blog-5__item-box-3 .blog-5__meta span {
  color: var(--it-common-pink-4);
}

.blog-5__item-box-3:hover {
  background-color: var(--it-common-black-7);
  border: 1px dashed var(--it-common-pink-4);
}

.blog-5__item-box-3:hover .blog-5__link a {
  color: var(--it-common-pink-4);
}

.blog-5__item-box-3:hover .blog-5__title-sm.title-color-3 {
  color: var(--it-common-pink-4);
}

.blog-5__item-box-4 .blog-5__meta span {
  color: var(--it-common-green);
}

.blog-5__item-box-4:hover {
  background-color: var(--it-common-black-7);
  border: 1px dashed var(--it-common-green);
}

.blog-5__item-box-4:hover .blog-5__link a {
  color: var(--it-common-green);
}

.blog-5__item-box-4:hover .blog-5__title-sm.title-color-4 {
  color: var(--it-common-green);
}

@media (max-width: 767px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .postbox__wrapper {
    padding-right: 0;
    margin-bottom: 50px;
  }
}

.postbox__p-right {
  margin-right: 20px;
}

@media (max-width: 767px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 992px) and (max-width: 1199px) {
  .postbox__p-right {
    margin-right: 0;
  }
}

.postbox__thumb .play-btn {
  position: absolute;
  top: calc(50% - 20px);
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
  height: 90px;
  width: 90px;
  background-color: var(--it-common-white);
  text-align: center;
  line-height: 90px;
  color: var(--it-common-black);
  border-radius: 50%;
  animation: pulse 2s infinite;
}

@media (max-width: 767px) {
  .postbox__thumb .play-btn {
    height: 50px;
    width: 50px;
    line-height: 50px;
  }

  .postbox__thumb .play-btn i {
    padding-left: 4px;
  }
}

.postbox__thumb .play-btn:hover {
  background-color: var(--it-common-blue-4);
  color: var(--it-common-white);
}

.postbox__thumb img {
  border-radius: 20px;
  margin-bottom: 40px;
}

.postbox__audio {
  height: 100%;
  width: 100%;
}

.postbox__audio iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.postbox__item-single:hover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

.postbox__content p img {
  margin-bottom: 30px;
  max-width: 100%;
  border-radius: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .postbox__content p img {
    width: 100%;
  }
}

.postbox__content-single {
  padding-left: 0;
  padding-right: 0;
  border: none;
}

.postbox__title {
  color: var(--it-common-black);
  line-height: 1.3;
  font-weight: 700;
  font-size: 30px;
  color: #202124;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .postbox__title {
    font-size: 33px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .postbox__title {
    font-size: 28px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .postbox__title {
    font-size: 33px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .postbox__title {
    font-size: 30px;
  }

  .postbox__title br {
    display: none;
  }
}

@media (max-width: 767px) {
  .postbox__title {
    font-size: 25px;
  }

  .postbox__title br {
    display: none;
  }
}

.postbox__title a:hover {
  color: var(--it-common-blue-4);
}

.postbox__meta {
  margin-bottom: 20px;
}

.postbox__meta span {
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
  color: #888;
  display: inline-block;
  margin-right: 30px;
}

.postbox__meta span:first-child {
  padding: 8px 12px;
  border: 1px solid #f0f0f0;
  border-radius: 20px;
}

.postbox__meta span:last-child {
  margin-right: 0;
}

.postbox__meta span i {
  color: var(--it-theme-1);
  margin-right: 3px;
  font-weight: 500;
}

.postbox__meta span:hover {
  color: var(--it-common-blue);
}

.postbox__text {
  padding-right: 30px;
}

.postbox__text img {
  max-width: 100%;
}

.postbox__text p {
  font-size: 17px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .postbox__text p {
    font-size: 16px;
  }
}

.postbox__text-single p {
  margin-bottom: 15px;
}

.postbox__slider button {
  position: absolute;
  left: 50px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  font-size: 30px;
  color: var(--it-common-white);
}

.postbox__slider button.slick-next {
  left: auto;
  right: 50px;
}

@media (max-width: 767px) {
  .postbox__slider button.slick-next {
    right: 10px;
  }
}

@media (max-width: 767px) {
  .postbox__slider button {
    left: 10px;
  }
}

.postbox__comment ul li {
  margin-bottom: 30px;
  padding-bottom: 30px;
  list-style: none;
  border-bottom: 1px solid var(--it-border-1);
}

.postbox__comment ul li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.postbox__comment ul li.children {
  margin-left: 100px;
}

@media (max-width: 767px) {
  .postbox__comment ul li.children {
    margin-left: 0;
  }
}

.postbox__comment ul-title {
  font-size: 20px;
  font-weight: 500;
  color: var(--it-common-black);
  margin-bottom: 40px;
  margin-right: 20px;
}

.postbox__comment-title {
  font-weight: 600;
  font-size: 26px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #202124;
  margin-bottom: 40px;
}

.postbox__comment-avater img {
  width: 50px;
  height: 50px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

@media (max-width: 767px) {
  .postbox__comment-avater img {
    width: 65px;
    height: 65px;
  }
}

.postbox__comment-name {
  margin-bottom: 5px;
}

.postbox__comment-name h5 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--it-common-black);
  padding-right: 10px;
  margin-right: 10px;
  position: relative;
}

.postbox__comment-name h5::after {
  content: "";
  height: 4px;
  width: 4px;
  background-color: var(--it-common-black);
  display: inline-block;
  position: absolute;
  right: -1px;
  bottom: 4px;
}

.postbox__comment-name span {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #888;
}

@media (max-width: 767px) {
  .postbox__comment-text {
    margin-left: 0;
    margin-top: 15px;
  }
}

.postbox__comment-text p {
  font-size: 16px;
  margin-bottom: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .postbox__comment-text p {
    font-size: 15px;
  }

  .postbox__comment-text p br {
    display: none;
  }
}

@media (max-width: 767px) {
  .postbox__comment-text p br {
    display: none;
  }
}

.postbox__comment-reply {
  margin-top: 10px;
}

.postbox__comment-reply a {
  display: inline-block;
  height: 30px;
  line-height: 27px;
  padding: 0 18px;
  font-weight: 500;
  font-size: 13px;
  color: #202124;
  border: 1px solid #f0f0f0;
  border-radius: 20px;
  transition: 0.3s;
}

.postbox__comment-reply a:hover {
  color: var(--it-common-white);
  background: var(--it-common-blue-4);
  border-color: var(--it-common-blue-4);
}

.postbox__comment-reply a:hover svg {
  color: var(--it-common-white);
}

.postbox__comment-reply a svg {
  transform: translateY(-2px);
  margin-right: 6px;
  color: #202124;
  transition: 0.3s;
}

.postbox__comment-agree {
  margin-bottom: 30px;
}

.postbox__comment-agree .form-check-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.3;
  color: #87888a;
}

.postbox__comment-agree .form-check-input:checked {
  background-color: var(--it-common-blue-4);
  border-color: transparent;
}

.postbox__comment-agree .form-check-input:focus {
  box-shadow: none;
}

.postbox__comment-agree .form-check-input.form-check-input[type="checkbox"] {
  border-radius: 2px;
  margin-top: 4px;
}

.postbox__comment-input {
  position: relative;
  margin-bottom: 20px;
}

.postbox__comment-input span {
  font-weight: 600;
  color: var(--it-common-black);
  margin-bottom: 12px;
  display: block;
}

.postbox__comment-input input,
.postbox__comment-input textarea {
  height: 55px;
  padding: 0 20px;
  width: 100%;
  font-size: 14px;
  outline: none;
  border: 1px solid #f7f7f7;
  box-shadow: 0 16px 24px rgba(189, 196, 205, 0.13);
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -o-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
  color: var(--tp-common-black);
}

.postbox__comment-input input::placeholder,
.postbox__comment-input textarea::placeholder {
  color: rgba(116, 116, 116, 0.5);
}

.postbox__comment-input input:focus,
.postbox__comment-input textarea:focus {
  border-color: var(--tp-common-pink);
  color: var(--tp-common-black);
}

.postbox__comment-input textarea {
  height: 175px;
  resize: none;
  padding-top: 20px;
  padding-bottom: 20px;
}

.postbox__tag span {
  font-size: 20px;
  margin-bottom: 17px;
  color: var(--it-common-black);
  margin-right: 30px;
  font-weight: 500;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .postbox__social {
    margin-top: 40px;
  }
}

.postbox__social span {
  font-size: 20px;
  color: var(--it-common-black);
  font-weight: 500;
}

.postbox__social a {
  font-size: 15px;
  padding-left: 30px;
}

.postbox__social a .it-linkedin {
  color: #0e6ba1;
}

.postbox__social a .it-pinterest {
  color: #d70220;
}

.postbox__social a .it-facebook {
  color: #0e6ba1;
}

.postbox__social a .it-twitter {
  color: #36b6ed;
}

@media (max-width: 767px) {
  .postbox__navigation-more {
    flex-wrap: wrap;
  }
}

.postbox__navigation-img {
  margin-right: 15px;
}

.postbox__navigation-img img {
  border-radius: 12px;
}

@media (max-width: 767px) {
  .postbox__navigation-left {
    margin-bottom: 30px;
  }
}

.postbox__navigation-content a:hover span {
  color: var(--it-common-blue-4);
}

.postbox__navigation-content span {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #5f6168;
  padding-bottom: 15px;
  display: inline-block;
  transition: 0.3s;
}

.postbox__navigation-content span i {
  margin-right: 10px;
}

.postbox__navigation-content h5 {
  font-weight: 700;
  font-size: 18px;
  line-height: 16px;
  color: #202124;
  transition: 0.3s;
}

@media (max-width: 767px) {
  .postbox__navigation-content h5 {
    font-size: 16px;
  }
}

.postbox__navigation-content h5:hover {
  color: var(--it-common-blue-4);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .postbox__navigation-content h5 {
    font-size: 15px;
  }
}

.postbox__navigation-right .postbox__navigation-img {
  margin-right: 0;
  margin-left: 15px;
}

.postbox__navigation-right .postbox__navigation-content span i {
  margin-right: 0;
  margin-left: 10px;
}

.form-label-action.active .postbox__comment-input label {
  top: 0;
  color: var(--it-common-blue-3);
  font-size: 13px;
  font-weight: 400;
}

.form-label-action.active .postbox__comment-input span {
  top: -14px;
  color: var(--it-common-blue-3);
  font-size: 13px;
  font-weight: 400;
}

.blog-grid-bg {
  position: relative;
}

.blog-grid-bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(247, 249, 252, 0) 0%, #f7f9fc 100%);
  transform: matrix(1, 0, 0, -1, 0, 0);
  height: 50%;
  width: 100%;
  display: inline-block;
  z-index: -1;
}

.postbox__comment-form-title {
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 26px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: var(--it-common-black);
}

@media (max-width: 767px) {
  .postbox__comment-form-title {
    font-size: 25px;
  }
}

.postbox__social-wrapper {
  border-bottom: 1px solid #eceef3;
  padding-bottom: 40px;
  margin-bottom: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .postbox__comment-form {
    margin-bottom: 50px;
  }
}

.aproch-title {
  font-size: 36px;
  margin-bottom: 15px;
}

.approch-item {
  margin-bottom: 65px;
}

.approch-item ul li {
  position: relative;
  padding-left: 30px;
}

.approch-item ul li i {
  color: var(--it-theme-2);
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  top: 5px;
  left: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .approch-thumb {
    margin-bottom: 40px;
  }
}

.approch-thumb img {
  border-radius: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .approch-thumb img {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .postbox__comment-box {
    flex-wrap: wrap;
  }
}

.postbox__comment-info {
  flex: 0 0 auto;
}

.blog-grid-avata-box .it-blog-author-info h5 {
  font-weight: 700;
  font-size: 17px;
  line-height: 16px;
  color: #fff;
}

.blog-grid-avata-box .it-blog-author-info span {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.7);
}

.blog-details-title-box span {
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  color: #fff;
  padding: 8px 20px;
  border: 1.5px solid rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  margin-bottom: 10px;
  display: inline-block;
}

.blog-details-banner-title {
  font-weight: 700;
  font-size: 70px;
  line-height: 1.1;
  letter-spacing: -0.02em;
  color: #fff;
  padding-bottom: 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-details-banner-title {
    font-size: 57px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-banner-title {
    font-size: 50px;
  }
}

@media (max-width: 767px) {
  .blog-details-banner-title {
    font-size: 45px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-details-banner-title {
    font-size: 40px;
  }
}

.blog-details-banner .it-blog-author-info h5 {
  font-weight: 800;
  font-size: 17px;
  line-height: 16px;
  color: #fff;
}

.blog-details-banner .it-blog-author-info span {
  font-weight: 600;
  font-size: 13px;
  line-height: 10px;
  color: rgba(255, 255, 255, 0.7);
}

@media (max-width: 767px) {
  .blog-details-social-box {
    margin-top: 30px;
  }
}

.blog-details-social-box a {
  height: 42px;
  width: 42px;
  line-height: 40px;
  border-radius: 50%;
  border: 1.5px solid rgba(255, 255, 255, 0.2);
  border-radius: 100px;
  display: inline-block;
  text-align: center;
  color: var(--it-common-white);
  margin-left: 6px;
  transition: 0.3s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-social-box a {
    height: 35px;
    width: 35px;
    line-height: 33px;
    margin-left: 0;
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  .blog-details-social-box a {
    margin-left: 0;
    margin-right: 6px;
  }
}

.blog-details-social-box a:hover {
  background-color: var(--it-common-yellow-4);
  border-color: var(--it-common-yellow-4);
  color: var(--it-common-black);
}

.blog-details-big-img {
  margin-top: -200px;
  position: sticky;
  top: 100px;
}

.blog-details-big-img img {
  border-radius: 30px;
}

.postbox__details-checkmark {
  padding-bottom: 40px;
}

.postbox__details-checkmark ul li {
  list-style-type: none;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 17px;
  line-height: 1.2;
  color: #202124;
}

@media (max-width: 767px) {
  .postbox__details-checkmark ul li {
    font-size: 14px;
  }
}

.postbox__details-checkmark ul li::last-child {
  margin-bottom: 0;
}

.postbox__details-checkmark ul li i {
  height: 20px;
  width: 20px;
  background-color: var(--it-common-black);
  color: var(--it-common-white);
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  font-size: 10px;
  transform: translateX(2px);
  position: absolute;
  top: -2px;
  left: 0;
}

.postbox__details-title {
  font-weight: 700;
  font-size: 34px;
  line-height: 1.2;
  color: #080829;
  padding-bottom: 10px;
}

@media only screen and (min-width: 440px) and (max-width: 575px) {
  .postbox__details-title {
    font-size: 29px;
  }
}

.postbox__details-title-box p {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #5f6168;
}

.postbox__details-img-box {
  padding-bottom: 20px;
}

@media (max-width: 767px) {
  .postbox__details-img-box {
    flex-wrap: wrap;
  }
}

.postbox__details-img-box span img {
  border-radius: 20px;
  margin-bottom: 20px;
}

.postbox__details-img-box span img.img-mr {
  margin-right: 20px;
}

.postbox__details-img-caption {
  font-weight: 400;
  font-size: 13px;
  line-height: 10px;
  color: #87888a;
}

.postbox__details-img-caption i {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 10px;
  color: #5f6168;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .postbox__details-wrapper {
    padding-right: 0;
  }
}

.postbox__details-qoute {
  background: linear-gradient(90.21deg,
      #fafafa -0.62%,
      rgba(255, 255, 255, 0) 18.02%);
  padding: 40px 50px;
  border-left: 3px solid var(--it-common-blue-4);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .postbox__details-qoute {
    padding: 40px;
  }
}

@media (max-width: 767px) {
  .postbox__details-qoute {
    padding: 30px;
  }
}

.postbox__details-qoute blockquote {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .postbox__details-qoute blockquote {
    flex-wrap: wrap;
  }
}

.postbox__details-qoute-icon svg {
  transform: translateY(5px);
  margin-right: 30px;
}

@media (max-width: 767px) {
  .postbox__details-qoute-icon svg {
    margin-bottom: 20px;
  }
}

.postbox__details-qoute-text p {
  font-weight: 500;
  font-size: 23px;
  line-height: 34px;
  color: #202124;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .postbox__details-qoute-text p {
    font-size: 21px;
  }
}

@media (max-width: 767px) {
  .postbox__details-qoute-text p {
    font-size: 17px;
    line-height: 26px;
  }
}

.postbox__details-qoute-text span {
  font-weight: 500;
  font-size: 15px;
  line-height: 14px;
  color: var(--it-common-blue-4);
}

.postbox__details-qoute-text span::before {
  content: "";
  height: 1px;
  width: 16px;
  background-color: var(--it-common-blue-4);
  display: inline-block;
  transform: translateY(-4px);
  margin-right: 10px;
}

.postbox__details-author-info-box {
  background-color: #f8f8f8;
  border-radius: 14px;
  padding: 50px 45px;
}

@media (max-width: 767px) {
  .postbox__details-author-info-box {
    flex-wrap: wrap;
    padding: 40px 30px;
  }
}

.postbox__details-author-avata {
  flex: 0 0 auto;
  margin-right: 25px;
}

@media (max-width: 767px) {
  .postbox__details-author-avata {
    margin-bottom: 20px;
  }
}

.postbox__details-author-avata img {
  flex: 0 0 auto;
  border-radius: 50%;
}

.postbox__details-author-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  color: #202124;
}

.postbox__details-author-social a {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  text-align: center;
  line-height: 38px;
  box-shadow: 0 1px 1px rgba(32, 33, 36, 0.12);
  display: inline-block;
  font-size: 12px;
  margin-right: 6px;
  transition: 0.3s;
}

.postbox__details-author-social a:hover {
  background-color: var(--it-common-blue-4);
  color: var(--it-common-white);
}

.postbox__comment-input {
  position: relative;
}

.postbox__comment-input input:focus~.floating-label,
.postbox__comment-input input:not(:focus):valid~.floating-label {
  top: 0;
  left: 20px;
  font-size: 13px;
  font-weight: 400;
  color: var(--it-common-blue-3);
  background-color: var(--it-common-white);
  padding: 0 4px;
}

.postbox__comment-input textarea:focus~.floating-label-2,
.postbox__comment-input textarea:not(:focus):valid~.floating-label-2 {
  top: -9px;
  left: 20px;
  font-size: 13px;
  font-weight: 400;
  color: var(--it-common-blue-3);
  background-color: var(--it-common-white);
  padding: 0 4px;
}

.postbox__comment-input .eye-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}

.postbox__comment-input .eye-btn span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.postbox__comment-input .eye-btn .eye-open {
  opacity: 0;
}

.postbox__comment-input .eye-btn .eye-off {
  opacity: 1;
}

.postbox__comment-input .eye-btn.pass-btn-open .eye-open {
  opacity: 1;
}

.postbox__comment-input .eye-btn.pass-btn-open .eye-off {
  opacity: 0;
}

.postbox__comment-input .inputText {
  border-radius: 12px;
  padding: 0 20px;
  font-size: 14px;
  width: 100%;
  height: 55px;
  border: 0;
  outline: 0;
  font-weight: 500;
  font-size: 15px;
  color: #87888a;
  box-shadow: inset 0 0 0 1px #e5e5e5;
  transition: box-shadow 0.3s cubic-bezier(0.3, 0, 0, 0.3);
  color: var(--it-common-black);
}

.postbox__comment-input .inputText:focus {
  box-shadow: 0 1px 2px 1px rgba(32, 33, 36, 0.06),
    inset 0 0 0 2px var(--it-common-blue-3);
}

.postbox__comment-input .textareaText {
  border-radius: 12px;
  padding: 20px;
  font-size: 14px;
  width: 100%;
  height: 160px;
  resize: none;
  border: 0;
  outline: 0;
  font-weight: 500;
  font-size: 15px;
  color: #87888a;
  box-shadow: inset 0 0 0 1px #e5e5e5;
  transition: box-shadow 0.3s cubic-bezier(0.3, 0, 0, 0.3);
  color: var(--it-common-black);
}

.postbox__comment-input .textareaText:focus {
  box-shadow: 0 1px 2px 1px rgba(32, 33, 36, 0.06),
    inset 0 0 0 2px var(--it-common-blue-3);
}

.postbox__comment-input .floating-label {
  position: absolute;
  pointer-events: none;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.2s ease all;
}

.postbox__comment-input .floating-label-2 {
  position: absolute;
  pointer-events: none;
  left: 20px;
  top: 20px;
  transition: 0.2s ease all;
}

.rc__post ul li:not(:last-child) {
  margin-bottom: 15px;
}

.rc__post-thumb {
  flex: 0 0 auto;
}

.rc__post-thumb img {
  border-radius: 10px;
  width: 75px;
  height: 75px;
  flex: 0 0 auto;
  object-fit: cover;
}

.rc__post-title {
  margin-bottom: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--it-common-black);
}

.rc__post-title a:hover {
  color: var(--it-common-blue-4);
}

.rc__meta span {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  color: #6a6a74;
}

.sidebar__wrapper {
  padding-left: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .sidebar__wrapper {
    padding-left: 0;
  }
}

.sidebar__widge-title-box {
  padding: 30px;
  border-radius: 14px 14px 0 0;
}

.sidebar__widget-content {
  padding: 40px 30px;
  padding-top: 0;
}

@media (max-width: 767px) {
  .sidebar__widget-content {
    padding: 35px 25px;
  }
}

.sidebar__widget {
  border: 1px solid #e5e5e5;
  border-radius: 14px;
}

.sidebar__widget-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  color: var(--tp-common-black);
  margin-bottom: 0;
}

.sidebar__widget:last-child-title {
  border-bottom: 0;
}

.sidebar__widget ul li {
  list-style: none;
  margin-bottom: 25px;
}

.sidebar__widget ul li:last-child {
  margin-bottom: 0;
}

.sidebar__widget ul li a {
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
  color: #5f6168;
  display: flex;
  justify-content: space-between;
}

.sidebar__widget ul li a:hover {
  color: var(--it-common-blue-4);
}

.sidebar__widget ul li a i {
  font-size: 18px;
  transform: translateY(2px);
  margin-right: 10px;
}

.sidebar__search {
  position: relative;
}

.sidebar__search input {
  width: 100%;
  height: 60px;
  line-height: 60px;
  padding: 0 25px;
  text-transform: capitalize;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -o-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
  border: 0;
  outline: none;
  padding-right: 60px;
  border: 1px solid #e5e5e5;
  border-radius: 14px;
}

@media (max-width: 767px) {
  .sidebar__search input {
    padding-right: 40px;
    padding-left: 15px;
  }
}

.sidebar__search input:focus {
  border-color: var(--tp-common-blue-4);
}

.sidebar__search input::placeholder {
  color: #777;
}

.sidebar__search button {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 0 24px;
  color: #fff;
  line-height: 60px;
  -webkit-border-radius: 0 7px 7px 0;
  -moz-border-radius: 0 7px 7px 0;
  -o-border-radius: 0 7px 7px 0;
  -ms-border-radius: 0 7px 7px 0;
  border-radius: 0 7px 7px 0;
  color: var(--tp-common-black);
}

.sidebar__banner-content {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  background: var(--tp-theme-1);
}

.sidebar__banner-content h4 {
  padding: 15px 20px;
  font-size: 24px;
  color: var(--tp-common-white);
  text-transform: uppercase;
  margin-bottom: 0;
}

.tagcloud a {
  border: 1px solid #f2f4f6;
  color: #8a879f;
  display: inline-block;
  line-height: 1;
  padding: 10px 25px;
  margin-bottom: 8px;
  margin-right: 8px;
  border-radius: 30px;
  transition: 0.3s;
  font-weight: 500;
  font-size: 14px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tagcloud a {
    padding: 8px 15px;
    font-size: 13px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tagcloud a {
    padding: 10px 15px;
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  .tagcloud a {
    padding: 10px 15px;
    font-size: 13px;
  }
}

.tagcloud a:hover {
  background: var(--it-common-blue-4);
  color: var(--it-common-white);
  border-color: var(--it-common-blue-4);
}

.tagcloud span {
  font-weight: 600;
  font-size: 16px;
  line-height: 12px;
  color: #202124;
  margin-right: 8px;
}

.basic-pagination ul {
  border: 1px solid #ebebeb;
  border-radius: 100px;
  display: inline-block;
  padding: 10px;
}

.basic-pagination ul li {
  display: inline-block;
}

.basic-pagination ul li a {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 600;
  transition: 0.3s;
}

.basic-pagination ul li span {
  display: inline-block;
  transform: translateY(-4px);
}

.basic-pagination ul li a.current {
  background: #f2f2f2;
  color: var(--it-common-black);
}

.basic-pagination ul li a:hover {
  background: #f2f2f2;
  border-color: #f2f2f2;
  color: var(--it-common-black);
}

.blog-grid-avata-box .tp-blog-author-info h5 {
  font-weight: 700;
  font-size: 17px;
  line-height: 16px;
  color: #fff;
}

.blog-grid-avata-box .tp-blog-author-info span {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.7);
}

.postbox__contact-input input {
  border: none;
  background-color: #f7f9fa;
  width: 100%;
  height: 45px;
  padding: 25px;
  border-radius: 5px;
  font-weight: 400;
  font-size: 17px;
  margin-bottom: 30px;
  border: 1px solid transparent;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .postbox__contact-input input {
    font-size: 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .postbox__contact-input input {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .postbox__contact-input input {
    font-size: 14px;
  }
}

.postbox__contact-input input:focus {
  border-color: var(--it-theme-1);
}

.postbox__contact-textarea textarea {
  font-weight: 400;
  font-size: 17px;
  width: 100%;
  resize: none;
  border: none;
  height: 160px;
  display: inline-block;
  padding: 20px;
  background-color: #f7f9fa;
  border: 1px solid transparent;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .postbox__contact-textarea textarea {
    padding-right: 45px;
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .postbox__contact-textarea textarea {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .postbox__contact-textarea textarea {
    font-size: 14px;
  }
}

.postbox__contact-textarea textarea:focus {
  border-color: var(--it-theme-1);
}

.footer__plr {
  padding: 0 200px;
  padding-top: 250px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .footer__plr {
    padding: 0 100px;
    padding-top: 250px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .footer__plr {
    padding: 0 55px;
    padding-top: 250px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__plr {
    padding: 0 30px;
    padding-top: 250px;
  }
}

@media (max-width: 767px) {
  .footer__plr {
    padding: 0;
    padding-top: 250px;
  }
}

.footer__plr-3 {
  padding-top: 100px;
}

.footer__logo {
  padding-bottom: 35px;
}

.footer__text p {
  color: var(--it-common-white);
  padding-right: 30px;
}

.footer__number-icon {
  padding-right: 20px;
}

.footer__number-icon span {
  font-size: 30px;
  color: var(--it-theme-1);
}

.footer__number-info span {
  display: block;
  color: var(--it-common-white);
  padding-bottom: 5px;
}

.footer__number-info a {
  color: var(--it-common-white);
  transition: 0.3s;
}

.footer__number-info a:hover {
  color: var(--it-theme-1);
}

.footer__title {
  font-weight: 700;
  font-size: 22px;
  color: var(--it-common-white);
  padding-bottom: 30px;
  padding-top: 20px;
}

.footer__menu-list ul li {
  list-style: none;
  padding-bottom: 10px;
}

.footer__menu-list ul li a {
  color: var(--it-common-white);
  font-weight: 400;
  font-size: 16px;
  transition: 0.3s;
}

.footer__menu-list ul li a:hover {
  color: var(--it-theme-1);
}

.footer__content p {
  color: var(--it-common-white);
  font-weight: 400;
}

.footer__input {
  position: relative;
  margin-bottom: 30px;
}

.footer__input form input {
  background-color: var(--it-common-white);
  border: 0;
  height: 40px;
  width: 100%;
  padding-left: 40px;
  border-radius: 30px;
  padding-right: 150px;
}

@media (max-width: 767px) {
  .footer__input form input {
    padding-left: 25px;
  }
}

.footer__input form input::placeholder {
  font-size: 14px;
  color: #333;
}

.footer__input button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 4px;
}

.footer__social-box span {
  font-family: poppins, sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: var(--it-common-white);
  padding-right: 5px;
}

.footer__social-box a {
  height: 33px;
  width: 33px;
  border-radius: 50%;
  display: inline-block;
  line-height: 33px;
  text-align: center;
  margin-right: 5px;
  transition: 0.3s;
  border: 1px solid #a1a1a2;
}

.footer__social-box a i {
  color: #a1a1a2;
  transition: 0.3s;
}

.footer__social-box a:hover {
  border: 1px solid var(--it-theme-1);
  background-color: var(--it-theme-1);
}

.footer__social-box a:hover i {
  color: var(--it-common-black);
}

.footer-2__plr {
  padding: 0 250px;
  padding-top: 180px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .footer-2__plr {
    padding: 0 100px;
    padding-top: 180px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .footer-2__plr {
    padding: 0 55px;
    padding-top: 180px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-2__plr {
    padding: 0 30px;
    padding-top: 180px;
  }
}

@media (max-width: 767px) {
  .footer-2__plr {
    padding: 0;
    padding-top: 180px;
  }
}

.footer-2__shape-1 {
  position: absolute;
  top: 5%;
  left: 43%;
  animation: tptranslateX2 4s infinite alternate;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .footer-2__shape-1 {
    top: 2%;
    left: 40%;
  }
}

.footer-2__shape-2 {
  position: absolute;
  top: 16%;
  right: 3%;
  animation: moving 9s linear infinite;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .footer-2__shape-2 {
    top: 20%;
    right: -2%;
  }
}

.footer-2__logo {
  padding-bottom: 35px;
}

.footer-2__text p {
  color: var(--it-common-white);
  padding-right: 45px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .footer-2__text p {
    padding-right: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-2__text p {
    padding-right: 163px;
  }
}

.footer-2__title {
  font-weight: 700;
  font-size: 22px;
  color: var(--it-common-white);
  padding-bottom: 30px;
  padding-top: 20px;
}

.footer-2__menu-list ul li {
  list-style: none;
  padding-bottom: 10px;
}

.footer-2__menu-list ul li a {
  color: var(--it-common-white);
  font-weight: 400;
  font-size: 16px;
  transition: 0.3s;
}

.footer-2__menu-list ul li a:hover {
  color: var(--it-theme-1);
}

.footer-2__content p {
  color: var(--it-common-white);
  font-weight: 400;
}

.footer-2__social-box span {
  font-family: poppins, sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: var(--it-common-white);
  padding-bottom: 12px;
  display: inline-block;
}

.footer-2__social-box a {
  height: 33px;
  width: 33px;
  border-radius: 50%;
  border: 1px solid #a1a1a2;
  display: inline-block;
  line-height: 33px;
  text-align: center;
  margin-right: 5px;
  transition: 0.3s;
}

.footer-2__social-box a i {
  color: #a1a1a2;
  transition: 0.3s;
}

.footer-2__social-box a:hover {
  background-color: var(--it-theme-1);
  border: 1px solid transparent;
}

.footer-2__social-box a:hover i {
  color: var(--it-common-black);
}

.footer-2__content-img-number {
  position: absolute;
  right: -10px;
  top: 8px;
}

.footer-2__content-img-number span {
  font-weight: 400;
  font-size: 12px;
  height: 24px;
  width: 24px;
  background-color: var(--it-theme-1);
  border-radius: 50%;
  line-height: 24px;
  display: inline-block;
  text-align: center;
}

.footer-2__content-text span {
  font-weight: 400;
  font-size: 14px;
  color: var(--it-common-white);
  padding-bottom: 10px;
  display: inline-block;
}

.footer-2__content-text h4 {
  font-weight: 600;
  font-size: 17px;
  color: var(--it-common-white);
  margin-bottom: 0;
  line-height: 1.2;
  transition: 0.3s;
}

.footer-2__content-text h4:hover {
  color: var(--it-theme-1);
}

.footer-3__plr {
  padding: 0 200px;
  padding-top: 250px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .footer-3__plr {
    padding: 0 100px;
    padding-top: 250px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .footer-3__plr {
    padding: 0 55px;
    padding-top: 250px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-3__plr {
    padding: 0 30px;
    padding-top: 250px;
  }
}

@media (max-width: 767px) {
  .footer-3__plr {
    padding: 0;
    padding-top: 250px;
  }
}

.footer-3__logo {
  padding-bottom: 35px;
}

.footer-3__text {
  padding-bottom: 26px;
}

.footer-3__text p {
  color: var(--it-common-white);
  padding-right: 45px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .footer-3__text p {
    padding-right: 24px;
  }
}

@media (max-width: 767px) {
  .footer-3__text p {
    padding-right: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-3__text p {
    padding-right: 166px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-3__text p {
    padding-right: 55px;
  }
}

.footer-3__social-box a {
  height: 33px;
  width: 33px;
  border: 1px solid var(--it-theme-1);
  display: inline-block;
  line-height: 33px;
  text-align: center;
  margin-right: 5px;
  transition: 0.3s;
  border-radius: 5px;
}

.footer-3__social-box a i {
  color: var(--it-theme-1);
  transition: 0.3s;
}

.footer-3__social-box a:hover {
  background-color: var(--it-theme-1);
  border: 1px solid transparent;
}

.footer-3__social-box a:hover i {
  color: var(--it-common-black);
}

.footer-3__title {
  font-weight: 700;
  font-size: 22px;
  color: var(--it-common-white);
  padding-bottom: 30px;
  padding-top: 20px;
}

.footer-3__menu-list ul {
  display: inline-block;
}

.footer-3__menu-list ul li {
  list-style: none;
  padding-bottom: 10px;
  width: 50%;
  float: left;
}

.footer-3__menu-list ul li a {
  color: var(--it-common-white);
  font-weight: 400;
  font-size: 16px;
  transition: 0.3s;
}

.footer-3__menu-list ul li a i {
  padding-right: 10px;
}

.footer-3__menu-list ul li a:hover {
  color: var(--it-theme-1);
}

.footer-3__content-text h4 {
  font-weight: 600;
  font-size: 14px;
  color: var(--it-common-white);
  margin-bottom: 0;
  line-height: 1.4;
  transition: 0.3s;
}

.footer-3__content-text h4:hover {
  color: var(--it-theme-1);
}

@media (max-width: 767px) {
  .footer-3__content-text h4 br {
    display: none;
  }
}

.footer-3__meta {
  padding-top: 5px;
}

.footer-3__meta span {
  font-weight: 400;
  font-size: 14px;
  color: var(--it-common-white);
  padding-bottom: 10px;
  display: inline-block;
}

.footer-3__meta i {
  color: var(--it-theme-1);
  padding-right: 5px;
}

.footer-3__text-2 {
  padding-bottom: 15px;
}

.footer-3__text-2 p {
  color: var(--it-common-white);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-3__text-2 p {
    padding-right: 92px;
  }
}

.footer-3__input {
  position: relative;
  margin-bottom: 30px;
}

.footer-3__input form input {
  background-color: var(--it-common-white);
  border: 0;
  height: 50px;
  width: 100%;
  padding-left: 20px;
  border-radius: 5px;
  padding-right: 84px;
}

.footer-3__input form input::placeholder {
  font-size: 14px;
  color: #333;
}

.footer-3__input button {
  position: absolute;
  top: 0;
  right: -1px;
}

.footer-3__plr-2 {
  padding: 0 200px;
  padding-top: 70px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .footer-3__plr-2 {
    padding: 0 100px;
    padding-top: 70px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .footer-3__plr-2 {
    padding: 0 55px;
    padding-top: 70px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-3__plr-2 {
    padding: 0 30px;
    padding-top: 70px;
  }
}

@media (max-width: 767px) {
  .footer-3__plr-2 {
    padding: 0;
    padding-top: 70px;
  }
}

.footer-3-col-2 {
  padding-left: 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .footer-3-col-2 {
    padding-left: 0;
  }
}

.footer-3-col-4 {
  padding-left: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .footer-3-col-4 {
    padding-left: 0;
  }
}

.footer-2-col-2 {
  padding-left: 85px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .footer-2-col-2 {
    padding-left: 0;
  }
}

.footer-2-col-3 {
  padding-left: 60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .footer-2-col-3 {
    padding-left: 19px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .footer-2-col-3 {
    padding-left: 0;
  }
}

.footer-2-col-4 {
  padding-left: 56px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .footer-2-col-4 {
    padding-left: 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .footer-2-col-4 {
    padding-left: 85px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .footer-2-col-4 {
    padding-left: 0;
  }
}

.footer-col-2 {
  padding-left: 80px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .footer-col-2 {
    padding-left: 0;
  }
}

.footer-col-3 {
  padding-left: 40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .footer-col-3 {
    padding-left: 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .footer-col-4 {
    padding-left: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
(max-width: 767px) {
  .footer-col-4 {
    padding-left: 0;
  }
}

.copyright__plr {
  padding: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .copyright__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .copyright__plr {
    padding: 0 55px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .copyright__plr {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .copyright__plr {
    padding: 0;
  }
}

.copyright__plr-2 {
  padding: 0 250px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .copyright__plr-2 {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .copyright__plr-2 {
    padding: 0 55px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .copyright__plr-2 {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .copyright__plr-2 {
    padding: 0;
  }
}

@media (max-width: 767px) {
  .copyright__text {
    padding-top: 20px;
  }
}

.copyright__text span {
  font-weight: 400;
  font-size: 16px;
  color: var(--it-common-white);
}

@media (max-width: 767px) {
  .copyright__text span {
    font-size: 15px;
  }
}

.copyright__item span {
  font-weight: 400;
  font-size: 16px;
  color: var(--it-common-white);
  display: inline-block;
  padding: 15px 0;
  margin-left: 30px;
  transition: 0.3s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .copyright__item span {
    margin-left: 10px;
  }
}

@media (max-width: 767px) {
  .copyright__item span {
    margin-left: 0;
    margin-right: 13px;
    font-size: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .copyright__item span {
    margin-right: 0;
    margin-right: 30px;
  }
}

.copyright__item span:hover {
  color: var(--it-theme-1);
}

.copyright__border {
  border-top: 1px solid var(--it-border-2);
}

@media (max-width: 767px) {
  .footer-3__content {
    margin-bottom: 20px;
  }
}

.video__plr {
  padding: 130px 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 767px) {
  .video__plr {
    padding: 130px 0;
  }
}

.video__content p {
  color: var(--it-common-white);
  padding-right: 80px;
  padding-bottom: 13px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .video__content p {
    padding-right: 0;
  }

  .video__content p br {
    display: none;
  }
}

@media only screen and (min-width: 440px) and (max-width: 575px) {
  .video__content p br {
    display: none;
  }
}

@media (max-width: 767px) {
  .video__content p {
    padding-right: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .video__icon {
    margin-right: 0;
  }
}

.video__icon a {
  font-size: 18px;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: var(--it-common-white);
  line-height: 63px;
  padding-left: 6px;
  display: inline-block;
  text-align: center;
  color: #404040;
  position: relative;
  z-index: 1;
}

.video__icon a::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.551);
  animation: borderanimate2 2s linear infinite;
  z-index: -1;
}

@media (max-width: 767px) {
  .video__button {
    margin-bottom: 50px;
  }
}

.video-2__bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  margin: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .video-2__bg {
    margin: 0 115px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .video-2__bg {
    margin: 0 115px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .video-2__bg {
    margin: 0 45px;
  }
}

@media (max-width: 767px) {
  .video-2__bg {
    margin: 0;
  }
}

.video-2__space {
  padding: 100px 0;
  margin-bottom: -250px;
  position: relative;
  z-index: 9;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-2__space {
    padding: 65px 0;
  }
}

.video-2__icon span {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: 3px solid var(--it-common-white);
  line-height: 58px;
  text-align: center;
  display: inline-block;
}

.video-2__icon span i {
  font-size: 17px;
  color: var(--it-common-white);
  padding-left: 4px;
}

.video-2__btn {
  margin-right: 45px;
}

@media (max-width: 767px) {
  .video-2__btn {
    margin-right: 20px;
  }
}

.funfact__space {
  padding: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .funfact__space {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .funfact__space {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .funfact__space {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .funfact__space {
    padding: 0;
  }
}

.funfact__bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 125px 80px;
  position: relative;
  border-radius: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .funfact__bg {
    padding: 70px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .funfact__bg {
    padding: 60px 30px;
  }
}

@media (max-width: 767px) {
  .funfact__bg {
    padding: 90px 20px;
  }
}

.funfact__shape-1 {
  position: absolute;
  bottom: 0;
  right: 20%;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .funfact__shape-1 {
    right: 5%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .funfact__shape-1 {
    right: 3%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .funfact__shape-1 {
    right: -12%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .funfact__shape-1 img {
    max-width: 75%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .funfact__shape-1 img {
    max-width: 73%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .funfact__shape-1 img {
    max-width: 60%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .funfact__shape-1 img {
    max-width: 60%;
  }
}

@media (max-width: 767px) {
  .funfact__content {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .funfact__content {
    margin-bottom: 50px;
  }
}

.funfact__text {
  padding-bottom: 40px;
}

.funfact__icon a {
  height: 65px;
  width: 65px;
  line-height: 68px;
  border-radius: 50%;
  background-color: var(--it-theme-1);
  display: inline-block;
  text-align: center;
  position: relative;
  z-index: 2;
}

.funfact__icon a::after {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: rgba(243, 215, 111, 0.588);
  position: absolute;
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  transform: scale(1.3);
  animation: borderanimate2 2s linear infinite;
}

.funfact__icon a span {
  font-size: 20px;
  color: var(--it-common-black);
  padding-left: 9px;
}

.funfact-2__space {
  padding: 0 200px;
  margin-top: 110px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .funfact-2__space {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .funfact-2__space {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .funfact-2__space {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .funfact-2__space {
    padding: 0;
  }
}

.funfact-2__bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 34px 80px;
  position: relative;
  border-radius: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .funfact-2__bg {
    padding: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .funfact-2__bg {
    padding: 40px 0;
  }
}

@media (max-width: 767px) {
  .funfact-2__bg {
    padding: 34px 0;
  }
}

@media (max-width: 767px) {
  .funfact-2__content {
    margin-bottom: 50px;
  }
}

.funfact-2__text {
  padding-bottom: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .funfact-2__text .section-title {
    font-size: 34px;
  }
}

.funfact-2__main-img {
  padding-left: 20px;
}

@media (max-width: 767px) {
  .funfact-2__main-img {
    padding-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .funfact-2__button .border-btn-3 {
    height: 40px;
    line-height: 40px;
    padding: 0 15px;
  }
}

.contact__plr {
  padding: 0 30px;
  padding-top: 120px;
  padding-bottom: 100px;
}

@media (max-width: 767px) {
  .contact__plr {
    padding: 0;
    padding-top: 120px;
  }
}

.contact__mlr {
  margin: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .contact__mlr {
    margin: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .contact__mlr {
    margin: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .contact__mlr {
    margin: 0 30px;
  }
}

@media (max-width: 767px) {
  .contact__mlr {
    margin: 0;
  }
}

.contact__shape-1 {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  animation: tptranslateX2 4s infinite alternate;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact__shape-1 {
    top: -4%;
  }
}

.contact__shape-2 {
  position: absolute;
  bottom: -4%;
  left: 0%;
  animation: jumpTwo 4s infinite linear;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .contact__shape-2 {
    bottom: -4%;
    left: -2%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact__shape-2 {
    bottom: -1%;
    left: -4%;
  }
}

.contact__shape-3 {
  position: absolute;
  bottom: 6%;
  right: 0%;
  animation: tpupdown 0.8s infinite alternate;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact__shape-3 {
    bottom: 2%;
    right: -2%;
  }
}

.contact__shape-4 {
  position: absolute;
  bottom: 0;
  right: 54%;
  animation: moving 9s linear infinite;
}

.contact__text {
  padding-bottom: 30px;
}

.contact__text p {
  padding-right: 235px;
  font-weight: 400;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .contact__text p {
    padding-right: 130px;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .contact__text p {
    padding-right: 130px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .contact__text p {
    padding-right: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact__text p {
    padding-right: 45px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact__text p {
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .contact__text p {
    padding-right: 0;
    font-size: 15px;
  }
}

.contact__text.text-color p {
  color: var(--it-common-white-2);
  padding-right: 312px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .contact__text.text-color p {
    padding-right: 280px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .contact__text.text-color p {
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .contact__text.text-color p {
    padding-right: 0;
  }
}

.contact__text-2 {
  padding-bottom: 10px;
}

.contact__text-2 p {
  padding-right: 235px;
  font-weight: 400;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .contact__text-2 p {
    padding-right: 200px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .contact__text-2 p {
    padding-right: 120px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .contact__text-2 p {
    padding-right: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact__text-2 p {
    padding-right: 45px;
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact__text-2 p {
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .contact__text-2 p {
    padding-right: 0;
    font-size: 15px;
  }
}

.contact__form {
  margin-right: 70px;
  margin-bottom: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .contact__form {
    margin-right: 0;
  }
}

.contact__form-input input {
  border: none;
  background-color: #f7f9fa;
  width: 100%;
  height: 45px;
  padding: 25px;
  border-radius: 5px;
  font-weight: 400;
  font-size: 17px;
  margin-bottom: 30px;
  border: 1px solid transparent;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact__form-input input {
    font-size: 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact__form-input input {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .contact__form-input input {
    font-size: 14px;
  }
}

.contact__form-input input:focus {
  border-color: var(--it-theme-1);
}

.contact__form-input textarea {
  font-weight: 400;
  font-size: 17px;
  width: 100%;
  resize: none;
  border: none;
  height: 160px;
  display: inline-block;
  padding: 20px;
  background-color: #f7f9fa;
  border: 1px solid transparent;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact__form-input textarea {
    padding-right: 45px;
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact__form-input textarea {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .contact__form-input textarea {
    font-size: 14px;
  }
}

.contact__form-input textarea:focus {
  border-color: var(--it-theme-1);
}

.contact__item {
  border: 1px solid var(--it-theme-1);
  border-radius: 10px;
  padding: 27px 35px;
  transition: 0.3s;
  margin-bottom: 30px;
  background-color: transparent;
}

@media (max-width: 767px) {
  .contact__item {
    padding: 27px 20px;
  }
}

.contact__item:hover {
  background-color: var(--it-theme-1);
}

.contact__item:hover .contact__icon span {
  background-color: var(--it-common-white);
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .contact__button {
    margin-bottom: 50px;
  }
}

.contact__icon {
  margin-right: 30px;
}

@media (max-width: 767px) {
  .contact__icon {
    margin-right: 22px;
  }
}

.contact__icon span {
  font-size: 45px;
  color: var(--it-common-black);
  height: 85px;
  width: 85px;
  line-height: 85px;
  border-radius: 50%;
  background-color: var(--it-theme-1);
  text-align: center;
  display: inline-block;
  transition: 0.3s;
}

.contact__title-sm {
  font-size: 22px;
  font-weight: 700;
  padding-bottom: 3px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact__title-sm {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .contact__title-sm {
    font-size: 18px;
  }
}

.contact__title-sm.title-color-1 {
  color: var(--it-common-white);
  transition: 0.3s;
}

.contact__title-sm.title-color-1:hover {
  color: var(--it-common-blue);
}

.contact__title-sm.title-color-2 {
  color: var(--it-common-white);
  transition: 0.3s;
}

.contact__title-sm.title-color-2:hover {
  color: var(--it-theme-1);
}

.contact__title-sm.title-color-3 {
  color: var(--it-common-white);
  transition: 0.3s;
}

.contact__title-sm.title-color-3:hover {
  color: var(--it-common-red);
}

.contact__content span {
  font-weight: 400;
  font-size: 17px;
  color: var(--it-common-black);
}

@media (max-width: 767px) {
  .contact__content span {
    font-size: 14px;
  }
}

.contact__form-2 {
  margin-bottom: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .contact__form-2 {
    margin-right: 0;
  }
}

.contact__from-2-input input {
  border: none;
  background-color: #f7f9fa;
  width: 100%;
  height: 45px;
  padding: 25px;
  border-radius: 5px;
  font-weight: 400;
  font-size: 17px;
  margin-bottom: 30px;
  border: 1px solid transparent;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact__from-2-input input {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact__from-2-input input {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .contact__from-2-input input {
    font-size: 13px;
  }
}

.contact__from-2-input input:focus {
  border-color: var(--it-theme-1);
}

.contact__from-2-input textarea {
  font-weight: 400;
  font-size: 17px;
  width: 100%;
  resize: none;
  border: none;
  height: 160px;
  display: inline-block;
  padding: 20px;
  background-color: #f7f9fa;
  border: 1px solid transparent;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact__from-2-input textarea {
    padding-right: 45px;
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact__from-2-input textarea {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .contact__from-2-input textarea {
    font-size: 13px;
  }
}

.contact__from-2-input textarea:focus {
  border-color: var(--it-theme-1);
}

.contact__bg {
  padding: 115px 120px;
  border-radius: 10px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .contact__bg {
    padding: 115px 60px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .contact__bg {
    padding: 115px 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .contact__bg {
    padding: 115px 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact__bg {
    padding: 115px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact__bg {
    padding: 115px 20px;
  }
}

@media (max-width: 767px) {
  .contact__bg {
    padding: 115px 0;
  }
}

.contact__plr-2 {
  padding: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .contact__plr-2 {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .contact__plr-2 {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .contact__plr-2 {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .contact__plr-2 {
    padding: 0;
  }
}

.contact__form-3 .contact__form-input input {
  background-color: var(--it-common-black-6);
  border: 1px solid var(--it-border-2);
  color: var(--it-common-white);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact__form-3 .contact__form-input input {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .contact__form-3 .contact__form-input input {
    font-size: 14px;
  }
}

.contact__form-3 .contact__form-input input::placeholder {
  color: var(--it-common-white-2);
}

.contact__form-3 .contact__form-input input:focus {
  border-color: var(--it-theme-1);
}

.contact__form-3 .contact__form-input textarea {
  background-color: var(--it-common-black-6);
  border: 1px solid var(--it-border-2);
  color: var(--it-common-white);
  border-radius: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact__form-3 .contact__form-input textarea {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .contact__form-3 .contact__form-input textarea {
    font-size: 14px;
  }
}

.contact__form-3 .contact__form-input textarea::placeholder {
  color: var(--it-common-white-2);
}

.contact__form-3 .contact__form-input textarea:focus {
  border-color: var(--it-theme-1);
}

.contact__wrapper {
  background-color: var(--it-common-black-5);
  border-radius: 20px;
  padding: 10px;
}

.contact__item-box {
  border-radius: 20px;
  border: 1px dashed var(--it-border-2);
}

.contact__icon-color-1 span {
  background-color: var(--it-common-blue);
}

.contact__icon-color-2 span {
  background-color: var(--it-theme-1);
}

.contact__icon-color-3 span {
  background-color: var(--it-common-pink-2);
}

.contact__shape-5 {
  position: absolute;
  bottom: 81%;
  right: 41%;
  animation: moving 9s linear infinite;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .contact__shape-5 {
    bottom: 81%;
    right: 41%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact__right-side {
    margin-bottom: 90px;
  }
}

.contact__main-img {
  margin-left: 89px;
}

.contact__main-img img {
  max-width: 100%;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .contact__main-img {
    margin-left: 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .contact__main-img {
    margin-left: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact__main-img {
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .contact__main-img {
    margin-left: 0;
  }
}

.contact__border {
  border: 1px solid var(--it-theme-1);
  padding: 100px;
  border-radius: 10px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .contact__border {
    padding: 100px 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .contact__border {
    padding: 60px 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact__border {
    padding: 60px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact__border {
    padding: 70px 20px;
  }
}

@media (max-width: 767px) {
  .contact__border {
    padding: 30px 15px;
  }
}

.contact__border .contact__item {
  padding: 27px 21px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact__border .contact__title-sm {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .contact__border .contact__text p {
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  .contact__border .contact__icon span {
    font-size: 34px;
    height: 65px;
    width: 65px;
    line-height: 65px;
  }
}

.contact__box-2 .contact__item {
  border: none;
  padding: 27px 26px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact__box-2 .contact__item {
    margin-bottom: 15px;
  }
}

.contact__box-2 .contact__item:hover {
  background-color: unset;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .contact__box-2 .contact__icon {
    margin-right: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact__box-2 .contact__icon {
    margin-right: 20px;
  }
}

.contact__box-2 .contact__icon span {
  font-size: 35px;
  height: 70px;
  width: 70px;
  line-height: 70px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .contact__box-2 .contact__content .contact__title-sm {
    font-size: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact__box-2 .contact__content .contact__title-sm {
    font-size: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact__box-2 .contact__content span {
    font-size: 16px;
  }
}

.Register__box {
  background-color: #f8f9fe;
  padding: 50px 45px;
}

@media (max-width: 767px) {
  .Register__box {
    padding: 40px 20px;
  }
}

.Register__title {
  font-weight: 600;
  font-size: 17px;
  padding-bottom: 25px;
}

.Register__form input {
  width: 100%;
  border: 1px solid var(--it-border-1);
  background-color: var(--it-common-white);
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 18px 40px;
  transition: 0.3s;
}

@media (max-width: 767px) {
  .Register__form input {
    margin-bottom: 20px;
    padding: 12px 20px;
    font-size: 14px;
  }
}

.Register__form input:focus {
  border-color: var(--it-theme-1);
}

@media (max-width: 767px) {
  .Register__checkbox {
    margin-bottom: 20px;
  }
}

.Register__checkbox input {
  width: 4%;
}

.Register__checkbox.login input {
  width: 20px;
  height: 20px;
  margin-bottom: 0;
  margin-right: 13px;
}

@media (max-width: 767px) {
  .Register__checkbox.login input {
    width: 13px;
    height: 13px;
  }
}

@media (max-width: 767px) {
  .Register__checkbox.login label {
    font-size: 13px;
  }
}

.Register__forgot a {
  color: var(--it-common-blue-3);
}

@media (max-width: 767px) {
  .Register__forgot a {
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  .Register__box-2 {
    flex-wrap: wrap;
  }
}

.contact__wrapper .contact__item:hover .contact__icon-color-1 span {
  background-color: var(--it-common-blue);
}

.contact__wrapper .contact__item:hover .contact__icon-color-2 span {
  background-color: var(--it-theme-1);
}

.contact__wrapper .contact__item:hover .contact__icon-color-3 span {
  background-color: var(--it-common-red);
}

.newsletter__plr {
  padding: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .newsletter__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .newsletter__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .newsletter__plr {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .newsletter__plr {
    padding: 0;
  }
}

.newsletter__bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 492px;
  padding-top: 300px;
  margin-top: -210px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .newsletter__bg {
    padding-top: 240px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .newsletter__bg {
    padding-top: 240px;
    margin-top: -180px;
  }
}

@media (max-width: 767px) {
  .newsletter__bg {
    padding-top: 216px;
    margin-top: -180px;
  }
}

.newsletter__icon {
  padding-right: 45px;
  flex: 0 0 auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .newsletter__icon {
    padding-right: 35px;
  }
}

@media (max-width: 767px) {
  .newsletter__icon {
    padding-right: 15px;
  }
}

.newsletter__input-box {
  position: relative;
  margin-left: 120px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px),
only screen and (min-width: 1200px) and (max-width: 1399px) {
  .newsletter__input-box {
    margin-left: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .newsletter__input-box {
    margin-left: 0;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .newsletter__input-box {
    margin-left: 0;
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .newsletter__input-box {
    margin-left: 0;
    margin-top: 20px;
  }
}

.newsletter__input-box input {
  height: 80px;
  line-height: 80px;
  width: 100%;
  background-color: var(--it-common-white);
  border-radius: 60px;
  border: none;
  box-shadow: 0 0 70px 0 rgba(0, 80, 159, 0.06);
  padding-left: 40px;
  padding-right: 220px;
}

@media (max-width: 767px) {
  .newsletter__input-box input {
    height: 40px;
    line-height: 40px;
    padding-right: 140px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .newsletter__input-box input {
    height: 65px;
    line-height: 65px;
  }
}

.newsletter__input-box button {
  position: absolute;
  top: 0;
  right: 0;
}

@media (max-width: 767px) {
  .newsletter__input-box button.subscribe-btn-2 {
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .newsletter__input-box button.subscribe-btn-2 {
    height: 65px;
    line-height: 65px;
    padding: 0 40px;
  }

  .newsletter__input-box button.subscribe-btn-2 span {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .newsletter__item {
    flex-wrap: wrap;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .newsletter__item {
    flex-wrap: nowrap;
  }
}

.newsletter-2__plr {
  padding: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .newsletter-2__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .newsletter-2__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .newsletter-2__plr {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .newsletter-2__plr {
    padding: 0;
  }
}

.newsletter-2__space {
  padding-top: 85px;
  padding-bottom: 85px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.newsletter-2__icon {
  padding-right: 45px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .newsletter-2__icon {
    padding-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .newsletter-2__icon {
    padding-right: 15px;
  }
}

@media (max-width: 767px) {
  .newsletter-2__icon {
    padding-right: 15px;
  }
}

.newsletter-2__input-box {
  position: relative;
  margin-left: 110px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .newsletter-2__input-box {
    margin-left: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .newsletter-2__input-box {
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .newsletter-2__input-box {
    margin-left: 0;
  }
}

.newsletter-2__input-box input {
  border: none;
  width: 100%;
  height: 80px;
  line-height: 80px;
  background-color: var(--it-common-white);
  border-radius: 60px;
  font-size: 16px;
  font-weight: 400;
  padding-left: 45px;
  padding-right: 220px;
  box-shadow: 0 0 70px 0 rgba(0, 80, 159, 0.06);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .newsletter-2__input-box input {
    height: 55px;
    line-height: 55px;
    border-radius: 60px;
    font-size: 14px;
    padding-left: 25px;
    padding-right: 160px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .newsletter-2__input-box input {
    height: 50px;
    line-height: 50px;
    border-radius: 60px;
    font-size: 14px;
    padding-left: 25px;
    padding-right: 160px;
  }
}

@media (max-width: 767px) {
  .newsletter-2__input-box input {
    height: 50px;
    line-height: 50px;
    border-radius: 60px;
    font-size: 13px;
    padding-left: 25px;
    padding-right: 140px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .newsletter-2__input-box input {
    font-size: 15px;
    padding-left: 40px;
  }
}

.newsletter-2__input-box button {
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .newsletter-2__input-box button {
    bottom: 8%;
    right: -5px;
  }
}

@media (max-width: 767px) {
  .newsletter-2__item {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .newsletter-2__text .section-title {
    font-size: 34px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .newsletter-2__text .section-title {
    font-size: 28px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .newsletter-2__text .section-title {
    font-size: 26px;
  }
}

@media (max-width: 767px) {
  .newsletter-2__text .section-title {
    font-size: 26px;
  }
}

@media (max-width: 767px) {
  .newsletter-2__text .section-title {
    font-size: 25px;
  }
}

.product__plr {
  padding: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .product__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .product__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .product__plr {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .product__plr {
    padding: 0;
  }
}

.product__title-sm h4 {
  font-size: 22px;
  font-weight: 700;
  padding-bottom: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product__title-sm h4 {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .product__title-sm h4 {
    font-size: 20px;
  }
}

.product__item {
  background-color: var(--it-common-white);
}

.product__item:hover .product__img::after {
  opacity: 1;
  visibility: visible;
}

.product__item:hover .product__icon {
  opacity: 1;
  visibility: visible;
  bottom: 40%;
}

.product__img {
  position: relative;
}

.product__img img {
  width: 100%;
  height: 332px;
}

.product__img::after {
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(41, 41, 48, 0.6);
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.product__icon {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 10px;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
}

.product__icon a {
  height: 50px;
  width: 50px;
  background-color: var(--it-theme-1);
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  font-weight: 300;
  color: var(--it-common-black);
  margin: 0 6px;
  transition: 0.3s;
}

.product__icon a:hover {
  background-color: #642cce;
  color: var(--it-common-white);
}

.product__img-text {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #642cce;
  padding: 8px 21px;
  border-radius: 20px;
  margin: 12px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .product__img-text {
    padding: 6px 17px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product__img-text {
    padding: 6px 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product__img-text {
    padding: 6px 13px;
  }
}

@media (max-width: 767px) {
  .product__img-text {
    padding: 6px 15px;
  }
}

@media only screen and (min-width: 440px) and (max-width: 575px) {
  .product__img-text {
    padding: 6px 15px;
  }
}

.product__img-text span {
  font-weight: 500;
  font-size: 17px;
  text-transform: uppercase;
  color: var(--it-common-white);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .product__img-text span {
    font-size: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product__img-text span {
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .product__img-text span {
    font-size: 14px;
  }
}

@media only screen and (min-width: 440px) and (max-width: 575px) {
  .product__img-text span {
    font-size: 15px;
  }
}

.product__content {
  padding: 30px 15px;
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
}

.product__content-text {
  padding-bottom: 15px;
}

.product__content-text span {
  font-size: 17px;
  display: inline-block;
  padding-bottom: 7px;
}

.product__content-text h4 {
  font-size: 22px;
  transition: 0.3s;
}

.product__content-text h4:hover {
  color: #642cce;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .product__content-text h4 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .product__content-text h4 {
    font-size: 19px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product__content-text h4 {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .product__content-text h4 {
    font-size: 17px;
  }
}

@media only screen and (min-width: 440px) and (max-width: 575px) {
  .product__content-text h4 {
    font-size: 21px;
  }
}

.product-details__plr {
  padding: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .product-details__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .product-details__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details__plr {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .product-details__plr {
    padding: 0;
  }
}

.product-details__img-box {
  padding-bottom: 100px;
}

.product-details__img-box img {
  padding: 0 15px;
}

@media (max-width: 767px) {
  .product-details__img-box {
    padding-bottom: 40px;
  }
}

.product-details__list {
  padding-bottom: 20px;
}

.product-details__list ul {
  display: inline-block;
  padding-bottom: 5px;
}

.product-details__list ul li {
  list-style-type: none;
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
  width: 25%;
  float: left;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details__list ul li {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .product-details__list ul li {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details__list ul li {
    width: 50%;
  }
}

.product-details__list ul li i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  font-weight: 500;
  color: var(--it-common-blue-3);
  font-size: 16px;
}

.product-details__list ul li span {
  font-weight: 600;
  font-size: 18px;
  font-family: poppins, sans-serif;
  color: var(--it-common-black);
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
(max-width: 767px) {
  .product-details__list ul li span {
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details__list ul li span {
    font-size: 15px;
  }
}

.product-details__text {
  padding-bottom: 20px;
}

.product-details__text p {
  font-size: 17px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details__text p br {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details__text p {
    font-size: 15px;
  }

  .product-details__text p br {
    display: none;
  }
}

@media (max-width: 767px) {
  .product-details__text p {
    font-size: 14px;
  }

  .product-details__text p br {
    display: none;
  }
}

.product-details__text-2 {
  padding-bottom: 70px;
  font-size: 17px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details__text-2 p br {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details__text-2 p {
    font-size: 15px;
  }

  .product-details__text-2 p br {
    display: none;
  }
}

@media (max-width: 767px) {
  .product-details__text-2 p {
    font-size: 14px;
  }

  .product-details__text-2 p br {
    display: none;
  }
}

.product-details__social-box span {
  font-size: 18px;
  margin-right: 10px;
}

.product-details__social-box a {
  height: 33px;
  width: 33px;
  border: 1px solid var(--it-theme-1);
  display: inline-block;
  line-height: 33px;
  text-align: center;
  margin-right: 5px;
  transition: 0.3s;
  border-radius: 5px;
}

.product-details__social-box a i {
  color: var(--it-theme-1);
  transition: 0.3s;
}

.product-details__social-box a:hover {
  background-color: var(--it-theme-1);
  border: 1px solid transparent;
}

.product-details__social-box a:hover i {
  color: var(--it-common-black);
}

.product-details__left-title {
  font-size: 22px;
  font-weight: 700;
  padding-bottom: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details__left-title {
    font-size: 19px;
  }
}

@media (max-width: 767px) {
  .product-details__left-title {
    font-size: 18px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details__left-title {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .product-details__left-side {
    padding-bottom: 40px;
  }
}

.product-details__left-side h5 {
  font-size: 20px;
  font-weight: 700;
}

.product-details__left-side h5 span {
  font-weight: 600;
  color: var(--it-common-blue-3);
  padding-bottom: 10px;
  display: inline-block;
}

.product-details__left-side p {
  font-size: 17px;
}

.product-details__left-side p span {
  font-weight: 600;
  color: var(--it-common-black);
}

.product-details__left-side p span i {
  color: var(--it-common-blue-3);
  font-style: normal;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details__left-side p br {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details__left-side p {
    font-size: 15px;
  }

  .product-details__left-side p br {
    display: none;
  }
}

@media (max-width: 767px) {
  .product-details__left-side p {
    font-size: 14px;
  }

  .product-details__left-side p br {
    display: none;
  }
}

.product-details__star {
  padding-bottom: 20px;
}

.product-details__star span {
  font-size: 12px;
  color: var(--it-common-blue-3);
}

.product-details__star i {
  font-size: 12px;
  color: var(--it-theme-1);
}

.product-details__star i.color {
  color: #d5d5d5;
}

.product-details__content {
  border-top: 1px solid var(--it-border-1);
  padding-top: 100px;
}

@media (max-width: 767px) {
  .product-details__content {
    padding-top: 40px;
  }
}

.pro-details-nav {
  background: #f9fafb;
  display: inline-block;
  padding: 7px;
}

.pro-details-nav-btn {
  border: none;
}

.pro-details-nav-btn li {
  font-weight: 500;
  font-size: 18px;
  color: var(--it-text-body);
  position: relative;
}

.pro-details-nav-btn li button span {
  position: relative;
  z-index: 9;
}

.pro-details-nav-btn li button::before {
  content: "";
  height: 100%;
  width: 102%;
  top: 0;
  left: -1px;
  background: #fff;
  position: absolute;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
}

.pro-details-nav-btn li button.active::before {
  opacity: 1;
  visibility: visible;
}

.pro-details-nav-btn li::after {
  position: absolute;
  content: "";
  height: 40px;
  width: 1px;
  background: #dfe3e9;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .pro-details-nav-btn li::after {
    display: none;
  }
}

.pro-details-nav-btn li:last-child::after {
  display: none;
}

@media (max-width: 767px) {
  .pro-details-nav-btn li {
    font-size: 16px;
  }
}

.pro-details-nav-btn li .nav-links {
  padding: 20px 45px;
  border-radius: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pro-details-nav-btn li .nav-links {
    padding: 10px 20px;
  }
}

@media (max-width: 767px) {
  .pro-details-nav-btn li .nav-links {
    padding: 5px 15px;
  }
}

.pro-details-nav-btn li .nav-links.active {
  background: var(--it-common-white);
  color: var(--it-common-black);
}

.tab-para p {
  font-size: 16px;
  line-height: 30px;
}

.comments-box {
  margin-bottom: 50px;
}

.user-rating ul li {
  display: inline-block;
  color: #ffdc60;
}

.comments-text span,
.comments-text p {
  font-size: 14px;
  color: #777;
  line-height: 26px;
}

.comment-title p {
  color: #777;
  font-size: 16px;
}

.comment-rating span {
  font-weight: 500;
  margin-right: 5px;
}

.comment-rating ul li {
  display: inline-block;
  color: #ffdc60;
}

.comment-input textarea {
  width: 100%;
  height: 120px;
  margin-bottom: 20px;
  border: 1px solid rgba(221, 221, 221, 0.8666666667);
  padding: 20px;
}

.comment-input textarea:focus {
  outline: 0;
  border: 1px solid var(--it-theme-1);
}

.comment-input input {
  width: 100%;
  height: 50px;
  resize: none;
  outline: 0;
  border: 1px solid rgba(221, 221, 221, 0.8666666667);
  padding: 8px 20px;
  margin-bottom: 30px;
}

.comment-input input:focus {
  border: 1px solid var(--it-theme-1);
}

.comments-avatar {
  flex: 0 0 auto;
}

.table .add-info {
  font-size: 18px;
  font-weight: 500;
}

.table td {
  padding: 18px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px),
only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .shop-left-right {
    margin-left: 0;
    margin-right: 0;
  }
}

.it-comments-title {
  font-size: 24px;
  color: var(--it-common-black);
  font-weight: 700;
  text-transform: capitalize;
}

@media (max-width: 767px) {
  .it-comments-title {
    font-size: 18px;
  }
}

.avatar-name b {
  color: var(--it-common-black);
  font-weight: 700;
  text-transform: uppercase;
}

.shop-faq .accordion-header button {
  font-size: 18px;
  padding: 20px 55px 20px 30px;
}

@media (max-width: 767px) {
  .shop-faq .accordion-header button {
    font-size: 14px;
  }
}

.shop-faq .accordion-content p br {
  display: none;
}

.shop-faq .accordion-content p {
  padding: 0 75px 20px 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-button a {
    margin-bottom: 10px;
  }
}

.comment-title {
  font-size: 24px;
  color: var(--it-common-black);
  font-weight: 700;
  text-transform: capitalize;
}

.it-product-slider-title {
  color: var(--it-common-black);
  font-size: 30px;
  font-weight: 500;
}

.team__plr {
  padding: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .team__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .team__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .team__plr {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .team__plr {
    padding: 0;
  }
}

.team__space {
  padding-top: 345px;
  padding-bottom: 95px;
}

.team__bg {
  position: relative;
  z-index: 2;
}

.team__bg::after {
  position: absolute;
  content: "";
  background-color: var(--it-common-black);
  bottom: 0;
  left: 0;
  width: 100%;
  height: 46%;
  z-index: -1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team__bg::after {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team__bg::after {
    display: none;
  }
}

@media (max-width: 767px) {
  .team__bg::after {
    display: none;
  }
}

.team__grey-bg {
  position: relative;
}

.team__grey-bg::before {
  position: absolute;
  content: "";
  background-color: var(--it-grey-1);
  top: 0;
  left: 0;
  width: 100%;
  height: 54%;
  z-index: -1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team__grey-bg::before {
    height: 100%;
  }
}

.team__item {
  padding-bottom: 50px;
}

.team__item:hover .team__main-thumb::after {
  height: 100%;
  opacity: 1;
  visibility: visible;
}

.team__item:hover .team__social-box {
  opacity: 1;
  visibility: visible;
}

.team__main-thumb {
  position: relative;
}

.team__main-thumb img {
  width: 100%;
}

.team__main-thumb::after {
  content: "";
  position: absolute;
  background-color: rgba(41, 41, 48, 0.6);
  bottom: 0;
  left: 0;
  height: 0;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
}

.team__social-box {
  z-index: 2;
  position: absolute;
  content: "";
  top: 45%;
  left: 0;
  right: 0;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
}

.team__social-box a {
  height: 40px;
  width: 40px;
  border: 1px solid var(--it-common-white);
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  display: inline-block;
  margin: 0 5px;
  transition: 0.3s;
}

.team__social-box a i {
  font-size: 17px;
  color: var(--it-common-white);
  transition: 0.3s;
}

.team__social-box a:hover {
  background-color: var(--it-theme-1);
  border: 1px solid transparent;
}

.team__social-box a:hover i {
  color: var(--it-common-black);
}

.team__author-info {
  padding: 30px;
  background-color: var(--it-common-white);
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .team__author-info {
    font-size: 14px;
  }
}

.team__author-name {
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 0;
  padding-bottom: 5px;
  transition: 0.3s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .team__author-name {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .team__author-name {
    font-size: 18px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .team__author-name {
    font-size: 17px;
  }
}

.team__author-name:hover {
  color: var(--it-common-blue-4);
}

.team__top-text p {
  padding-right: 106px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .team__top-text p {
    padding-right: 85px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .team__top-text p {
    padding-right: 7px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .team__top-text p {
    padding-right: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .team__top-text p {
    padding-right: 0;
    font-size: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .team__top-text p {
    padding-right: 0;
  }
}

.team__shape-1 {
  position: absolute;
  left: 1%;
  top: 23%;
}

.team__space-2 {
  padding-top: 250px;
  margin-top: -220px;
  padding-bottom: 50px;
}

.job__plr {
  padding: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px),
only screen and (min-width: 1400px) and (max-width: 1599px) {
  .job__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .job__plr {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .job__plr {
    padding: 0;
  }
}

.job__list {
  padding-bottom: 5px;
}

.job__list ul {
  display: inline-block;
  padding-bottom: 5px;
}

.job__list ul li {
  list-style-type: none;
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
  width: 50%;
  float: left;
  transition: 0.3s;
  text-decoration: transparent;
}

.job__list ul li:hover {
  text-decoration: underline;
}

@media (max-width: 767px) {
  .job__list ul li {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .job__list ul li {
    width: 50%;
  }
}

@media only screen and (min-width: 440px) and (max-width: 575px) {
  .job__list ul li {
    width: 50%;
  }
}

.job__list ul li i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  font-weight: 500;
  color: var(--it-common-blue-3);
  font-size: 16px;
}

.job__list ul li span {
  font-weight: 400;
  font-size: 17px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .job__list ul li span {
    font-size: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
(max-width: 767px) {
  .job__list ul li span {
    font-size: 15px;
  }
}

.job__item {
  padding: 45px 55px;
  border: 1px solid var(--it-border-1);
  border-radius: 5px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .job__item {
    padding: 45px 33px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .job__item {
    padding: 45px 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .job__item {
    padding: 45px 48px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .job__item {
    padding: 35px 18px;
  }
}

@media (max-width: 767px) {
  .job__item {
    padding: 35px 22px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .job__item {
    padding: 45px 55px;
  }
}

.job__title-sm {
  font-weight: 700;
  font-size: 17px;
}

.job__meta {
  padding-bottom: 20px;
}

.job__meta span {
  margin-right: 54px;
  font-size: 15px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .job__meta span {
    margin-right: 28px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .job__meta span {
    margin-right: 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .job__meta span {
    font-size: 14px;
    margin-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .job__meta span {
    font-size: 13px;
    margin-right: 24px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .job__meta span {
    margin-right: 41px;
  }
}

@media (max-width: 767px) {
  .job__meta span {
    display: block;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .job__meta span {
    display: inline-block;
    margin-right: 81px;
  }
}

@media only screen and (min-width: 440px) and (max-width: 575px) {
  .job__meta span {
    display: inline-block;
  }
}

.job__meta span.clock {
  margin-right: 0;
  margin-bottom: 0;
}

.job__meta span i {
  padding-right: 10px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px),
only screen and (min-width: 1200px) and (max-width: 1399px) {
  .job__meta span i {
    padding-right: 5px;
  }
}

.job__salary {
  padding-bottom: 30px;
}

.job__salary span {
  font-size: 600px;
  font-size: 17px;
}

.job__salary span i {
  font-size: 12px;
  font-style: normal;
}

.job__img img {
  width: 54px;
  height: 54px;
}

.job-details__mlr {
  margin: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px),
only screen and (min-width: 1400px) and (max-width: 1599px) {
  .job-details__mlr {
    margin: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .job-details__mlr {
    margin: 0 30px;
  }
}

@media (max-width: 767px) {
  .job-details__mlr {
    margin: 0;
  }
}

.job-details__author-info h4 {
  font-size: 22px;
}

.job-details__list span {
  display: inline-block;
  padding-bottom: 25px;
  text-transform: uppercase;
}

.job-details__list p {
  padding-right: 74px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .job-details__list p {
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .job-details__list p {
    padding-right: 0;
    font-size: 14px;
  }
}

.job-details__list ul {
  padding-right: 188px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .job-details__list ul {
    padding-right: 0;
  }
}

.job-details__list ul li {
  font-size: 17px;
  list-style: none;
  padding-bottom: 20px;
  position: relative;
  margin-left: 30px;
  position: relative;
}

@media (max-width: 767px) {
  .job-details__list ul li {
    font-size: 14px;
  }
}

.job-details__list ul li::before {
  position: absolute;
  content: "";
  top: 2px;
  left: -28px;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: var(--it-common-black);
  border: 4px solid var(--it-theme-1);
}

.job-details__summary {
  margin-right: 170px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .job-details__summary {
    margin-right: 0;
  }
}

.job-details__summary h4 {
  font-size: 22px;
  font-size: 700;
  padding-bottom: 20px;
}

@media (max-width: 767px) {
  .job-details__summary h4 {
    font-size: 18px;
  }
}

.job-details__summary ul {
  display: inline-block;
}

.job-details__summary ul li {
  width: 50%;
  float: left;
  list-style-type: none;
  padding-bottom: 20px;
}

@media (max-width: 767px) {
  .job-details__summary ul li {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .job-details__summary ul li {
    width: 50%;
  }
}

.job-details__text span {
  font-weight: 600;
  font-size: 20px;
  display: block;
}

@media (max-width: 767px) {
  .job-details__text span {
    font-size: 17px;
  }
}

.job-details__text i {
  font-size: 15px;
  display: block;
  font-style: normal;
}

@media (max-width: 767px) {
  .job-details__text i {
    font-size: 14px;
  }
}

.job-details__icon {
  padding-right: 20px;
}

.job-details__icon i {
  font-size: 25px;
  color: var(--it-common-blue-4);
}

.job-details__text-2 {
  padding-right: 80px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px),
only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .job-details__text-2 {
    padding-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .job-details__text-2 {
    margin-bottom: 70px;
  }
}

@media (max-width: 767px) {
  .job-details__author-info h5 {
    font-size: 17px;
  }
}

@media (max-width: 767px) {
  .career-details-title-xs {
    font-size: 18px;
  }
}

.postbox__select {
  display: inline-block;
  width: 100%;
}

.postbox__select .nice-select {
  width: 100%;
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  height: 55px;
  line-height: 55px;
  padding: 0 20px;
  position: relative;
}

.postbox__select .nice-select::after {
  border: none;
  background-color: transparent;
  transform: translateY(-45%);
  margin-top: 0;
  right: 20px;
  content: "\f107";
  font-family: "font awesome 5 pro";
  transform-origin: center;
  color: var(--it-common-black);
  font-weight: 500;
  height: auto;
  width: auto;
  color: #5f6168;
}

.postbox__select .nice-select span {
  font-weight: 500;
  font-size: 15px;
  color: #5f6168;
}

.postbox__select .nice-select.open::after {
  transform: translateY(-45%) rotate(-180deg);
}

.postbox__select .nice-select .list {
  width: 100%;
}

.postbox__select .nice-select .list li:last-child {
  border-bottom: 0;
}

.postbox__select .nice-select .selected {
  color: var(--it-common-blue-4);
  font-weight: 600;
}

.postbox__authorization {
  margin-bottom: 40px;
}

.postbox__authorization .switcher label {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.postbox__authorization .switcher label * {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #5f6168;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .postbox__authorization .switcher label * {
    font-size: 13px;
  }
}

.postbox__authorization .switcher label * svg {
  position: absolute;
  top: 0;
  left: 0;
}

.postbox__authorization .switcher label .switcher-icon {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
}

.postbox__authorization .switcher label .switcher-icon svg {
  position: absolute;
  top: 6px;
  left: 0;
}

.postbox__authorization .switcher label input {
  display: none;
}

.postbox__authorization .switcher label input+span {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  background: #e6e8eb;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  flex: 0 0 auto;
}

.postbox__authorization .switcher label input+span small {
  position: absolute;
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  left: 4px;
  top: 3px;
  background: linear-gradient(180deg, #ffffff 0%, #e7e2f0 100%);
  box-shadow: 1px 2px 3px rgba(18, 20, 32, 0.3);
}

.postbox__authorization .switcher label input:checked+span {
  background: var(--it-common-blue-3);
}

.postbox__authorization .switcher label input:checked+span small {
  left: 56%;
}

.career-border-bottom {
  border-bottom: 1px solid #ebebeb;
}

.postbox__resume label {
  width: 100%;
  height: 130px;
  border: 2px dashed #c1c4ce;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.postbox__resume label span {
  background-color: #f3f3f3;
  border-radius: 8px;
  padding: 10px 20px;
  font-weight: 500;
  font-size: 14px;
  color: var(--it-common-black);
}

@media (max-width: 767px) {
  .postbox__resume label span {
    padding: 10px;
    font-size: 12px;
  }
}

.postbox__resume label span svg {
  margin-right: 10px;
}

.career-details-social-box {
  text-align: center;
}

.career-details-social-box a {
  padding: 15px 45px;
  display: inline-block;
  border: 1px solid #ebebeb;
  border-radius: 12px;
  margin: 0 3px;
  font-size: 18px;
  font-weight: 400;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .career-details-social-box a {
    padding: 15px 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .career-details-social-box a {
    padding: 15px 30px;
  }
}

@media (max-width: 767px) {
  .career-details-social-box a {
    margin-bottom: 10px;
  }
}

.career-details-social-box a.facebook {
  color: #1b74e4;
  transition: 0.3s;
}

.career-details-social-box a.facebook:hover {
  background-color: #1b74e4;
  color: var(--it-common-white);
  border-color: #1b74e4;
}

.career-details-social-box a.twitter {
  color: #1d9bf0;
  transition: 0.3s;
}

.career-details-social-box a.twitter:hover {
  background-color: #1d9bf0;
  color: var(--it-common-white);
  border-color: #1d9bf0;
}

.career-details-social-box a.instagram {
  position: relative;
}

.career-details-social-box a.instagram i {
  background: linear-gradient(261.91deg, #b900b4 0%, #f50000 40%, #ffd723 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: 0.3s;
  font-weight: 500;
  font-size: 19px;
}

.career-details-social-box a.instagram .insta-bg {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  background: linear-gradient(266.81deg,
      #d500bf -6.56%,
      #f80095 34.02%,
      #ff005d 60.87%,
      #ffc300 103.55%);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 12px;
  opacity: 0;
  visibility: hidden;
}

.career-details-social-box a.instagram:hover i {
  -webkit-text-fill-color: inherit;
  color: var(--it-common-white);
  position: relative;
  z-index: 99;
}

.career-details-social-box a.instagram:hover .insta-bg {
  opacity: 1;
  visibility: visible;
}

.career-details-social-box a.linkedin {
  color: #0a66c2;
  transition: 0.3s;
}

.career-details-social-box a.linkedin:hover {
  background-color: #0a66c2;
  color: var(--it-common-white);
  border-color: #0a66c2;
}

.postbox__apply-btn-border {
  position: relative;
}

.postbox__apply-btn-border::after {
  content: "";
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  width: 100%;
  background-color: var(--it-common-blue-4);
  position: absolute;
  text-align: center;
  margin: 0 auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .career-details-hide-wrapper .postbox__comment-form {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .job-section-box p br {
    display: none;
  }
}

.job-right-box {
  position: sticky;
  top: 50px;
}

.table-content .table> :not(:first-child) {
  border-top: 0;
}

.table-content table {
  background: #fff;
  border-color: #eaedff;
  border-radius: 0;
  border-style: solid;
  border-width: 1px 0 0 1px;
  text-align: center;
  width: 100%;
  margin-bottom: 0;
}

.table-content .table> :not(:last-child)> :last-child>* {
  border-bottom-color: #eaedff;
}

.table-content .product-quantity {
  float: none;
}

.table-content table td.product-name {
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
}

.table-content table td.product-name a:hover {
  color: var(--it-theme-1);
}

.table-content table td {
  border-top: medium;
  padding: 20px 10px;
  vertical-align: middle;
  font-size: 16px;
}

.table-content table th,
.table-content table td {
  border-bottom: 1px solid #eaedff;
  border-right: 1px solid #eaedff;
}

.product-quantity input {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #dcdcdc;
}

.table td,
.table th {
  border-top: 1px solid #eaedff;
}

.product-quantity>input {
  width: 80px;
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-quantity>input {
    width: inherit;
  }
}

.table-content table td.product-subtotal {
  font-size: 16px;
}

.table-content table td .cart-plus-minus {
  float: none;
  margin: 0 auto;
}

.coupon-all {
  margin-top: 50px;
}

.coupon {
  float: left;
}

@media (max-width: 767px) {
  .coupon {
    float: none;
  }
}

#coupon_code {
  height: 58px;
  border: 2px solid #eaedff;
  padding: 0 30px;
  margin-right: 10px;
  border-radius: 30px;
}

@media (max-width: 767px) {
  #coupon_code {
    margin-bottom: 15px;
  }
}

.coupon2 {
  float: right;
}

@media (max-width: 767px) {
  .coupon2 {
    float: none;
    margin-top: 15px;
  }
}

.cart-page-total {
  padding-top: 50px;
}

.cart-page-total>h2 {
  font-size: 25px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.cart-page-total>ul {
  border: 1px solid #eaedff;
}

.cart-page-total>ul>li {
  list-style: none;
  font-size: 15px;
  color: #6f7172;
  padding: 10px 30px;
  border-bottom: 1px solid #eaedff;
  font-weight: 400;
}

.cart-page-total ul>li>span {
  float: right;
}

.cart-page-total li:last-child {
  border-bottom: 0;
}

td.product-thumbnail img {
  width: 125px;
}

.cart-plus,
.cart-minus {
  width: 20px;
  height: 20px;
  border: 1px solid #e7e7e7;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}

.cart-plus:hover,
.cart-minus:hover {
  background-color: var(--it-theme-1);
  color: var(--it-common-white);
}

.coupon-accordion h3 {
  background-color: #f7f7f7;
  border-top: 3px solid var(--it-theme-1);
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 25px;
  padding: 1em 2em 1em 3.5em;
  position: relative;
  width: auto;
}

.coupon-accordion h3::before {
  content: "\f07b";
  left: 15px;
  top: 13px;
  position: absolute;
  color: var(--it-theme-1);
  font-family: "font awesome 5 pro";
  font-weight: 700;
}

.coupon-accordion span {
  color: #6f7172;
  cursor: pointer;
  transition: 0.3s;
  font-weight: 500;
}

.coupon-accordion span:hover,
p.lost-password a:hover {
  color: var(--it-common-black);
}

.coupon-content {
  border: 1px solid #eaedff;
  display: none;
  margin-bottom: 20px;
  padding: 30px;
}

.coupon-info p.coupon-text {
  margin-bottom: 15px;
}

.coupon-info p {
  margin-bottom: 0;
}

.coupon-info p.form-row-first label,
.coupon-info p.form-row-last label {
  display: block;
  color: #6f7172;
}

.coupon-info p.form-row-first label span.required,
.coupon-info p.form-row-last label span.required {
  color: var(--it-theme-1);
  font-weight: 700;
}

.coupon-info p.form-row-first input,
.coupon-info p.form-row-last input {
  border: 1px solid #eaedff;
  height: 45px;
  margin: 0 0 14px;
  max-width: 100%;
  padding: 0 0 0 10px;
  width: 100%;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

.coupon-info p.form-row-first input:focus,
.coupon-info p.form-row-last input:focus {
  border-color: var(--it-common-black);
}

.coupon-info p.form-row input[type="submit"]:hover,
p.checkout-coupon input[type="submit"]:hover {
  background: #3e976c none repeat scroll 0 0;
}

.coupon-info p.form-row input[type="checkbox"] {
  position: relative;
  top: 2px;
}

.form-row>label {
  margin-top: 15px;
  margin-left: 15px;
  color: #6f7172;
}

.buttons-cart input,
.coupon input[type="submit"],
.buttons-cart a,
.coupon-info p.form-row input[type="submit"] {
  background: #252525 none repeat scroll 0 0;
  border: medium;
  color: #fff;
  display: inline-block;
  float: left;
  font-size: 12px;
  font-weight: 700;
  height: 40px;
  line-height: 40px;
  margin-right: 15px;
  padding: 0 15px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}

p.lost-password {
  margin-top: 15px;
}

p.lost-password a {
  color: #6f6f6f;
}

p.checkout-coupon input[type="text"] {
  height: 45px;
  padding: 0 15px;
  width: 100%;
  border: 1px solid #eaedff;
  margin-bottom: 15px;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

p.checkout-coupon input[type="text"]:focus {
  border-color: var(--it-common-black);
}

.coupon-checkout-content {
  display: none;
}

.checkbox-form h3 {
  border-bottom: 1px solid #eaedff;
  font-size: 26px;
  margin: 0 0 20px;
  padding-bottom: 10px;
  width: 100%;
}

.country-select {
  margin-bottom: 30px;
  position: relative;
}

.country-select select {
  width: 100%;
  background-color: transparent;
  border: 1px solid #eaedff;
  padding: 0 10px;
  height: 50px;
}

.country-select label,
.checkout-form-list label {
  color: #6f7172;
  display: block;
  margin: 0 0 5px;
}

.country-select label span.required,
.checkout-form-list label span.required {
  color: var(--it-theme-1);
}

.country-select .nice-select {
  border: 1px solid #eaedff;
  height: 45px;
  padding-left: 10px;
  width: 100%;
  color: #6f7172;
  margin-bottom: 20px;
}

.country-select .nice-select .list {
  width: 100%;
}

.checkout-form-list {
  margin-bottom: 30px;
}

.checkout-form-list label {
  color: #6f7172;
}

.checkout-form-list input[type="text"],
.checkout-form-list input[type="password"],
.checkout-form-list input[type="email"] {
  background: #fff;
  border: 1px solid #eaedff;
  border-radius: 0;
  height: 45px;
  padding: 0 0 0 10px;
  width: 100%;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

.checkout-form-list input[type="text"]:focus,
.checkout-form-list input[type="password"]:focus,
.checkout-form-list input[type="email"]:focus {
  border-color: var(--it-common-black);
}

.checkout-form-list input[type="text"]::-moz-placeholder,
.checkout-form-list input[type="password"]::-moz-placeholder,
.checkout-form-list input[type="email"]::-moz-placeholder {
  color: #6f7172;
  opacity: 1;
}

.checkout-form-list input[type="text"]::placeholder,
.checkout-form-list input[type="password"]::placeholder,
.checkout-form-list input[type="email"]::placeholder {
  color: #6f7172;
  opacity: 1;
}

.checkout-form-list input[type="checkbox"] {
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 1px;
}

.create-acc label {
  color: #6f7172;
  display: inline-block;
}

.create-account {
  display: none;
}

.ship-different-title h3 label {
  display: inline-block;
  margin-right: 20px;
  color: var(--it-common-black);
}

.order-notes textarea {
  border: 1px solid #eaedff;
  height: 120px;
  padding: 15px;
  width: 100%;
  outline: 0;
  resize: none;
}

.order-notes textarea:focus {
  border-color: var(--it-common-black);
}

.order-notes textarea::-moz-placeholder {
  color: #6f7172;
  opacity: 1;
}

.order-notes textarea::placeholder {
  color: #6f7172;
  opacity: 1;
}

#ship-box-info {
  display: none;
}

.panel-group .panel {
  border-radius: 0;
}

.panel-default>.panel-heading {
  border-radius: 0;
}

.your-order {
  padding: 30px 40px 45px;
  border: 3px solid #f7f7f7;
}

@media (max-width: 767px) {
  .your-order {
    padding: 15px;
  }
}

.your-order h3 {
  border-bottom: 1px solid #eaedff;
  font-size: 30px;
  margin: 0 0 20px;
  padding-bottom: 10px;
  width: 100%;
}

.your-order-table table {
  background: 0 0;
  border: 0;
  width: 100%;
}

.your-order-table table th,
.your-order-table table td {
  border-bottom: 1px solid #eaedff;
  border-right: medium;
  color: #6f7172;
  font-size: 14px;
  padding: 15px 0;
  text-align: left;
}

@media (max-width: 767px) {

  .your-order-table table th,
  .your-order-table table td {
    font-size: 13px;
  }
}

@media (max-width: 767px) {

  .your-order-table table th,
  .your-order-table table td {
    padding-right: 10px;
  }
}

.your-order-table table th {
  border-top: medium;
  color: #6f7172;
  font-weight: 400;
  text-align: left;
  vertical-align: middle;
  white-space: nowrap;
  width: 250px;
}

.panel-body>p {
  color: #222;
}

.your-order-table table .shipping ul li {
  list-style: none;
}

.your-order-table table .shipping ul li input {
  position: relative;
  top: 2px;
}

.your-order-table table .shipping ul li label {
  color: #6f7172;
}

.your-order-table table .shipping th {
  vertical-align: top;
}

.your-order-table table .order-total th {
  border-bottom: 0;
  font-size: 14px;
}

.your-order-table table .order-total td {
  border-bottom: medium;
}

.your-order-table table tr.cart_item:hover {
  background: #f9f9f9;
}

.your-order-table table tr.order-total td span {
  color: var(--it-common-black);
  font-size: 18px;
  font-weight: 500;
}

.payment-method {
  margin-top: 40px;
}

.payment-method .accordion-item:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.payment-method .accordion-item {
  background-color: #fff;
  border: 0;
  border-bottom: 1px solid #e7e7e7;
}

.payment-method .accordion-button {
  font-size: 16px;
  font-weight: 500;
  color: var(--it-common-black);
  padding: 23px 0;
  border: none;
}

.payment-method .accordion-button:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

.payment-method .accordion-button::after {
  position: absolute;
  content: "\f067";
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 16px;
  font-weight: 400;
  margin-left: 0;
  background-image: none;
  color: var(--it-common-black);
  font-family: "font awesome 5 pro";
}

.payment-method .accordion-button:not(.collapsed) {
  color: var(--it-common-black);
  background-color: var(--it-common-white);
  box-shadow: none;
}

.payment-method .accordion-button:not(.collapsed)::after {
  content: "\f068";
  font-family: "font awesome 5 pro";
}

.payment-method .accordion-body {
  padding: 8px 0;
  padding-bottom: 40px;
}

.payment-method .accordion-collapse {
  border: none;
}

.panel-title>a {
  display: block;
}

.order-button-payment input {
  background: #232323 none repeat scroll 0 0;
  border: medium;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  height: 40px;
  margin: 0 0 0;
  padding: 0;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  width: 100%;
}

.order-button-payment input:hover {
  background: #3e976c none repeat scroll 0 0;
}

.payment-method .btn-link {
  -moz-user-select: none;
  background: no-repeat;
  border: medium;
  border-radius: 0;
  color: #444;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 1;
  margin-bottom: 0;
  padding: 3px 10px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
  white-space: nowrap;
  text-decoration: none;
}

.payment-method .card {
  background-color: #fff;
  border: 1px solid #eaedff;
  border-radius: 0;
  margin-bottom: 10px;
}

.payment-method .accordion .card:first-of-type {
  border: 1px solid #eaedff;
}

.card-header:first-child {
  border-radius: 0;
}

.payment-method .card-header {
  background-color: #fff;
  border-bottom: 1px solid #eaedff;
}

.order-button-payment button {
  width: 100%;
}

.faq__plr {
  padding: 0 200px;
  padding-top: 100px;
  padding-bottom: 40px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .faq__plr {
    padding: 0 100px;
    padding-top: 100px;
    padding-bottom: 40px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px),
only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
  .faq__plr {
    padding: 0 30px;
    padding-top: 100px;
    padding-bottom: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .faq__plr {
    padding: 0;
    padding-top: 100px;
    padding-bottom: 40px;
  }
}

.error__text h4 {
  font-size: 32px;
  padding-bottom: 10px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
  .error__text h4 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error__text h4 {
    font-size: 24px;
  }
}

@media (max-width: 767px) {
  .error__text h4 {
    font-size: 19px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .error__text h4 {
    font-size: 24px;
  }
}

.error__text span {
  display: inline-block;
  padding-bottom: 30px;
}

@media (max-width: 767px) {
  .error__text span {
    font-size: 14px;
  }
}

.price__plr {
  padding: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .price__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .price__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .price__plr {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .price__plr {
    padding: 0;
  }
}

.price__space {
  padding-top: 120px;
  padding-bottom: 40px;
}

.price__item {
  background-color: var(--it-common-white);
  box-shadow: 0 0 86px 0 rgba(92, 92, 92, 0.04);
}

.price__item.active {
  background-color: var(--it-theme-1);
}

.price__item.active .price__icon span {
  background-color: var(--it-common-white);
}

.price__item.active .price__top-content {
  border-bottom: 1px solid var(--it-common-white);
}

.price__top-content {
  border-bottom: 1px solid var(--it-border-1);
  transition: 0.3s;
}

.price__icon {
  padding-bottom: 25px;
}

.price__icon span {
  width: 160px;
  height: 135px;
  background-color: var(--it-theme-1);
  display: inline-block;
  line-height: 162px;
  text-align: center;
  border-bottom-left-radius: 90px;
  border-bottom-right-radius: 90px;
  transition: 0.3s;
}

.price__icon span i {
  font-size: 70px;
  color: var(--it-common-black);
  padding-left: 6px;
}

.price__title-sm {
  font-weight: 600;
  font-size: 22px;
  font-family: saira, sans-serif;
  margin-bottom: 0;
  padding-bottom: 15px;
  transition: 0.3s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .price__title-sm {
    font-size: 19px;
  }
}

@media (max-width: 767px) {
  .price__title-sm {
    font-size: 18px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .price__title-sm {
    font-size: 21px;
  }
}

.price__content-list {
  padding: 10px;
}

.price__content-list ul li {
  font-size: 18px;
  font-weight: 400;
  list-style-type: none;
  padding-bottom: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .price__content-list ul li {
    padding-bottom: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .price__content-list ul li {
    font-size: 15px;
    padding-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .price__content-list ul li {
    font-size: 15px;
    padding-bottom: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .price__content-list ul li {
    font-size: 17px;
    padding-bottom: 22px;
  }
}

.price__content-list ul li:first-child {
  font-weight: 600;
  font-size: 50px;
  font-family: poppins, sans-serif;
  color: var(--it-common-black);
  padding-bottom: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .price__content-list ul li:first-child {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .price__content-list ul li:first-child {
    font-size: 36px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .price__content-list ul li:first-child {
    font-size: 45px;
  }
}

.price__button {
  padding-bottom: 40px;
}

.price__top-text p {
  padding-right: 106px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .price__top-text p {
    padding-right: 85px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .price__top-text p {
    padding-right: 7px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .price__top-text p {
    padding-right: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .price__top-text p {
    padding-right: 0;
    font-size: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .price__top-text p {
    padding-right: 0;
  }
}

.price__shape-1 {
  position: absolute;
  top: 38%;
  left: 2%;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .price__shape-1 {
    left: 0;
  }
}

.price__shape-2 {
  position: absolute;
  bottom: 8%;
  left: 1%;
}

.price__shape-3 {
  position: absolute;
  top: 7%;
  right: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .price__shape-3 {
    top: 2%;
  }
}

.price__shape-4 {
  position: absolute;
  bottom: 2%;
  right: 3%;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .price__shape-4 {
    bottom: -1%;
    right: 0%;
  }
}

.price__shape-5 {
  position: absolute;
  top: 36%;
  right: 6%;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .price__shape-5 {
    right: 2%;
  }
}

.price-2__plr {
  padding: 0 200px;
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .price-2__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .price-2__plr {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .price-2__plr {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .price-2__plr {
    padding: 0;
  }
}

.price-2__space {
  padding-top: 70px;
  padding-bottom: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .price-2__space {
    padding-bottom: 0;
  }
}

@media (max-width: 767px) {
  .price-2__space {
    padding-bottom: 0;
  }
}

.price-2__item {
  padding-bottom: 80px;
}

.price-2__item.active {
  background-color: var(--it-common-black-2);
  border-radius: 10px;
}

.price-2__item.active .price-2__top-item {
  border-bottom: 1px solid #58503b;
}

.price-2__item.active .price-2__top-item i {
  color: var(--it-common-white);
}

.price-2__item.active .price-2__top-title {
  color: var(--it-common-white);
}

.price-2__item.active .price-2__list-title {
  color: var(--it-common-white);
}

.price-2__item.active .price-2__list ul li {
  transition: 0.3s;
}

.price-2__item.active .price-2__list ul li span {
  color: var(--it-common-white);
}

.price-2__item.active .price-2__list ul li:hover span {
  color: var(--it-theme-1);
}

.price-2__top-item {
  padding: 75px 54px;
  padding-bottom: 0;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--it-border-1);
  position: relative;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .price-2__top-item {
    padding: 75px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .price-2__top-item {
    padding: 75px 40px;
    padding-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .price-2__top-item {
    padding: 75px 30px;
    padding-bottom: 40px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .price-2__top-item {
    padding: 40px 70px;
  }
}

.price-2__top-item i {
  font-style: normal;
  font-size: 50px;
  color: var(--it-common-black);
  font-weight: 600;
}

.price-2__top-item i span {
  font-size: 16px;
}

.price-2__top-title {
  font-size: 22px;
  font-weight: 700;
  padding-bottom: 5px;
}

.price-2__list-title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 0;
}

.price-2__shape {
  position: absolute;
  right: 0;
  top: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .price-2__shape {
    right: -18px;
    top: -22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .price-2__shape {
    right: -4%;
    top: -6%;
  }
}

@media (max-width: 767px) {
  .price-2__shape {
    right: -7%;
    top: -13%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .price-2__shape {
    right: -1%;
    top: -4%;
  }
}

@media only screen and (min-width: 440px) and (max-width: 575px) {
  .price-2__shape {
    right: -1%;
    top: -1%;
  }
}

.price-2__item-shape {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .price-2__item-shape {
    bottom: -6%;
    right: -9%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .price-2__item-shape {
    bottom: -6%;
    right: -9%;
  }
}

.price-2__icon {
  position: absolute;
  right: 22%;
  top: 24%;
}

.price-2__list {
  padding: 40px 54px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .price-2__list {
    padding: 40px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .price-2__list {
    padding: 40px;
  }
}

@media (max-width: 767px) {
  .price-2__list {
    padding: 40px 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .price-2__list {
    padding: 40px 70px;
  }
}

.price-2__list ul li {
  font-size: 18px;
  font-weight: 400;
  list-style-type: none;
  padding-top: 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .price-2__list ul li {
    font-size: 15px;
  }
}

.price-2__list ul li i {
  color: var(--it-common-blue-3);
  padding-right: 5px;
}

.price-2__list ul li:hover span {
  color: var(--it-common-blue-3);
}

.price-2__list ul li span {
  border-bottom: 1px solid var(--it-border-1);
  display: inline-block;
  padding-bottom: 7px;
  transition: 0.3s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .price-2__list ul li {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .price-2__list ul li {
    font-size: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .price-2__list ul li {
    font-size: 17px;
  }
}

.price-2__button {
  padding: 0 54px;
  padding-top: 0;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .price-2__button {
    padding: 0 70px;
  }
}

.mobile-nav ul li a {
  display: block;
  font-size: 22px;
  color: white;
  /* text-decoration: underline; */
  /* height: 40px; */
  margin: 10px 0px;
  border-bottom: 1px solid white;
  padding: 0px 0px 5px 0px;


}

.media_main {
  padding: 50px;
  background: rgba(255, 220, 96, 1);
  margin: 40px 0px;
}

.media_main h3 {
  color: #000;
}

.media_main h4 {
  color: white;
  background-color: rgba(255, 220, 96, 1);
}

.media {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; /* Ensure items wrap on smaller screens */
  gap: 20px; /* Add spacing between images */
}

.media img {
  background-color: #fff;
  height: 100px;
  width: 180px;
  margin: 10px; /* Adjust margin for consistent spacing */
  padding: 10px;
  border-radius: 5px;
  box-shadow: 10px 13px 20px -6px rgba(0, 0, 0, 0.46);
  object-fit: contain;
}

@media only screen and (min-width: 300px) and (max-width: 767px) {
  .media {
    display: grid; /* Switch to grid layout for mobile */
    grid-template-columns: repeat(2, 1fr); /* Two columns of equal width */
    gap: 10px; /* Space between images */
    padding: 10px;
  }

  .media img {
    background-color: #fff;
    height: 100px; /* Adjusted height for better appearance */
    width: 100%; /* Image will take the full width of the column */
    margin: 0; /* Removed margin for better grid alignment */
    padding: 10px;
    border-radius: 5px;
    border-bottom: 1px solid #6f6f7944;
    object-fit: contain; /* Ensure images are scaled properly */
  }
}


.media img:hover {
  cursor: pointer;
  scale: 1.05;
}

.comingsoon {
  display: flex;
  align-items: center;
}

.comingsoon p {
  color: white;
  padding: 0px;
  margin: 0px;
  font-weight: 700;
}

.comingsoon img {
  height: 20px;


}


@media screen and (max-width:700px) {
  .header-social-icons {
    display: none;
  }
}

.slick-slide img {
  width: 80% !important;
  margin: 0px 10px;

}

.medium {
  width: 180px;
  height: 100px;

}
.ip_banner {
  width: 100%;
}
@media (min-width: 992px) and (max-width: 1400px){
  .ip_banner {
    width: 90%;
    margin-top: 100px;
    margin-left: 10%;
  }
}
@media (max-width: 992px){
  .row1 {
    display: block;
    align-items: center;
    margin-top: 150px;
  }
  .row2 {
    display: block;
    align-items: center;
    margin-top: 30px;
  }
  .no-padd{
    justify-content: center;
  }
}
video {
  border: 2px solid black;
  border-radius: 10px;
  max-width: 100%;
}


@media (min-width: 1024px) {
  .video-container {
    max-width: 960px;
    margin: 0 auto;
  }
}


@media (max-width: 1023px) and (min-width: 768px) {
  .video-container {
    max-width: 100%; 
  }
}


@media (max-width: 767px) and (min-width: 480px) {
  .video-container {
    max-width: 100%; 
  }
}


@media (max-width: 479px) {
  .video-container {
    max-width: 100%; 
    
  }
}

.row.align-items-center.justify-content-center{
  margin-right: -15px;
  margin-left: -15px;
}
.no-padd{
  padding: 0px;
  justify-self: center;
}
.align-items-center{
  -webkit-box-align: center !important;
  align-items: center !important;
}
.justify-content-center{
  -webkit-box-pack: center !important;
  justify-content: center !important;
}
.row1{
  display: flex;
  flex-wrap: wrap;
}
.row2{
  display: flex;
  flex-wrap: wrap;
}
.ip_banner_content{
  text-align: center;
  padding: 20px;
}
.img-fluid{
  max-width: 100%;
  height: auto;
}
.line1{
  width: 200px;
  height: 1px;
  background: black;
  margin: auto;
  margin-bottom: 20px;
}
.ip_banner_content h4{
  color: black;
  font-size: 20px;
  font-family: sans-serif !important;
  line-height: 30px;
  margin-bottom: 25px;
}
.white{
  font-weight: bold;
}

.ftco-section {
  padding: 1em 0;
  position: relative;
}
.ftc-no-pb{
  padding-bottom: 0 !important;
}

@media (min-width: 576px){
  .container1{
    max-width: 540px;
  }
}

.container1{
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.ftco-section h2{
  font-size: 24px;
}
@media (min-width: 1200px){
  .container1{
    max-width: 1140px;
  }
}
@media (min-width: 992px) and (max-width: 1199px){
  .container1{
    max-width: 960px;
  }
}
@media (min-width: 768px) and (max-width: 991px){
  .container1{
    max-width: 720px;
  }
}

.practice{
  margin-bottom: 60px;
}
.practice .info{
  margin-top: 30px;
}
.info{
  font-size: 14px;
  text-align: justify;
}
/* a{
  color: black !important;
  text-decoration: none !important;
} */

.Head1{
  padding: 0px !important;
}

.troy{
  border: 1.5px solid black !important;
}
.troy > img{
  width: 100%;
  height: 200px;
}

.card-group{
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 40px;
}